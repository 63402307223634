import { isAxiosError } from '@/api/business'
import { changeRoundDate } from '@/api/business/cotacoes'
import { Button } from '@/components/ui/button'
import { DatePicker } from '@/components/DatePicker'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { quotationKeys } from '@/queries/useQuotationQuery'
import { useBaseStore } from '@/store'
import { getLastRoundSelector } from '@/store/quotationDetailSlice'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { format, isBefore, isValid } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { CalendarIcon } from 'lucide-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { z } from 'zod'

interface ChangeDateDialogProps {
    isOpen: boolean
    onClose: () => void
}

const schema = z.object({
    newDate: z
        .date()
        .refine(
            (date) => isBefore(new Date(), date),
            'Data não pode ser anterior à data de hoje'
        ),
    oldDate: z.date(),
})

type Schema = z.infer<typeof schema>

const ChangeDateDialog = ({ isOpen, onClose }: ChangeDateDialogProps) => {
    const { id } = useParams()
    const queryClient = useQueryClient()

    const { toast } = useToast()

    const lastRound = useBaseStore(getLastRoundSelector)

    const form = useForm<Schema>({
        resolver: zodResolver(schema),
        defaultValues: {
            oldDate:
                lastRound && isValid(new Date(lastRound.expirationDate))
                    ? new Date(lastRound.expirationDate)
                    : undefined,
            newDate: undefined,
        },
    })

    const { mutate, isPending } = useMutation({
        mutationFn: changeRoundDate,
        onSuccess: () => {
            if (id)
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(Number(id)),
                })
            toast({
                title: 'Data alterada com sucesso',
                description: `Expiração alterada para ${format(
                    form.getValues('newDate'),
                    'dd/MM/yyyy'
                )}`,
            })

            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível alterar a data de expiração. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao alterar data',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const handleSubmit = (data: Schema) => {
        if (id) {
            mutate({
                quotationId: Number(id),
                date: format(data.newDate, 'yyyy-MM-dd'),
            })
        }
    }

    useEffect(() => {
        if (lastRound?.expirationDate) {
            form.reset({
                oldDate: new Date(lastRound.expirationDate),
                newDate: undefined,
            })
        }
    }, [lastRound?.expirationDate])

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Alterar data da rodada</DialogTitle>
                    <DialogDescription>
                        Preencha a nova data de expiração da rodada abaixo
                    </DialogDescription>
                    <Form {...form}>
                        <form
                            onSubmit={form.handleSubmit(handleSubmit)}
                            className="space-y-4"
                        >
                            <FormField
                                control={form.control}
                                name="oldDate"
                                render={({ field }) => {
                                    return (
                                        <FormItem>
                                            <FormLabel>
                                                Última data de expiração:
                                            </FormLabel>
                                            <FormControl>
                                                <div className="flex items-center px-4 border">
                                                    <CalendarIcon className="w-4 h-4" />
                                                    <Input
                                                        className="border-none shadow-none text-neutral-500"
                                                        readOnly
                                                        value={
                                                            isValid(field.value)
                                                                ? format(
                                                                      field.value,
                                                                      'PPP',
                                                                      {
                                                                          locale: ptBR,
                                                                      }
                                                                  )
                                                                : '-'
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )
                                }}
                            />
                            <FormField
                                control={form.control}
                                name="newDate"
                                render={({ field }) => {
                                    return (
                                        <FormItem>
                                            <FormLabel>
                                                Nova data de expiração:
                                            </FormLabel>
                                            <FormControl>
                                                <DatePicker
                                                    className="w-full"
                                                    date={field.value}
                                                    setDate={field.onChange}
                                                    disabled={(date) =>
                                                        isBefore(
                                                            date,
                                                            new Date()
                                                        )
                                                    }
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )
                                }}
                            />
                            <div className="flex justify-end gap-2">
                                <Button
                                    variant="ghost"
                                    onClick={onClose}
                                    type="button"
                                >
                                    Cancelar
                                </Button>
                                <Button disabled={isPending}>Confirmar</Button>
                            </div>
                        </form>
                    </Form>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    )
}

export default ChangeDateDialog
