import {
    fetchPurchaseRequest,
    fetchPurchaseRequestFilters,
    fetchPurchaseRequestToSelect,
    fetchQuotationPurchaseRequest,
} from '@/api/business/solicitacaoDeCompra'
import {
    FetchPurchaseRequestProps,
    FetchPurchaseRequestToSelectProps,
    FetchQuotationPurchaseRequestProps,
    PurchaseRequestFlat,
} from '@/api/business/solicitacaoDeCompra/type'
import { Filter } from '@/types/Filters'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const purchaseRequestKeys = {
    all: ['purchaseRequest'] as const,
    lists: () => [...purchaseRequestKeys.all, 'list'] as const,
    listQuotation: (params: {
        perPage: number
        currentPage: number
        filters?: any
    }) => [...purchaseRequestKeys.lists(), 'quotation', params] as const,
    list: (params: { perPage: number; currentPage: number; filters?: any }) =>
        [...purchaseRequestKeys.lists(), params] as const,
    details: () => [...purchaseRequestKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...purchaseRequestKeys.details(), params] as const,
    filters: () => [...purchaseRequestKeys.all, 'filters'] as const,
    toSelect: (filters: Filter<PurchaseRequestFlat>[]) =>
        [...purchaseRequestKeys.all, 'toSelect', filters] as const,
}

interface UseQuotationPurchaseRequestListQueryProps
    extends FetchQuotationPurchaseRequestProps {
    enabled: boolean
}

type UsePurchaseRequestListQueryProps = FetchPurchaseRequestProps & {
    enabled: boolean
}

export const usePurchaseRequestListQuery = ({
    currentPage,
    perPage,
    filters,
    enabled,
}: UsePurchaseRequestListQueryProps) => {
    return useQuery({
        queryFn: () => fetchPurchaseRequest({ currentPage, perPage, filters }),
        queryKey: purchaseRequestKeys.list({
            currentPage,
            perPage,
            filters,
        }),
        placeholderData: keepPreviousData,
        enabled,
    })
}
export const useQuotationPurchaseRequestListQuery = ({
    currentPage,
    perPage,
    filters,
    enabled,
}: UseQuotationPurchaseRequestListQueryProps) => {
    return useQuery({
        queryFn: () =>
            fetchQuotationPurchaseRequest({ currentPage, perPage, filters }),
        queryKey: purchaseRequestKeys.listQuotation({
            currentPage,
            perPage,
            filters,
        }),
        placeholderData: keepPreviousData,
        enabled,
    })
}

export const usePurchaseRequestFiltersQuery = () => {
    return useQuery({
        queryFn: fetchPurchaseRequestFilters,
        queryKey: purchaseRequestKeys.filters(),
    })
}

type UsePurchaseRequestToSelectProps = FetchPurchaseRequestToSelectProps & {
    enabled?: boolean
}

export const usePurchaseRequestToSelectQuery = ({
    filters,
    enabled,
}: UsePurchaseRequestToSelectProps) => {
    return useQuery({
        queryFn: () => fetchPurchaseRequestToSelect({ filters }),
        queryKey: purchaseRequestKeys.toSelect(filters),
        enabled,
    })
}
