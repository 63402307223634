import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useTable } from '@/hooks/useTable'
import {
    useQuotationByIdQuery,
    useQuotationSuppliersQuery,
} from '@/queries/useQuotationQuery'
import { QuotationSupplier } from '@/types/Suppliers'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import RemoveSupplierDialog from './components/RemoveSupplierDialog'
import useDisclosure from '@/hooks/useDisclosure'
import { quotationDetailStateSelector } from '@/store/quotationDetailSlice'
import { useBaseStore } from '@/store'
import { isAxiosError } from '@/api/business'
import TopMessage from '@/components/TopMessage'
import SuppliersActions from './components/SuppliersActions'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { CheckCircle, CircleX, Clock, Minus } from 'lucide-react'
import { formatCpfOrCnpj } from '@/utils/stringFormatter'
import { cn } from '@/lib/utils'

type SuppliersTable = TableData<QuotationSupplier>

const columnHelper = createColumnHelper<QuotationSupplier>()

const SuppliersSection = () => {
    const { id } = useParams()
    const { selectedRows, setSelectedRows } = useTable()
    const { selectedRoundId, lastRoundId } = useBaseStore(
        quotationDetailStateSelector
    )

    const { data: quotation } = useQuotationByIdQuery({
        id: Number(id),
    })

    const {
        isOpen: isRemoveSupplierDialogOpen,
        onOpen: onRemoveSupplierDialogOpen,
        onClose: onRemoveSupplierDialogClose,
    } = useDisclosure()

    const {
        data: suppliers,
        isPending,
        isFetching,
        isError,
        error,
    } = useQuotationSuppliersQuery({
        quotationId: Number(id),
    })

    const data = useMemo(
        () =>
            suppliers?.filter(
                (supplier) => supplier.SK_COTACAO_X_RODADA === selectedRoundId
            ) || [],
        [suppliers, selectedRoundId]
    )

    const selectedSuppliers = useMemo(
        () =>
            data.filter((supplier) =>
                Object.keys(selectedRows).includes(supplier.SK_FORNECEDOR)
            ),
        [data, selectedRows, selectedRoundId]
    )

    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'STATUS',
                header: 'Status',
                cell: ({ row }) => {
                    const { DD_PUBLICADO, DD_DECLINADO, SK_COTACAO_X_RODADA } =
                        row.original

                    const commonClassName =
                        'flex-1 overflow-hidden text-xs whitespace-nowrap text-ellipsis'

                    if (DD_DECLINADO === 1) {
                        return (
                            <>
                                <CircleX
                                    size={14}
                                    className="flex-shrink-0 mr-2 text-destructive"
                                />
                                <TypographyP
                                    className={cn(
                                        commonClassName,
                                        'text-destructive'
                                    )}
                                >
                                    Declinado
                                </TypographyP>
                            </>
                        )
                    }

                    if (DD_PUBLICADO === 1) {
                        return (
                            <>
                                <CheckCircle
                                    size={14}
                                    className="flex-shrink-0 mr-2 text-green-600"
                                />
                                <TypographyP
                                    className={cn(
                                        commonClassName,
                                        'text-green-600'
                                    )}
                                >
                                    Finalizado
                                </TypographyP>
                            </>
                        )
                    }

                    if (lastRoundId !== SK_COTACAO_X_RODADA) {
                        return (
                            <>
                                <Minus
                                    size={14}
                                    className="flex-shrink-0 mr-2 text-accent-foreground"
                                />
                                <TypographyP
                                    className={cn(
                                        commonClassName,
                                        'text-accent-foreground'
                                    )}
                                >
                                    Sem resposta
                                </TypographyP>
                            </>
                        )
                    }

                    return (
                        <>
                            <Clock
                                size={14}
                                className="flex-shrink-0 mr-2 text-amber-600"
                            />
                            <TypographyP
                                className={cn(
                                    commonClassName,
                                    'text-amber-600'
                                )}
                            >
                                Pendente
                            </TypographyP>
                        </>
                    )
                },
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                size: 130,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('NK_FORNECEDOR', {
                id: 'NK_FORNECEDOR',
                header: 'Cod fornecedor',
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                size: 100,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_LOJA', {
                id: 'DD_LOJA',
                header: 'Loja',
                size: 60,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_NOME_FANTASIA', {
                id: 'DD_NOME_FANTASIA',
                header: 'Nome fantasia',
                size: 200,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                id: 'DD_RAZAO_SOCIAL',
                header: 'Razão social',
                size: 200,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DS_EMAIL', {
                id: 'DS_EMAIL',
                header: 'Email',
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
                size: 200,
            }),
            columnHelper.accessor('DD_CNPJ', {
                id: 'DD_CNPJ',
                header: 'CNPJ',
                size: 120,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? formatCpfOrCnpj(value) : '-',
                        className: 'text-xs border-none',
                    },
                },
            }),
            columnHelper.accessor('DD_OBSERVACAO', {
                id: 'DD_OBSERVACAO',
                header: 'Observação',
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
                size: 200,
            }),
            columnHelper.accessor('USUARIO', {
                id: 'USUARIO',
                header: 'Usuário cotação',
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
                size: 150,
            }),
            columnHelper.accessor('NUMERO_RODADA', {
                id: 'NUMERO_RODADA',
                header: 'Rodada',
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
                size: 150,
            }),
            columnHelper.accessor('MOTIVO_DECLINIO', {
                id: 'MOTIVO_DECLINIO',
                header: 'Motivo declinio',
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                meta: {
                    enableColumnOrdering: false,
                },
                size: 200,
            }),
        ],
        []
    )

    return (
        <>
            <div className="py-2">
                <div className="mb-4">
                    <SuppliersActions />
                    {selectedSuppliers.length > 0 && (
                        <div className="flex items-center gap-4">
                            <TypographyMuted>
                                {selectedSuppliers.length} fornecedores
                                selecionados
                            </TypographyMuted>
                            <div className="flex items-center gap-4">
                                <button
                                    className="text-sm text-neutral-500 hover:underline"
                                    onClick={onRemoveSupplierDialogOpen}
                                >
                                    Remover selecionados
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                {isFetching && !isPending && (
                    <TopMessage
                        text="Atualizando fornecedores..."
                        variant="loading"
                    />
                )}
                {suppliers?.length === 0 && !isPending ? (
                    <div className="flex items-center text-sm justify-center text-neutral-400 h-[100px]">
                        Cotação sem fornecedor
                    </div>
                ) : (
                    <div className="flex flex-col flex-1">
                        <Table<SuppliersTable>
                            data={data}
                            columns={columns}
                            isLoading={isPending}
                            isError={isError}
                            errorMessage={
                                isError && isAxiosError(error)
                                    ? error?.response?.data.message
                                    : 'Erro ao carregar dados'
                            }
                            getRowId={(row) => row.SK_FORNECEDOR.toString()}
                            tableState={{ rowSelection: selectedRows }}
                            onRowSelectionChange={setSelectedRows}
                            enableRowSelection={(row) =>
                                row.original.D_E_L_E_T_ === null &&
                                !!(quotation && quotation?.RODADAS.length === 1)
                            }
                            meta={{
                                layout: 'stretch',
                                cell: {
                                    className: 'border-none py-4 border-b',
                                },
                                header: {
                                    className:
                                        'border-r-0 border-t-0 group-first-of-type:border-l-0 border-b py-1.5',
                                },
                            }}
                            defaultColumn={{
                                cell: ({ getValue }) => (
                                    <DefaultColumn>
                                        {getValue() as string}
                                    </DefaultColumn>
                                ),
                            }}
                        />
                    </div>
                )}
            </div>
            <RemoveSupplierDialog
                isOpen={isRemoveSupplierDialogOpen}
                suppliers={selectedSuppliers}
                onClose={onRemoveSupplierDialogClose}
                onSelectRows={setSelectedRows}
            />
        </>
    )
}

export default SuppliersSection
