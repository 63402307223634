import { useBaseStore } from '@/store'
import { supplierByIdSelector } from '@/store/quotationMapSlice'
import { useState } from 'react'
import { ArrowLeft, Eye, Pencil } from 'lucide-react'
import EditMode from './components/EditMode'
import ViewMode from './components/ViewMode'
import { format, isValid } from 'date-fns'
import { Store } from '@/store/type'
import useDisclosure from '@/hooks/useDisclosure'
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateSupplierValues } from '@/api/business/cotacoes'
import { quotationMapKeys } from '@/queries/useQuotatioMapQuery'
import { isAxiosError } from '@/api/business'
import { Link, useParams } from 'react-router-dom'
import { useToast } from '@/components/ui/use-toast'
import {
    TypographyH1,
    TypographyMuted,
    TypographyP,
} from '@/components/ui/typography'
import { formatCpfOrCnpj } from '@/utils/stringFormatter'
import RoundSelect from '../QuotationDetail/components/QuotatioDetailsHeader/components/RoundSelect'
import { Button } from '@/components/ui/button'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { Separator } from '@/components/ui/separator'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

type Mode = 'edit' | 'view'

const stateSelector = (state: Store) => ({
    companies: state.quotationMapSlice.state.quotationMap.EMPRESAS,
    updatedValues: state.quotationMapSlice.state.supplierDialog.updatedValues,
    onResetUpdatedValues: state.quotationMapSlice.actions.onResetUpdatedValues,
})

const SupplierMap = () => {
    const { id: quotationId, supplierId } = useParams()
    const [mode, setMode] = useState<Mode>('view')

    const { data: quotationDetail } = useQuotationByIdQuery({
        id: Number(quotationId),
    })

    const supplier = useBaseStore(supplierByIdSelector(supplierId!))

    const { updatedValues, companies, onResetUpdatedValues } =
        useBaseStore(stateSelector)

    const {
        isOpen: isUnsavedChangesDialogOpen,
        onOpenChange: onUnsavedChangesDialogOpenChange,
    } = useDisclosure()

    const { toast } = useToast()
    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: updateSupplierValues,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: quotationMapKeys.all })
            toast({
                title: 'Resposta salva com sucesso',
            })
            onResetUpdatedValues()
            onUnsavedChangesDialogOpenChange(false)
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível salvar respostas. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao salvar',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    return (
        <>
            <div className="p-4">
                <Link
                    className="flex items-center gap-2"
                    to={`/cotacoes/${quotationId}/?tab=map`}
                >
                    <ArrowLeft size={14} />
                    <TypographyP>Voltar para o mapa</TypographyP>
                </Link>
            </div>
            <Separator />
            <div className="p-4 space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <div>
                            <TypographyH1>
                                {supplier?.DD_RAZAO_SOCIAL}
                            </TypographyH1>
                        </div>
                        {supplier?.FORNECEDOR_DECLINADO === 1 ? (
                            <span className="px-2 py-1 text-sm text-red-500 rounded-md bg-red-50">
                                Declinado
                            </span>
                        ) : supplier?.FORNECEDOR_PUBLICADO === 1 ? (
                            <span className="px-2 py-1 text-sm rounded-md bg-sky-50 text-sky-500">
                                Finalizado
                            </span>
                        ) : (
                            <span className="px-2 py-1 text-sm rounded-md bg-amber-50 text-amber-600">
                                Pendente
                            </span>
                        )}
                    </div>
                    <div className="flex items-center gap-4 mr-4">
                        <Button
                            variant="outline"
                            className="space-x-2"
                            disabled={
                                !!quotationDetail?.APROVACAO &&
                                ['APROVADO', 'EM_APROVACAO'].includes(
                                    quotationDetail?.APROVACAO?.DD_STATUS
                                )
                            }
                            onClick={() =>
                                setMode((prev) =>
                                    prev === 'edit' ? 'view' : 'edit'
                                )
                            }
                        >
                            {mode === 'edit' ? (
                                <>
                                    <Eye size={14} className="text-sky-500" />
                                    <span>Modo visualização</span>
                                </>
                            ) : (
                                <>
                                    <Pencil
                                        size={14}
                                        className="text-amber-500"
                                    />
                                    <span>Modo edição</span>
                                </>
                            )}
                        </Button>
                        <RoundSelect />
                    </div>
                </div>
                <div className="flex items-center gap-6">
                    <div>
                        <TypographyMuted>Nome fantasia</TypographyMuted>
                        <TypographyP>{supplier?.DD_NOME_FANTASIA}</TypographyP>
                    </div>
                    <div>
                        <TypographyMuted>Razão social</TypographyMuted>
                        <TypographyP>{supplier?.DD_RAZAO_SOCIAL}</TypographyP>
                    </div>
                    {supplier?.DD_CNPJ && (
                        <div>
                            <TypographyMuted>CNPJ</TypographyMuted>
                            <TypographyP>
                                {formatCpfOrCnpj(supplier.DD_CNPJ)}
                            </TypographyP>
                        </div>
                    )}
                    <div>
                        <TypographyMuted>Adicionado em</TypographyMuted>
                        <TypographyP>
                            {supplier?.CREATED_AT &&
                            isValid(new Date(supplier?.CREATED_AT))
                                ? format(
                                      new Date(supplier.CREATED_AT),
                                      'dd/MM/yyyy, HH:mm:ss'
                                  )
                                : '-'}
                        </TypographyP>
                    </div>
                </div>
                <Separator />
                <Tabs defaultValue={companies[0]?.SK_EMPRESA}>
                    <TabsList>
                        {companies.map((company) => {
                            return (
                                <TabsTrigger
                                    key={company.SK_EMPRESA}
                                    value={company.SK_EMPRESA}
                                >
                                    {company.ABREVIATURA_EMPRESA}
                                </TabsTrigger>
                            )
                        })}
                    </TabsList>
                    {companies.map((company) => {
                        return (
                            <TabsContent
                                key={company.SK_EMPRESA}
                                value={company.SK_EMPRESA}
                            >
                                {mode === 'edit' ? (
                                    <EditMode
                                        companyId={company.SK_EMPRESA}
                                        supplierId={supplierId!}
                                    />
                                ) : (
                                    <ViewMode
                                        companyId={company.SK_EMPRESA}
                                        supplierId={supplierId!}
                                    />
                                )}
                            </TabsContent>
                        )
                    })}
                </Tabs>
            </div>
            <UnsavedChangesDialog
                isOpen={isUnsavedChangesDialogOpen}
                onConfirm={() =>
                    mutate({
                        products: updatedValues,
                        quotationId: Number(quotationId),
                        supplierId: supplierId!,
                    })
                }
                isLoading={isPending}
                onDiscard={() => {
                    onUnsavedChangesDialogOpenChange(false)
                    onResetUpdatedValues()
                }}
                onOpenChange={onUnsavedChangesDialogOpenChange}
            />
        </>
    )
}

export default SupplierMap
