import { publishSupplier } from '@/api/business/cotacoes'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { useToast } from '@/components/ui/use-toast'
import { useBaseStore } from '@/store'
import { getUpdatedSupplierValuesSelector } from '@/store/quotationMapSlice'
import { Store } from '@/store/type'
import { DisclosureCommonProps } from '@/types/Disclosure'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { AlertTriangle } from 'lucide-react'
import { useParams } from 'react-router-dom'
import PublishTable from './components/PublishTable'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { quotationMapKeys } from '@/queries/useQuotatioMapQuery'
import { quotationKeys } from '@/queries/useQuotationQuery'

type PublishDialogProps = DisclosureCommonProps

const stateSelector = (state: Store) => ({
    supplierId: state.quotationMapSlice.state.supplierDialog.supplierId,
    updatedValues: state.quotationMapSlice.state.supplierDialog.updatedValues,
})

const PublishDialog = ({ isOpen, onOpenChange }: PublishDialogProps) => {
    const { toast } = useToast()
    const { id: quotationId } = useParams()
    const { supplierId, updatedValues } = useBaseStore(stateSelector)
    const queryClient = useQueryClient()

    const values = useBaseStore(getUpdatedSupplierValuesSelector(supplierId))

    const { mutate, isPending } = useMutation({
        mutationFn: publishSupplier,
        onSuccess: () => {
            const id = Number(quotationId)

            if (id) {
                queryClient.invalidateQueries({
                    queryKey: quotationMapKeys.detail(id),
                })
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(id),
                })
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detailSuppliers(Number(supplierId)),
                })
            }

            toast({
                title: 'Cotação publicada com sucesso',
            })
            onOpenChange(false)
        },
        onError: (error) => {
            let message =
                'Houve um erro ao publicar cotação. Tente novamente mais tarde.'

            if (isAxiosError(error)) {
                message = error.response?.data.message || message
            }

            toast({
                title: 'Erro ao publicar',
                description: message,
            })
        },
    })

    const onMutate = () => {
        mutate({
            quotationId: Number(quotationId),
            supplierId,
        })
    }

    const renderContent = () => {
        if (updatedValues.length > 0) {
            return (
                <DialogContent className="max-h-[80%] max-w-2xl flex flex-col overflow-hidden">
                    <DialogHeader>
                        <DialogTitle>Publicar</DialogTitle>
                    </DialogHeader>
                    <div className="flex flex-col gap-2">
                        <div className="py-4">
                            <TypographyP>
                                Você tem alterações não salvas. Salve-as antes
                                de publicar.
                            </TypographyP>
                        </div>
                        <div className="ml-auto">
                            <Button onClick={() => onOpenChange(false)}>
                                OK
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            )
        }

        const noFreight = values.filter((product) => !product.FRETE)

        if (noFreight.length > 0) {
            const uniqueCompanies = [
                ...new Set(
                    noFreight.map((product) => product.ABREVIATURA_EMPRESA)
                ),
            ]
            return (
                <DialogContent className="max-h-[80%] max-w-2xl flex flex-col overflow-hidden">
                    <DialogHeader>
                        <DialogTitle>Publicar</DialogTitle>
                    </DialogHeader>
                    <div className="flex flex-col gap-2">
                        <div className="py-4">
                            <TypographyP>
                                Para publicar é necessário selecionar um frete.
                            </TypographyP>
                            {uniqueCompanies.map((company) => (
                                <div
                                    key={company}
                                    className="flex items-center gap-2"
                                >
                                    <AlertTriangle size={14} />
                                    <p>{company}</p>
                                </div>
                            ))}
                        </div>
                        <div className="ml-auto">
                            <Button onClick={() => onOpenChange(false)}>
                                OK
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            )
        }

        const noPaymentCondition = values.filter(
            (product) =>
                !product.SK_CONDICAO_DE_PAGAMENTO &&
                !product.DS_CONDICAO_DE_PAGAMENTO_MANUAL
        )

        if (noPaymentCondition.length > 0) {
            const uniqueCompanies = [
                ...new Set(
                    noPaymentCondition.map(
                        (product) => product.ABREVIATURA_EMPRESA
                    )
                ),
            ]
            return (
                <DialogContent className="max-h-[80%] max-w-2xl flex flex-col overflow-hidden">
                    <DialogHeader>
                        <DialogTitle>Publicar</DialogTitle>
                    </DialogHeader>
                    <div className="flex flex-col gap-2">
                        <div className="py-4">
                            <TypographyP>
                                Para publicar é necessário selecionar uma
                                condição de pagamento.
                            </TypographyP>
                            {uniqueCompanies.map((company) => (
                                <div
                                    key={company}
                                    className="flex items-center gap-2"
                                >
                                    <AlertTriangle size={14} />
                                    <p>{company}</p>
                                </div>
                            ))}
                        </div>
                        <div className="ml-auto">
                            <Button onClick={() => onOpenChange(false)}>
                                OK
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            )
        }

        const requiredFields = values.map((product) => ({
            DS_PRODUTO: product.DS_PRODUTO,
            SK_PRODUTO: product.SK_PRODUTO,
            PRECO: product.PRECO,
            PREVISAO_DE_ENTREGA: product.PREVISAO_DE_ENTREGA,
        }))

        const missingFields = requiredFields
            .map((product) => {
                return Object.entries(product).filter(([, value]) => !value)
            })
            .filter((product) => !product.every(([, value]) => Boolean(value)))

        if (missingFields.some((miss) => miss.length > 0)) {
            const originalMissing = values.filter((product) =>
                values.some((p) => p.SK_PRODUTO === product.SK_PRODUTO)
            )

            const companies = [
                ...new Set(
                    originalMissing.map((product) => product.SK_EMPRESA)
                ),
            ].map((id) => {
                const found = values.find(
                    (company) => company.SK_EMPRESA === id
                )
                return {
                    SK_EMPRESA: found?.SK_EMPRESA,
                    ABREVIATURA_EMPRESA: found?.ABREVIATURA_EMPRESA,
                }
            })

            return (
                <DialogContent className="max-h-[80%] max-w-2xl flex flex-col overflow-hidden">
                    <DialogHeader>
                        <DialogTitle>Publicar</DialogTitle>
                    </DialogHeader>
                    <div className="w-full overflow-hidden">
                        <TypographyMuted>
                            Os seguintes produtos estão com informações
                            faltando, preencha-os antes de publicar.
                        </TypographyMuted>
                        <div className="flex-1 overflow-auto">
                            {companies.map((company) => {
                                return (
                                    <div key={company.SK_EMPRESA}>
                                        <TypographyMuted>
                                            {company.ABREVIATURA_EMPRESA}
                                        </TypographyMuted>
                                        <div className="p-2 space-y-0.5 bg-slate-50">
                                            {originalMissing
                                                .filter(
                                                    (product) =>
                                                        product.SK_EMPRESA ===
                                                        company.SK_EMPRESA
                                                )
                                                .map((product) => {
                                                    return (
                                                        <div
                                                            key={
                                                                product.SK_RESPOSTA_FORNECEDOR
                                                            }
                                                            className="text-sm text-neutral-600"
                                                        >
                                                            {product.DS_PRODUTO}
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="flex justify-end">
                            <Button
                                variant="ghost"
                                onClick={() => onOpenChange(false)}
                            >
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            )
        }

        return (
            <DialogContent className="max-h-[80%] max-w-[90%] flex flex-col overflow-hidden">
                <DialogHeader>
                    <DialogTitle>Publicar</DialogTitle>
                    <DialogDescription>
                        Abaixo estão os valores a serem publicados
                    </DialogDescription>
                </DialogHeader>
                <div className="w-full space-y-4 overflow-hidden">
                    <PublishTable />
                    <div className="flex items-center justify-end gap-4">
                        <Button
                            variant="ghost"
                            onClick={() => onOpenChange(false)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            className="w-[100px]"
                            onClick={onMutate}
                            disabled={isPending}
                        >
                            Confirmar
                        </Button>
                    </div>
                </div>
            </DialogContent>
        )
    }

    return (
        <Dialog onOpenChange={onOpenChange} open={isOpen}>
            {renderContent()}
        </Dialog>
    )
}

export default PublishDialog
