import { Button } from '@/components/ui/button'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { QuotationMapSupplierValuesState } from '@/store/quotationMapSlice/type'
import { formatCpfOrCnpj } from '@/utils/stringFormatter'
import { CheckCircle, Clock, Eye, XCircle } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

type HeaderContentProps = {
    supplier: QuotationMapSupplierValuesState
}

const HeaderContent = ({ supplier }: HeaderContentProps) => {
    const navigate = useNavigate()

    const declined = supplier.FORNECEDOR_DECLINADO === 1
    const finished = supplier.FORNECEDOR_PUBLICADO === 1

    return (
        <div className="flex gap-2 overflow-hidden">
            <Button
                variant="outline"
                size="icon"
                onClick={() =>
                    navigate(`mapa/fornecedor/${supplier.SK_FORNECEDOR}`)
                }
            >
                <Eye className="text-primary-600" />
            </Button>
            <div className="flex-1 overflow-hidden">
                <TypographyP className="text-xs truncate">
                    {supplier.DD_NOME_FANTASIA}
                </TypographyP>
                <TypographyMuted className="text-xs truncate">
                    {supplier.DD_RAZAO_SOCIAL} -{' '}
                    {supplier.DD_CNPJ && formatCpfOrCnpj(supplier.DD_CNPJ)}
                </TypographyMuted>
                <TypographyP
                    className={cn(
                        'flex items-center gap-1 text-xs text-amber-500',
                        finished && 'text-green-500',
                        declined && 'text-red-500'
                    )}
                >
                    {declined ? (
                        <>
                            <XCircle size={14} />
                            Declinado
                        </>
                    ) : finished ? (
                        <>
                            <CheckCircle size={14} />
                            Finalizado
                        </>
                    ) : (
                        <>
                            <Clock size={14} />
                            Pendente
                        </>
                    )}
                </TypographyP>
            </div>
        </div>
    )
}

export default HeaderContent
