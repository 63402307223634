import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog'
import { useShippingCompanyQuery } from '@/queries/useShippingCompanyQuery'
import { Paintbrush } from 'lucide-react'
import ShippingList from './components/ShippingList'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { useEffect, useMemo, useState } from 'react'
import useDisclosure from '@/hooks/useDisclosure'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addShippingCompany } from '@/api/business/cotacoes'
import { isAxiosError } from '@/api/business'
import { useToast } from '@/components/ui/use-toast'
import { quotationKeys } from '@/queries/useQuotationQuery'
import { quotationMapKeys } from '@/queries/useQuotatioMapQuery'
// import { getSelectedShippingCompanySelector } from '@/store/quotationMapSlice'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import TopMessage from '@/components/TopMessage'
import RemoveShippingDialog from '../RemoveShippingDialog'
import { FetchQuotationMapResponse } from '@/api/business/cotacoes/type'
import { currencyFormat } from '@/utils/stringFormatter'

const stateSelector = (state: Store) => ({
    selectedCompanyId:
        state.quotationMapSlice.state.supplierDialog.selectedCompanyId,
    supplierId: state.quotationMapSlice.state.supplierDialog.supplierId,
    supplierValues: state.quotationMapSlice.state.supplierDialog.supplierValues,
    lastRoundId: state.quotationDetailSlice.state.lastRoundId,
    onUpdateSupplierShippingCompany:
        state.quotationMapSlice.actions.onUpdateSupplierShippingCompany,
})

const ShippingDialog = () => {
    const { id } = useParams()
    const {
        selectedCompanyId,
        supplierId,
        supplierValues,
        lastRoundId,
        onUpdateSupplierShippingCompany,
    } = useBaseStore(stateSelector)

    const values = supplierValues.filter(
        (value) => value.SK_EMPRESA === selectedCompanyId
    )

    // const shippingCompany = useBaseStore(getSelectedShippingCompanySelector)
    const shippingCompany = {
        SK_TRANSPORTADORA: values[0]?.SK_TRANSPORTADORA,
        NM_TRANSPORTADORA: values[0]?.NM_TRANSPORTADORA,
        VL_FRETE: values[0]?.VL_FRETE,
    }

    const { isOpen, onOpenChange } = useDisclosure()
    const {
        isOpen: isRemoveDialogOpen,
        onOpenChange: onRemoveDialogOpenChange,
    } = useDisclosure()

    const [selectedId, setSelectedId] = useState<string | null>(
        shippingCompany.SK_TRANSPORTADORA
    )

    const [shippingInputValue, setShippingInputValue] = useState('')
    const [shippingCompanyError, setShippingCompanyError] = useState(false)

    const [ufInputValue, setUfInputValue] = useState('')

    const [freightValue, setFreightValue] = useState(shippingCompany.VL_FRETE)
    const [freightError, setFreightError] = useState(false)

    const queryClient = useQueryClient()

    const {
        data,
        isPending: isShippingPending,
        isError: isShippingError,
        isFetching: isShippingFetching,
    } = useShippingCompanyQuery({
        enabled: true,
    })

    const [filteredData, setFilteredData] = useState(data || [])

    const { toast } = useToast()

    const { mutate, isPending } = useMutation({
        mutationFn: addShippingCompany,
        onSuccess: (_, vars) => {
            toast({
                title: 'Transportadora adicionada',
                description: 'A transportadora foi adicionada com sucesso.',
                variant: 'success',
            })

            const shippingName =
                data?.find(
                    (shipping) =>
                        shipping.SK_TRANSPORTADORA === vars.shippingCompanyId
                )?.NM_TRANSPORTADORA || ''

            onUpdateSupplierShippingCompany(
                { id: vars.shippingCompanyId, name: shippingName },
                Number(vars.freightValue)
            )

            if (id) {
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(Number(id)),
                })
                queryClient.invalidateQueries({
                    queryKey: quotationMapKeys.detail(Number(id)),
                })

                queryClient.setQueryData<FetchQuotationMapResponse>(
                    quotationMapKeys.detail(Number(id)),
                    (prev) =>
                        prev
                            ? {
                                  ...prev,
                                  VALORES: prev.VALORES.map((value) => {
                                      if (
                                          value.SK_COTACAO_X_RODADA ===
                                              lastRoundId &&
                                          value.SK_FORNECEDOR_X_RODADA ===
                                              values[0]
                                                  .SK_FORNECEDOR_X_RODADA &&
                                          value.SK_EMPRESA ===
                                              values[0].SK_EMPRESA
                                      ) {
                                          return {
                                              ...value,
                                              SK_TRANSPORTADORA:
                                                  vars.shippingCompanyId,
                                              NM_TRANSPORTADORA: shippingName,
                                          }
                                      }

                                      return value
                                  }),
                              }
                            : undefined
                )
            }

            onOpenChange(false)
            onReset()
        },
        onError: (error) => {
            let message =
                'Houve um erro ao adicionar transportadora. Tente novamente mais tarde.'

            if (isAxiosError(error)) {
                message = error.response?.data.message || message
            }

            toast({
                title: 'Erro ao dicionar transportadora',
                description: message,
                variant: 'destructive',
            })
        },
    })

    const uniqueStates = useMemo(
        () =>
            [...new Set(data?.map((shipping) => shipping.DD_UF))]
                .filter(Boolean)
                .sort((a, b) => a!.localeCompare(b!)),
        [data]
    )

    const onItemClick = (id: string) => {
        setSelectedId(id)
    }

    const onShippingChange = (value: string) => {
        if (!data) return

        setFilteredData(
            data.filter((shipping) => {
                const company = shipping.NM_TRANSPORTADORA.trim().toLowerCase()
                const tradeName = shipping.NM_REDUZIDO?.trim().toLowerCase()
                const shippingUf = shipping.DD_UF
                const newValue = value.trim().toLowerCase()

                return (
                    (company.includes(newValue) ||
                        tradeName?.includes(newValue)) &&
                    shippingUf?.includes(ufInputValue)
                )
            })
        )
        setShippingInputValue(value)
    }

    const onUfChange = (value: string) => {
        if (!data) return
        setFilteredData(
            data.filter((shipping) => shipping.DD_UF?.includes(value))
        )
        setUfInputValue(value)
    }

    const onClear = () => {
        if (data) setFilteredData(data)
        setUfInputValue('')
        setShippingInputValue('')
    }

    const onReset = () => {
        setShippingCompanyError(false)
        setFreightError(false)
        setUfInputValue('')
        setShippingInputValue('')
        setFreightValue('')
        setSelectedId(null)

        if (data) setFilteredData(data)
    }

    const onSubmit = () => {
        if (!selectedId) {
            setShippingCompanyError(true)

            return
        }

        if (!freightValue) {
            setFreightError(true)

            return
        }

        if (selectedId)
            mutate({
                companyId: selectedCompanyId,
                quotationId: Number(id),
                shippingCompanyId: selectedId,
                supplierId,
                freightValue,
            })
    }

    useEffect(() => {
        if (data) setFilteredData(data)
    }, [data])

    useEffect(() => {
        if (!isOpen) {
            onReset()
        } else {
            setSelectedId(shippingCompany.SK_TRANSPORTADORA)
            setFreightValue(shippingCompany.VL_FRETE)
        }
    }, [isOpen])

    return (
        <>
            <Dialog open={isOpen} onOpenChange={onOpenChange}>
                <DialogTrigger className="justify-start space-y-1.5">
                    <TypographyMuted className="text-start">
                        Transportadora
                    </TypographyMuted>
                    <Button className="self-end gap-3" variant="outline">
                        {shippingCompany.NM_TRANSPORTADORA
                            ? shippingCompany.NM_TRANSPORTADORA
                            : 'Selecionar transportadora'}
                        - Frete:{' '}
                        {currencyFormat(
                            Number(shippingCompany.VL_FRETE) || 0,
                            4
                        )}
                    </Button>
                </DialogTrigger>
                <DialogContent className="max-h-[90%] flex flex-col overflow-hidden">
                    <DialogHeader>
                        <DialogTitle>Adicionar transportadora</DialogTitle>
                        <DialogDescription>
                            Selecione uma transportadora e informe o frete
                        </DialogDescription>
                    </DialogHeader>
                    <div className="flex items-center gap-2">
                        <div className="flex-1">
                            <Label>Transportadora</Label>
                            <Input
                                value={shippingInputValue}
                                onChange={(e) =>
                                    onShippingChange(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <Label>UF</Label>
                            <Select
                                value={ufInputValue}
                                onValueChange={(value) => onUfChange(value)}
                            >
                                <SelectTrigger className="w-[100px]">
                                    <SelectValue placeholder="Selecione um item" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {uniqueStates.map((state) => {
                                            return (
                                                <SelectItem
                                                    key={state}
                                                    value={state!}
                                                >
                                                    {state}
                                                </SelectItem>
                                            )
                                        })}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="self-end">
                            <Button
                                variant="outline"
                                size="icon"
                                onClick={onClear}
                            >
                                <Paintbrush size={14} />
                            </Button>
                        </div>
                    </div>
                    {isShippingPending && !data ? (
                        <TopMessage
                            text="Carregando transportadoras"
                            variant="loading"
                        />
                    ) : null}
                    {isShippingFetching && data ? (
                        <TopMessage
                            text="Carregando transportadoras"
                            variant="success"
                        />
                    ) : null}
                    {isShippingError && (
                        <TopMessage
                            text="Erro ao listar transportadoras"
                            variant="error"
                        />
                    )}
                    {shippingCompanyError && (
                        <TypographyP>
                            Necessário selecionar transportadora
                        </TypographyP>
                    )}
                    {data && (
                        <ShippingList
                            data={filteredData}
                            onItemClick={onItemClick}
                            selectedId={selectedId}
                        />
                    )}
                    <div className="flex items-center gap-2">
                        <Label
                            className={cn(
                                'whitespace-nowrap',
                                freightError && 'text-red-500'
                            )}
                        >
                            Valor do frete:
                        </Label>
                        <Input
                            type="number"
                            value={freightValue || ''}
                            onChange={(e) => setFreightValue(e.target.value)}
                            min={0}
                        />
                    </div>
                    <DialogFooter>
                        <div className="flex-1">
                            {shippingCompany.SK_TRANSPORTADORA && (
                                <Button
                                    variant="destructive"
                                    onClick={() =>
                                        onRemoveDialogOpenChange(true)
                                    }
                                >
                                    Remover transportadora
                                </Button>
                            )}
                        </div>
                        <div>
                            <Button
                                variant="ghost"
                                onClick={() => onOpenChange(false)}
                            >
                                Cancelar
                            </Button>
                            <Button
                                disabled={
                                    !selectedId || !freightValue || isPending
                                }
                                onClick={() => onSubmit()}
                            >
                                Confirmar
                            </Button>
                        </div>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <RemoveShippingDialog
                isOpen={isRemoveDialogOpen}
                companyId={selectedCompanyId}
                supplierId={supplierId}
                onOpenChange={onRemoveDialogOpenChange}
            />
        </>
    )
}

export default ShippingDialog
