import { cn } from '@/lib/utils'
import { useBaseStore } from '@/store'
import { QuotationMapValuesState } from '@/store/quotationMapSlice/type'
import { Store } from '@/store/type'
import { ChevronDown, CornerDownRight } from 'lucide-react'

type CellContentProps = {
    data: QuotationMapValuesState
    dataKey: keyof QuotationMapValuesState
    shouldGroup?: boolean
    className?: string
    formatter?: (value: string) => string
}

const stateSelector = (state: Store) => ({
    toggleState:
        state.quotationMapSlice.state.quotationMapTableGroupVisibilityState,
    onToggle: state.quotationMapSlice.actions.toggleGroupVisibilityState,
})

const CellContent = ({
    data,
    shouldGroup,
    dataKey,
    className,
    formatter,
}: CellContentProps) => {
    const { toggleState, onToggle } = useBaseStore(stateSelector)

    const isOpen = toggleState[data.SK_RESPOSTA_FORNECEDOR]

    const value = data[dataKey] as string

    const children = formatter ? formatter(value) : value
    const hasGroup = data.GRUPO?.length > 0

    return (
        <div className={cn('w-full divide-y', !hasGroup && 'h-full')}>
            <div
                className={cn(
                    'relative text-foreground flex flex-1 p-3 truncate items-center justify-end h-full gap-2',
                    shouldGroup && hasGroup ? 'justify-between ' : null,
                    hasGroup
                        ? 'cursor-pointer text-primary-700'
                        : 'items-start h-full',
                    className
                )}
                onClick={() => onToggle(data.SK_RESPOSTA_FORNECEDOR)}
            >
                {hasGroup && shouldGroup ? (
                    <div className="flex items-center gap-1">
                        <ChevronDown
                            className={cn(
                                'font-semibold text-primary-700 ease-in duration-150',
                                isOpen ? '-rotate-180' : ''
                            )}
                            size={16}
                        />
                        <div className="px-1 rounded-md bg-primary-200 text-primary-800">
                            +{data.GRUPO.length}
                        </div>
                    </div>
                ) : data.SK_RESPOSTA_SUPERIOR && shouldGroup ? (
                    <CornerDownRight size={11} className="ml-2 mr-auto " />
                ) : null}
                <p className={cn('text-right text-xs truncate')}>
                    {value ? children : '-'}
                </p>
            </div>
            {hasGroup && isOpen ? (
                <div className="w-full divide-y">
                    {data.GRUPO.map((group) => {
                        return (
                            <CellContent
                                key={group.SK_RESPOSTA_FORNECEDOR}
                                data={group as QuotationMapValuesState}
                                dataKey={dataKey}
                                className={className}
                                shouldGroup={shouldGroup}
                                formatter={formatter}
                            />
                        )
                    })}
                </div>
            ) : null}
        </div>
    )
}

export default CellContent
