import { useBaseStore } from '@/store'
import { QuotationMapFlatState } from '@/store/quotationMapSlice/type'
import { Store } from '@/store/type'
import { Column, Row } from '@tanstack/react-table'
import { Copy, ListX, RotateCcw, X } from 'lucide-react'
import AddProductGroupDialog from '../../../AddProductGroupDialog'
import RemoveProductGroupDialog from '../../../RemoveProductGroupDialog'
import useDisclosure from '@/hooks/useDisclosure'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'

type RowActionsProps = {
    row: Row<QuotationMapFlatState>
    column: Column<QuotationMapFlatState>
}

const stateSelector = (state: Store) => ({
    onUpdateSupplierValue:
        state.quotationMapSlice.actions.onUpdateSupplierValue,
})

const RowActions = ({ row, column }: RowActionsProps) => {
    const { onUpdateSupplierValue } = useBaseStore(stateSelector)

    const product = row.original

    const { DD_RECUSADO, GRUPO, SK_RESPOSTA_SUPERIOR } = product

    const hasGroup = GRUPO && GRUPO.length > 0

    const {
        isOpen: isAddGroupDialogOpen,
        onOpenChange: onAddGroupDialogOpenChange,
    } = useDisclosure()

    const {
        isOpen: isRemoveGroupDialogOpen,
        onOpenChange: onRemoveGroupDialogOpenChange,
    } = useDisclosure()

    return (
        <>
            {!SK_RESPOSTA_SUPERIOR && (
                <>
                    <div className="flex items-center justify-center w-full gap-2 p-1">
                        <ButtonWithTooltip
                            tooltipContent="Duplicar linha"
                            variant="ghost"
                            className="w-6 h-6"
                            onClick={() => onAddGroupDialogOpenChange(true)}
                        >
                            <Copy size={14} className="text-blue-500" />
                        </ButtonWithTooltip>
                        {hasGroup ? (
                            <ButtonWithTooltip
                                tooltipContent="Duplicar linha"
                                variant="ghost"
                                className="w-6 h-6"
                                onClick={() =>
                                    onRemoveGroupDialogOpenChange(true)
                                }
                            >
                                <ListX size={14} className="text-blue-500" />
                            </ButtonWithTooltip>
                        ) : DD_RECUSADO !== 1 ? (
                            <ButtonWithTooltip
                                tooltipContent="Declinar produto"
                                variant="ghost"
                                className="w-6 h-6"
                                onClick={() =>
                                    onUpdateSupplierValue(
                                        row.id,
                                        'DD_RECUSADO',
                                        1,
                                        column.columnDef
                                    )
                                }
                            >
                                <X size={14} className="text-red-500" />
                            </ButtonWithTooltip>
                        ) : (
                            <ButtonWithTooltip
                                tooltipContent="Declinar produto"
                                variant="ghost"
                                className="w-6 h-6"
                                onClick={() =>
                                    onUpdateSupplierValue(
                                        row.id,
                                        'DD_RECUSADO',
                                        0,
                                        column.columnDef
                                    )
                                }
                            >
                                <RotateCcw
                                    size={14}
                                    className="text-secondary-500"
                                />
                            </ButtonWithTooltip>
                        )}
                    </div>
                    <AddProductGroupDialog
                        product={product}
                        isOpen={isAddGroupDialogOpen}
                        onOpenChange={onAddGroupDialogOpenChange}
                    />
                    <RemoveProductGroupDialog
                        product={product}
                        isOpen={isRemoveGroupDialogOpen}
                        onOpenChange={onRemoveGroupDialogOpenChange}
                    />
                </>
            )}
        </>
    )
}

export default RowActions
