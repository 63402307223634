import { TypographyP } from '@/components/ui/typography'
import {
    Section,
    SectionContent,
    SectionHeader,
    SectionTitle,
    SectionValue,
} from '../Section'
import { currencyFormat } from '@/utils/stringFormatter'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import { quotationMapFlatSelector } from '@/store/quotationMapSlice'
import { QuotationMapFlatState } from '@/store/quotationMapSlice/type'
import { useCallback, useMemo } from 'react'

export type SupplierTotals = {
    supplier: string
    supplierId: string
    totalInitial: number
    totalFinal: number
    totalDifference: number
    totalTax: number
    financialSaving: number
    financialSavingPercentage: number
    negotiationSaving: number
    negotiationSavingPercentage: number
    baselineSaving: number
    baselineSavingPercentage: number
}

const stateSelector = (state: Store) => ({
    supplierMap: state.quotationMapSlice.state.quotationMap.FORNECEDORES,
})

const SupplierSection = () => {
    const { supplierMap } = useBaseStore(stateSelector)
    const quotationMap = useBaseStore(quotationMapFlatSelector)

    const suppliersMap = useMemo(
        () => Object.values(supplierMap.entities) || [],
        [supplierMap]
    )

    const getTotals = useCallback((values: QuotationMapFlatState[]) => {
        return values.reduce(
            (acc, curr) => {
                const {
                    PRECO_TOTAL,
                    PRECO_TOTAL_IMPOSTO,
                    SAVING_BASELINE,
                    SAVING_FINANCEIRO,
                    SAVING_NEGOCIACAO,
                } = curr

                const total = acc.total + Number(PRECO_TOTAL)
                const totalTax = acc.totalTax + Number(PRECO_TOTAL_IMPOSTO)
                const financialSaving =
                    acc.financialSaving + Number(SAVING_FINANCEIRO) || 0
                const negotiationSaving =
                    acc.negotiationSaving + Number(SAVING_NEGOCIACAO) || 0
                const baselineSaving =
                    acc.baselineSaving + Number(SAVING_BASELINE) || 0

                return {
                    ...acc,
                    total,
                    totalTax,
                    financialSaving,
                    negotiationSaving,
                    baselineSaving,
                }
            },
            {
                total: 0,
                totalTax: 0,
                financialSaving: 0,
                negotiationSaving: 0,
                baselineSaving: 0,
            }
        )
    }, [])

    const suppliers = useMemo(() => {
        const normalized: Record<string, SupplierTotals> = suppliersMap.reduce(
            (acc, curr) => {
                const supplierFirstRound = Math.min(
                    ...curr.FORNECEDOR_COTACAO_RODADAS
                )
                const supplierLastRound = Math.max(
                    ...curr.FORNECEDOR_COTACAO_RODADAS
                )

                const firstRoundValues = quotationMap.filter(
                    (value) =>
                        value.SK_COTACAO_X_RODADA === supplierFirstRound &&
                        value.SK_FORNECEDOR === curr.SK_FORNECEDOR
                )

                const lastRoundValues = quotationMap.filter(
                    (value) =>
                        value.SK_COTACAO_X_RODADA === supplierLastRound &&
                        value.SK_FORNECEDOR === curr.SK_FORNECEDOR
                )

                const initialTotals = getTotals(firstRoundValues)
                const finalTotals = getTotals(lastRoundValues)

                return {
                    ...acc,
                    [curr.SK_FORNECEDOR]: {
                        supplier: curr.DD_NOME_FANTASIA,
                        supplierId: curr.SK_FORNECEDOR,
                        totalInitial: initialTotals.total,
                        totalFinal: finalTotals.total,
                        totalDifference:
                            initialTotals.total - finalTotals.total,
                        totalTax: finalTotals.totalTax,
                        financialSaving: finalTotals.financialSaving,
                        financialSavingPercentage: finalTotals.baselineSaving,
                        negotiationSaving: finalTotals.negotiationSaving,
                        negotiationSavingPercentage: finalTotals.baselineSaving,
                        baselineSaving: finalTotals.baselineSaving,
                        baselineSavingPercentage: finalTotals.baselineSaving,
                    },
                }
            },
            {} as Record<string, SupplierTotals>
        )

        return Object.values(normalized)
    }, [quotationMap, suppliersMap])

    return (
        <Section>
            <SectionHeader>
                <SectionTitle>Fornecedores</SectionTitle>
            </SectionHeader>
            <SectionContent>
                {suppliers.map((supplier) => {
                    return (
                        <div
                            key={supplier.supplierId}
                            className="p-4 space-y-2 border rounded-md bg-card"
                        >
                            <div>
                                <TypographyP className="text-base font-semibold">
                                    {supplier.supplier}
                                </TypographyP>
                            </div>
                            <SectionValue
                                label="Valor total inicial"
                                value={supplier.totalInitial}
                                formatter={currencyFormat}
                            />
                            <SectionValue
                                className="font-bold"
                                label="Valor total final"
                                value={supplier.totalFinal}
                                formatter={currencyFormat}
                            />
                            <SectionValue
                                label="Total inicial - total final"
                                value={supplier.totalDifference}
                                formatter={currencyFormat}
                            />
                            <SectionValue
                                label="Saving baseline"
                                value={supplier.baselineSaving}
                                formatter={currencyFormat}
                            />
                            <SectionValue
                                label="Saving negociação"
                                value={supplier.negotiationSaving}
                                formatter={currencyFormat}
                            />
                            <SectionValue
                                label="Saving financeiro"
                                value={supplier.financialSaving}
                                formatter={currencyFormat}
                            />
                        </div>
                    )
                })}
            </SectionContent>
        </Section>
    )
}

export default SupplierSection
