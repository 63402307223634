import { TypographyP } from '@/components/ui/typography'
import {
    Section,
    SectionContent,
    SectionHeader,
    SectionTitle,
} from '../Section'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { useParams } from 'react-router-dom'

const BuyerSection = () => {
    const { id } = useParams()
    const { data } = useQuotationByIdQuery({ id: Number(id) })

    if (!data) return null

    return (
        <Section>
            <SectionHeader>
                <SectionTitle>Comprador</SectionTitle>
            </SectionHeader>
            <SectionContent>
                <div>
                    <TypographyP className="text-muted-foreground">
                        Comprador
                    </TypographyP>
                    <TypographyP>{data.NOME_USUARIO}</TypographyP>
                </div>
                <div>
                    <TypographyP className="text-muted-foreground">
                        Observação
                    </TypographyP>
                    <TypographyP>
                        {data.APROVACAO?.COMPRADOR?.DS_OBSERVACAO || '-'}
                    </TypographyP>
                </div>
            </SectionContent>
        </Section>
    )
}

export default BuyerSection
