import { MenubarItem } from '@/components/ui/menubar'
import { cn } from '@/lib/utils'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { Trash, XCircle } from 'lucide-react'
import { useParams } from 'react-router-dom'

type DeleteItemProps = {
    onClick: () => void
}

const DeleteItem = ({ onClick }: DeleteItemProps) => {
    const { id } = useParams()

    const {
        data: quotation,
        isPending,
        isError,
    } = useQuotationByIdQuery({
        id: Number(id),
    })

    const shouldDelete = quotation?.DD_CANCELADA || !quotation?.DD_PUBLICADO

    const disabled =
        isPending ||
        isError ||
        quotation.APROVACAO?.DD_STATUS === 'APROVADO' ||
        quotation.APROVACAO?.DD_STATUS === 'EM_APROVACAO'

    return (
        <>
            <MenubarItem
                className={cn(
                    'gap-2',
                    shouldDelete && 'text-destructive focus:text-destructive'
                )}
                onClick={onClick}
                disabled={disabled}
            >
                {shouldDelete ? (
                    <>
                        <Trash size={14} className="text-red-500" />
                        Excluir cotação
                    </>
                ) : (
                    <>
                        <XCircle size={14} className="text-red-500" />
                        Cancelar cotação
                    </>
                )}
            </MenubarItem>
        </>
    )
}

export default DeleteItem
