import { AprovacaoHistorico } from '@/api/business/cotacaoAprovacao/type'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip'
import {
    TypographyLarge,
    TypographyMuted,
    TypographyP,
    TypographySmall,
} from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { useQuotationApprovalHistory } from '@/queries/useQuotationQuery'
import { ApprovalStatus } from '@/types/Approval'
import { format } from 'date-fns'
import {
    CheckCircle,
    CircleDotDashed,
    Clock,
    History,
    LocateFixed,
    XCircle,
} from 'lucide-react'
import { ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const ApprovalElement = {
    EM_APROVACAO: (
        <div className="flex items-center gap-2">
            <CircleDotDashed size={14} className="text-amber-500" />
            <TypographyP className="text-sm font-semibold text-amber-500">
                Em aprovação
            </TypographyP>
        </div>
    ),
    APROVADO: (
        <div className="flex items-center gap-2">
            <CheckCircle size={14} className="text-green-500" />
            <TypographyP className="text-sm font-semibold text-green-600">
                Aprovado
            </TypographyP>
        </div>
    ),
    RECUSADO: (
        <div className="flex items-center gap-2">
            <XCircle size={14} className="text-red-500" />
            <TypographyP className="text-sm font-semibold text-red-500">
                Recusado
            </TypographyP>
        </div>
    ),
    PENDENTE: (
        <div className="flex items-center gap-2">
            <Clock size={14} className="text-muted-foreground" />
            <TypographyP className="text-sm font-semibold text-muted-foreground">
                Pendente (Nova rodada)
            </TypographyP>
        </div>
    ),
} as Record<ApprovalStatus, ReactNode>

const ApprovalHistory = () => {
    const { id } = useParams()
    const { data, isPending, isError } = useQuotationApprovalHistory(Number(id))

    const [selected, setSelected] = useState<
        (AprovacaoHistorico & { id: string }) | null
    >(null)

    useEffect(() => {
        if (data) {
            setSelected(data.find((item) => item.DD_TIPO === 'CURRENT') || null)
        }
    }, [data])

    if (isPending || isError)
        return (
            <div>
                <Skeleton className="w-[300px] h-4" />
            </div>
        )

    return (
        <div className="flex h-full">
            <div className="flex-1 space-y-3">
                <TypographyLarge>Histórico de aprovação</TypographyLarge>
                <div className="space-y-4">
                    {data.map((history) => {
                        const isSelected = history.id === selected?.id

                        const date = format(
                            new Date(history.UPDATED_AT),
                            'PP HH:mm'
                        )

                        return (
                            <div
                                className={cn(
                                    'flex items-center gap-2',
                                    isSelected && 'border-primary'
                                )}
                                key={history.id}
                                onClick={() => setSelected(history)}
                            >
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                className="w-10 h-10 "
                                                variant="ghost"
                                            >
                                                {history.DD_TIPO === 'OLD' ? (
                                                    <History
                                                        size={20}
                                                        className="text-muted-foreground"
                                                    />
                                                ) : (
                                                    <LocateFixed
                                                        size={20}
                                                        className="text-secondary-500"
                                                    />
                                                )}
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            {history.DD_TIPO === 'OLD' ? (
                                                <TypographyP className="text-xs">
                                                    Aprovação antiga
                                                </TypographyP>
                                            ) : (
                                                <TypographyP className="text-xs">
                                                    Histórico
                                                </TypographyP>
                                            )}
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                                <Separator
                                    orientation="horizontal"
                                    className="w-6 h-[1px] bg-gray-400"
                                />
                                <div
                                    className={cn(
                                        'flex flex-col gap-1 w-full p-4 border rounded-lg bg-card hover:bg-accent cursor-pointer',
                                        isSelected &&
                                            'bg-accent border-neutral-300'
                                    )}
                                >
                                    <div className="flex items-center justify-between gap-2">
                                        <TypographyP className="text-sm">
                                            {ApprovalElement[history.DD_STATUS]}
                                        </TypographyP>
                                        <TypographySmall className="text-xs text-muted-foreground">
                                            {date}
                                        </TypographySmall>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="flex flex-1 p-4">
                <Separator orientation="vertical" />
                {selected && (
                    <div className="w-full p-4 space-y-4">
                        <div className="px-4">
                            {ApprovalElement[selected.DD_STATUS]}

                            <TypographyMuted>
                                {format(
                                    new Date(selected.UPDATED_AT),
                                    'PP HH:mm'
                                )}
                            </TypographyMuted>
                        </div>
                        {selected.COMPRADOR && (
                            <div className="px-4">
                                <div>
                                    <TypographyP className="text-base font-semibold text-accent-foreground">
                                        Comprador
                                    </TypographyP>
                                </div>
                                <div className="space-y-4">
                                    <div>
                                        <TypographyP>
                                            {selected.COMPRADOR.NOME_USUARIO}
                                        </TypographyP>
                                        <TypographyMuted>
                                            {selected.COMPRADOR.EMAIL_USUARIO}
                                        </TypographyMuted>
                                    </div>
                                    {selected.COMPRADOR.DS_OBSERVACAO && (
                                        <div>
                                            <TypographyP className="font-semibold">
                                                Observação
                                            </TypographyP>
                                            <TypographyP>
                                                {
                                                    selected.COMPRADOR
                                                        .DS_OBSERVACAO
                                                }
                                            </TypographyP>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {selected.APROVADOR && (
                            <>
                                <Separator />
                                <div className="px-4">
                                    <div>
                                        <TypographyP className="text-base font-semibold text-accent-foreground">
                                            Aprovador
                                        </TypographyP>
                                    </div>
                                    <div className="space-y-4">
                                        <div>
                                            <TypographyP>
                                                {
                                                    selected.APROVADOR
                                                        .NOME_USUARIO
                                                }
                                            </TypographyP>
                                            <TypographyMuted>
                                                {
                                                    selected.APROVADOR
                                                        .EMAIL_USUARIO
                                                }
                                            </TypographyMuted>
                                        </div>
                                        {selected.APROVADOR.DS_OBSERVACAO && (
                                            <div>
                                                <TypographyP className="font-semibold">
                                                    Observação
                                                </TypographyP>
                                                <TypographyP>
                                                    {
                                                        selected.APROVADOR
                                                            .DS_OBSERVACAO
                                                    }
                                                </TypographyP>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default ApprovalHistory
