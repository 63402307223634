import { cn } from '@/lib/utils'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'

import { Pencil } from 'lucide-react'
import { useParams } from 'react-router-dom'
import { MenubarItem } from '@/components/ui/menubar'

type EditItemProps = {
    onClick: () => void
}

const EditItem = ({ onClick }: EditItemProps) => {
    const { id } = useParams()

    const {
        data: quotation,
        isPending: isQuotationPending,
        isError: isQuotationError,
    } = useQuotationByIdQuery({
        id: Number(id),
    })

    const disabled =
        isQuotationPending || quotation?.DD_CANCELADA || isQuotationError

    return (
        <MenubarItem className="gap-2" disabled={disabled} onClick={onClick}>
            <Pencil
                size={14}
                className={cn(
                    disabled ? 'text-muted-foreground' : 'text-sky-500'
                )}
            />
            Editar cotação
        </MenubarItem>
    )
}

export default EditItem
