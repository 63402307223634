import { QuotationMapValuesState } from '@/store/quotationMapSlice/type'
import { Checkbox } from '@/components/ui/checkbox'
import { cn } from '@/lib/utils'
import { currencyFormat } from '@/utils/stringFormatter'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { useMemo } from 'react'
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@/components/ui/accordion'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import { format, isValid } from 'date-fns'
import { Separator } from '@/components/ui/separator'

type CellContentProps = {
    value: QuotationMapValuesState
    isGrouped: boolean
    disabled: boolean
    quotationProductId: number
    onCheckedChange: (checked: boolean) => void
}

const stateSelector = (state: Store) => ({
    selectedWinners: state.quotationMapSlice.state.selectedWinners,
})

const Content = ({
    value,
    disabled,
    quotationProductId,
    isGrouped,
    onCheckedChange,
}: CellContentProps) => {
    const { selectedWinners } = useBaseStore(stateSelector)

    const isSelected =
        selectedWinners[quotationProductId] &&
        selectedWinners[quotationProductId][value.SK_FORNECEDOR_X_RODADA]

    const hasGroup = value.GRUPO && value.GRUPO.length > 0

    const { quantity, price, tax, total, totalWithTax } = useMemo(() => {
        return {
            quantity: Number(value.QTD_DISPONIVEL) || 0,
            price: Number(value.PRECO) || 0,
            total: value.PRECO_TOTAL || 0,
            tax: value.PRECO_IMPOSTO || 0,
            totalWithTax: value.PRECO_TOTAL_IMPOSTO || 0,
        }
    }, [])

    const declined = value.DD_RECUSADO === 1

    const partiallyDeclined =
        hasGroup && value.GRUPO.some((group) => group.DD_RECUSADO === 1)

    const deliveryForecast =
        value.PREVISAO_DE_ENTREGA &&
        isValid(new Date(value.PREVISAO_DE_ENTREGA))
            ? format(new Date(value.PREVISAO_DE_ENTREGA), 'dd/MM/yyyy')
            : null

    return (
        <div
            className={cn(
                'flex items-center gap-4 p-2 flex-1 overflow-hidden',
                isSelected && !isGrouped
                    ? ' border-green-500 border bg-green-50'
                    : null,
                isGrouped && 'pr-16',
                declined && 'border-red-500 border bg-red-50'
            )}
        >
            {!isGrouped && (
                <Checkbox
                    disabled={disabled || declined}
                    checked={!!isSelected}
                    onCheckedChange={onCheckedChange}
                    onClick={(e) => e.stopPropagation()}
                />
            )}
            <div className="flex items-center gap-6">
                {hasGroup || partiallyDeclined || declined ? (
                    <div className="flex items-center gap-2">
                        {hasGroup && (
                            <div className="px-2 py-1 rounded-md bg-primary-100">
                                <TypographyP className="text-xs text-primary-600">
                                    SC fracionada
                                </TypographyP>
                            </div>
                        )}
                        {partiallyDeclined && (
                            <TypographyP className="text-xs text-red-500">
                                Parcialmente recusado
                            </TypographyP>
                        )}
                        {declined && (
                            <TypographyP className="text-xs text-red-500">
                                Declinado
                            </TypographyP>
                        )}
                    </div>
                ) : null}
                {isGrouped && (
                    <div className="flex-1">
                        <TypographyMuted className="text-right">
                            Qtde
                        </TypographyMuted>
                        <TypographyP
                            className={cn(
                                'text-right',
                                declined && 'text-neutral-400'
                            )}
                        >
                            {quantity}
                        </TypographyP>
                    </div>
                )}
                <div className="flex-1">
                    <TypographyMuted className="text-right">
                        Valor
                    </TypographyMuted>
                    <TypographyP
                        className={cn(
                            'text-right',
                            declined && 'text-neutral-400'
                        )}
                    >
                        {currencyFormat(price, 4, value.DS_MOEDA)}
                    </TypographyP>
                </div>
                <div className="flex-1">
                    <TypographyMuted className="text-right">
                        Valor c/ imposto
                    </TypographyMuted>
                    <TypographyP
                        className={cn(
                            'text-right',
                            declined && 'text-neutral-400'
                        )}
                    >
                        {currencyFormat(Number(tax) || 0, 4, value.DS_MOEDA)}
                    </TypographyP>
                </div>
                <div className="flex-1">
                    <TypographyMuted className="text-right">
                        Valor total
                    </TypographyMuted>
                    <TypographyP
                        className={cn(
                            'text-right',
                            declined && 'text-neutral-400'
                        )}
                    >
                        {currencyFormat(total, 4, value.DS_MOEDA)}
                    </TypographyP>
                </div>
                <div className="flex-1">
                    <TypographyMuted className="text-right whitespace-nowrap">
                        Valor total c/ imposto
                    </TypographyMuted>
                    <TypographyP
                        className={cn(
                            'text-right',
                            declined && 'text-neutral-400'
                        )}
                    >
                        {currencyFormat(totalWithTax, 4, value.DS_MOEDA)}
                    </TypographyP>
                </div>
                <div className="flex-1">
                    <TypographyMuted className="text-right whitespace-nowrap">
                        Prev. Entrega
                    </TypographyMuted>
                    <TypographyP
                        className={cn(
                            'text-right',
                            declined && 'text-neutral-400'
                        )}
                    >
                        {deliveryForecast ?? 'Não informado'}
                    </TypographyP>
                </div>
            </div>
        </div>
    )
}

const CellContent = ({
    value,
    disabled,
    quotationProductId,
    onCheckedChange,
}: CellContentProps) => {
    if (value.GRUPO && value.GRUPO.length > 0) {
        return (
            <Accordion type="single" collapsible className="flex-1">
                <AccordionItem value="item-1">
                    <AccordionTrigger className="p-0">
                        <Content
                            disabled={disabled}
                            onCheckedChange={onCheckedChange}
                            value={value}
                            isGrouped={false}
                            quotationProductId={quotationProductId}
                        />
                    </AccordionTrigger>
                    <Separator />
                    <AccordionContent className="flex flex-col items-end w-full">
                        {value.GRUPO.map((group, idx) => {
                            return (
                                <>
                                    {idx !== 0 && <Separator />}
                                    <Content
                                        key={group.SK_RESPOSTA_FORNECEDOR}
                                        disabled={disabled}
                                        onCheckedChange={onCheckedChange}
                                        value={group as QuotationMapValuesState}
                                        isGrouped
                                        quotationProductId={quotationProductId}
                                    />
                                </>
                            )
                        })}
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
        )
    }

    return (
        <Content
            disabled={disabled}
            onCheckedChange={onCheckedChange}
            value={value}
            isGrouped={false}
            quotationProductId={quotationProductId}
        />
    )
}

export default CellContent
