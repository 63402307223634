import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useBaseStore } from '@/store'
import {
    supplierByIdSelector,
    supplierValuesByIdSelector,
} from '@/store/quotationMapSlice'
import { QuotationMapFlatState } from '@/store/quotationMapSlice/type'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import { createColumnHelper } from '@tanstack/react-table'
import { format, isValid } from 'date-fns'
import { useEffect, useMemo } from 'react'
import AttachmentsPopover from '../../components/AttachmentsPopover'
import { useParams } from 'react-router-dom'
import { AlertCircleIcon } from 'lucide-react'
import { TypographyH2, TypographyMuted } from '@/components/ui/typography'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import FreightSection from './components/FreightSection'
import PaymentConditionSection from './components/PaymentConditionSection'
import { Store } from '@/store/type'
import ShippingCompanySection from './components/ShippingCompanySection'
import { SavingTypeMapper } from '@/api/business/cotacoes/type'

const columnHelper = createColumnHelper<QuotationMapFlatState>()
type SupplierTable = TableData<QuotationMapFlatState>

interface ViewModeProps {
    supplierId: string
    companyId: string
}

const stateSelector = (state: Store) => ({
    selectedCompanyId:
        state.quotationMapSlice.state.supplierDialog.selectedCompanyId,
    onSelectSupplierToUpdate:
        state.quotationMapSlice.actions.onSelectSupplierToUpdate,
})

const ViewMode = ({ supplierId, companyId }: ViewModeProps) => {
    const { id: quotationId } = useParams()
    const supplier = useBaseStore(supplierByIdSelector(supplierId))
    const values = useBaseStore(supplierValuesByIdSelector(supplierId))
    const { selectedCompanyId, onSelectSupplierToUpdate } =
        useBaseStore(stateSelector)

    // const freight = values[0]?.FRETE
    const paymentCondition = values.some((value) =>
        Boolean(value.DS_CONDICAO_DE_PAGAMENTO)
    )
    const manualPaymentCondition = values.some((value) =>
        Boolean(value.DS_CONDICAO_DE_PAGAMENTO_MANUAL)
    )

    const data = useMemo(
        () =>
            values
                .filter((value) => value.SK_EMPRESA === companyId)
                .map((value) => ({
                    ...value,
                    subRows: value.GRUPO as QuotationMapFlatState[],
                })),
        [values, selectedCompanyId]
    )

    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'STATUS',
                header: 'Status',
                cell: ({ row }) => {
                    const { DD_RECUSADO } = row.original

                    return DD_RECUSADO === 1 ? (
                        <span className="text-destructive">Declinado</span>
                    ) : (
                        <span className="text-green-600">Aceito</span>
                    )
                },
                meta: {
                    row: {
                        isGrouped: true,
                    },
                },
            }),
            columnHelper.accessor('NUMERO_RODADA', {
                id: 'NUMERO_RODADA',
                header: 'Rodada',
            }),
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Cod empresa',
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'ABREVIATURA_EMPRESA',
                header: 'Empresa',
            }),
            columnHelper.accessor('NK_PRODUTO', {
                id: 'NK_PRODUTO',
                header: 'Cod produto',
            }),
            columnHelper.accessor('DD_NCM', {
                id: 'DD_NCM',
                header: 'NCM',
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 300,
            }),
            columnHelper.accessor('QTD_SOLICITADA', {
                id: 'QTD_SOLICITADA',
                header: 'Qtd solicitada',
            }),
            columnHelper.accessor('QTD_DISPONIVEL', {
                id: 'QTD_DISPONIVEL',
                header: 'Qtd disponível',
                aggregatedCell: ({ row }) => {
                    const { subRows } = row

                    const total = subRows.reduce((acc, row) => {
                        const { QTD_DISPONIVEL } = row.original
                        return acc + Number(QTD_DISPONIVEL)
                    }, 0)

                    return total
                },
            }),
            columnHelper.accessor(
                ({ PRECO }) => currencyFormat(Number(PRECO) || 0),
                {
                    id: 'PRECO',
                    header: 'Preço',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    aggregatedCell: ({ row }) => {
                        const { subRows } = row
                        const total = subRows.reduce((acc, row) => {
                            const { PRECO } = row.original
                            return acc + Number(PRECO)
                        }, 0)

                        return currencyFormat(total)
                    },
                }
            ),
            columnHelper.accessor(
                ({ PRECO_TOTAL }) => currencyFormat(Number(PRECO_TOTAL) || 0),
                {
                    id: 'PRECO_TOTAL',
                    header: 'Preço total',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    aggregatedCell: ({ row }) => {
                        const { subRows } = row
                        const total = subRows.reduce((acc, row) => {
                            const { PRECO_TOTAL } = row.original
                            return acc + Number(PRECO_TOTAL)
                        }, 0)

                        return currencyFormat(total)
                    },
                }
            ),
            columnHelper.accessor(
                ({ PRECO_IMPOSTO }) =>
                    currencyFormat(Number(PRECO_IMPOSTO) || 0),
                {
                    id: 'PRECO_IMPOSTO',
                    header: 'Preço com imposto',
                    cell: ({ getValue }) => {
                        return currencyFormat(Number(getValue()) || 0)
                    },
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    aggregatedCell: ({ row }) => {
                        const { subRows } = row
                        const total = subRows.reduce((acc, row) => {
                            const { PRECO_IMPOSTO } = row.original
                            return acc + Number(PRECO_IMPOSTO)
                        }, 0)

                        return currencyFormat(total)
                    },
                }
            ),
            columnHelper.accessor(
                ({ PRECO_TOTAL_IMPOSTO }) =>
                    currencyFormat(Number(PRECO_TOTAL_IMPOSTO) || 0),
                {
                    id: 'PRECO_TOTAL_IMPOSTO',
                    header: 'Preço total com imposto',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    aggregatedCell: ({ row }) => {
                        const { subRows } = row
                        const total = subRows.reduce((acc, row) => {
                            const { PRECO_TOTAL_IMPOSTO } = row.original
                            return acc + Number(PRECO_TOTAL_IMPOSTO)
                        }, 0)

                        return currencyFormat(total)
                    },
                }
            ),
            columnHelper.accessor('VL_SPENDING', {
                id: 'VL_SPENDING',
                header: 'Valor spending',
                size: 150,
            }),
            columnHelper.accessor(
                ({ VL_SAVING }) => currencyFormat(VL_SAVING),
                {
                    id: 'VL_SAVING',
                    header: 'Valor saving',
                    size: 150,
                }
            ),
            columnHelper.accessor(
                ({ PERCENTUAL_SAVING }) =>
                    percentageFormat(Number(PERCENTUAL_SAVING) || 0),
                {
                    id: 'PERCENTUAL_SAVING',
                    header: 'Saving (%)',
                    size: 130,
                }
            ),
            columnHelper.accessor(
                ({ DD_SAVING }) => SavingTypeMapper[DD_SAVING],
                {
                    id: 'DD_SAVING',
                    header: 'Tipo Saving',
                    size: 120,
                    aggregatedCell: () => null,
                }
            ),
            columnHelper.accessor(
                ({
                    SK_CONDICAO_DE_PAGAMENTO,
                    DS_CONDICAO_DE_PAGAMENTO,
                    DS_CONDICAO_DE_PAGAMENTO_MANUAL,
                }) => {
                    return SK_CONDICAO_DE_PAGAMENTO
                        ? DS_CONDICAO_DE_PAGAMENTO
                        : DS_CONDICAO_DE_PAGAMENTO_MANUAL
                },
                {
                    id: 'DS_CONDICAO_DE_PAGAMENTO',
                    header: 'Condição de pagamento',
                    aggregatedCell: () => null,
                }
            ),
            columnHelper.accessor('FRETE', {
                id: 'FRETE',
                header: 'Frete',
                aggregatedCell: () => null,
            }),
            columnHelper.accessor(
                ({ VL_FRETE }) => currencyFormat(Number(VL_FRETE)),
                {
                    id: 'VL_FRETE',
                    header: 'Valor frete',
                    aggregatedCell: () => null,
                }
            ),
            columnHelper.accessor(
                ({ ICMS }) => percentageFormat(Number(ICMS) || 0),
                {
                    id: 'ICMS',
                    header: 'ICMS',
                    aggregatedCell: () => null,
                }
            ),
            columnHelper.accessor(
                ({ PIS }) => percentageFormat(Number(PIS) || 0),
                {
                    id: 'PIS',
                    header: 'PIS',
                    aggregatedCell: () => null,
                }
            ),
            columnHelper.accessor(
                ({ COFINS }) => percentageFormat(Number(COFINS) || 0),
                {
                    id: 'COFINS',
                    header: 'COFINS',
                    aggregatedCell: () => null,
                }
            ),
            columnHelper.accessor(
                ({ ST }) => percentageFormat(Number(ST) || 0),
                {
                    id: 'ST',
                    header: 'ST',
                    aggregatedCell: () => null,
                }
            ),
            columnHelper.accessor(
                ({ FCP }) => percentageFormat(Number(FCP) || 0),
                {
                    id: 'FCP',
                    header: 'FCP',
                    aggregatedCell: () => null,
                }
            ),
            columnHelper.accessor(
                ({ PREVISAO_DE_ENTREGA }) =>
                    PREVISAO_DE_ENTREGA &&
                    isValid(new Date(PREVISAO_DE_ENTREGA))
                        ? format(new Date(PREVISAO_DE_ENTREGA), 'dd/MM/yyyy')
                        : '-',
                {
                    id: 'PREVISAO_DE_ENTREGA',
                    header: 'Previsão de entrega',
                    aggregatedCell: () => null,
                }
            ),
            columnHelper.accessor('DD_OBSERVACAO', {
                id: 'DD_OBSERVACAO',
                header: 'Observação',
                aggregatedCell: () => null,
            }),
        ],
        []
    )

    useEffect(() => {
        onSelectSupplierToUpdate(supplierId)
    }, [supplierId])

    return (
        <div className="flex flex-col flex-1 overflow-hidden">
            <div>
                <TypographyH2 className="font-semibold ">
                    Resposta do fornecedor
                </TypographyH2>
            </div>
            {!paymentCondition && manualPaymentCondition ? (
                <div className="flex items-center gap-2 mb-2">
                    <AlertCircleIcon size={14} className="text-amber-600" />
                    <TypographyMuted>
                        O fornecedor escolheu uma condição de pagamento não
                        cadastrada. Informe a condição de pagamento antes de
                        finalizar a cotação.
                    </TypographyMuted>
                </div>
            ) : null}
            <div className="flex items-center gap-4 mb-4">
                {/* <div className="w-full max-w-[150px]">
                    <Label>Frete</Label>
                    <Input readOnly value={freight || '-'} />
                </div>
                <div className="w-full max-w-[200px]">
                    <Label className="flex items-center gap-2 mb-2">
                        {!paymentCondition && manualPaymentCondition ? (
                            <AlertCircleIcon
                                className="text-amber-500"
                                size={14}
                            />
                        ) : null}
                        Condição de pagamento
                    </Label>
                    <Input
                        readOnly
                        value={
                            paymentCondition || manualPaymentCondition || '-'
                        }
                    />
                </div> */}
                <div className="py-2">
                    <TypographyMuted>Anexos</TypographyMuted>
                    {supplier?.SK_FORNECEDOR && quotationId ? (
                        <AttachmentsPopover
                            enabled={true}
                            quotationId={Number(quotationId)}
                            supplierId={supplier?.SK_FORNECEDOR}
                        />
                    ) : null}
                </div>
                <PaymentConditionSection />
                <ShippingCompanySection />
                <FreightSection />
            </div>
            <div className="flex flex-col flex-1 overflow-hidden">
                <Table<SupplierTable>
                    data={data}
                    columns={columns}
                    defaultColumn={{
                        cell: ({ getValue }) => (
                            <DefaultColumn>
                                {getValue() as string}
                            </DefaultColumn>
                        ),
                    }}
                />
            </div>
        </div>
    )
}

export default ViewMode
