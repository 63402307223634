import { useBaseStore } from '@/store'
import { quotationPurchaseRequestActionsSelector } from '@/store/quotationPurchaseRequestSlice'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const QuotationCreatePurchaseRequestLayout = () => {
    const { onReset } = useBaseStore(quotationPurchaseRequestActionsSelector)

    useEffect(() => {
        return () => {
            onReset()
        }
    }, [])

    return <Outlet />
}

export default QuotationCreatePurchaseRequestLayout
