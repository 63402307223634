import { DropdownMenuItem } from '@/components/ui/dropdown-menu'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { CircleDollarSign } from 'lucide-react'
import { useParams } from 'react-router-dom'

type AddPaymentConditionButtonProps = {
    onClick: () => void
}

const AddPaymentConditionButton = ({
    onClick,
}: AddPaymentConditionButtonProps) => {
    const { id } = useParams()
    const { data, isPending, isError } = useQuotationByIdQuery({
        id: Number(id),
    })

    const disabled =
        isPending || isError || data.DD_CANCELADA || !!data.APROVACAO

    return (
        <>
            <DropdownMenuItem
                disabled={disabled}
                onClick={onClick}
                className="gap-2"
            >
                <CircleDollarSign size={16} className="text-yellow-600" />
                Adicionar condição de pagamento
            </DropdownMenuItem>
        </>
    )
}

export default AddPaymentConditionButton
