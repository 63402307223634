import { isAxiosError } from '@/api/business'
import { ReactNode } from 'react'
import Forbidden from '@/pages/ErrorPages/Forbidden'
import Unauthorized from '@/pages/ErrorPages/Unauthorized'
import Error from '@/pages/ErrorPages/Error'
import NotFound from '@/pages/ErrorPages/NotFound'
import BadRequest from '../ErrorPages/BadRequest'
import InternalServerError from '../ErrorPages/InternalServerError'

type PageLoaderProps = {
    children: ReactNode
    fallback: ReactNode
    isLoading: boolean
    defaultMessage?: string
    error?: Error | null
}

const errorFallback = (errMessage?: string) => ({
    400: <BadRequest message={errMessage} />,
    401: <Unauthorized />,
    403: <Forbidden />,
    404: <NotFound />,
    500: <InternalServerError message={errMessage} />,
})

const PageLoader = ({
    children,
    fallback,
    error,
    isLoading,
    defaultMessage,
}: PageLoaderProps) => {
    if (isLoading) return fallback

    if (error && isAxiosError(error)) {
        const errorCode = error.response?.status

        if (!errorCode || !error.response)
            return <Error code={512} message={defaultMessage} />

        const errorMessage = error.response.data?.message

        const ErrorPage = errorFallback(errorMessage)[error.response.status]

        return (
            ErrorPage ?? (
                <Error
                    code={errorCode}
                    message={errorMessage ?? defaultMessage}
                />
            )
        )
    }

    return <>{children}</>
}

export default PageLoader
