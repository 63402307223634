import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { QuotationMapSupplierValuesState } from '@/store/quotationMapSlice/type'
import { formatCpfOrCnpj } from '@/utils/stringFormatter'

type HeaderContentProps = {
    supplier: QuotationMapSupplierValuesState
}

const HeaderContent = ({ supplier }: HeaderContentProps) => {
    return (
        <div className="flex gap-2 overflow-hidden">
            <div className="flex-1 overflow-hidden">
                <TypographyP className="text-xs truncate">
                    {supplier.DD_NOME_FANTASIA}
                </TypographyP>
                <TypographyMuted className="text-xs truncate">
                    {supplier.DD_RAZAO_SOCIAL} -{' '}
                    {supplier.DD_CNPJ && formatCpfOrCnpj(supplier.DD_CNPJ)}
                </TypographyMuted>
            </div>
        </div>
    )
}

export default HeaderContent
