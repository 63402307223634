import SuppliersDialog from '@/components/SuppliersDialog'

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'

import useDisclosure from '@/hooks/useDisclosure'
import { useTable } from '@/hooks/useTable'
import { useBaseStore } from '@/store'
import { lastRoundSuppliersSelector } from '@/store/quotationMapSlice'
import { Supplier } from '@/types/Suppliers'
import { RowSelectionState } from '@tanstack/react-table'
import { AlertTriangle, Store } from 'lucide-react'
import { useState } from 'react'
import NewSuppliersSection from './components/NewSuppliersSection'
import RoundSuppliersSection from './components/RoundSuppliersSection'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { DatePicker } from '@/components/DatePicker'
import { format, isBefore } from 'date-fns'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createNewRound } from '@/api/business/cotacoes'
import { useToast } from '@/components/ui/use-toast'
import { useParams } from 'react-router-dom'
import { quotationKeys } from '@/queries/useQuotationQuery'
import { quotationMapKeys } from '@/queries/useQuotatioMapQuery'
import { isAxiosError } from '@/api/business'

interface NewRoundDialogProps {
    isOpen: boolean
    onClose: () => void
}

const NewRoundDialog = ({ isOpen, onClose }: NewRoundDialogProps) => {
    const { id } = useParams()
    const { toast } = useToast()
    const queryClient = useQueryClient()
    const [newSuppliers, setNewSuppliers] = useState<Supplier[]>([])

    const { mutate, isPending } = useMutation({
        mutationFn: createNewRound,
        onSuccess: () => {
            const numberId = Number(id)

            if (numberId) {
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(numberId),
                })
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detailProducts(numberId),
                })
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detailSuppliers(numberId),
                })
                queryClient.invalidateQueries({
                    queryKey: quotationMapKeys.detail(numberId),
                })
            }

            setNewSuppliers([])
            setNewSuppliersRowSelection({})

            toast({
                title: 'Rodada criada com sucesso',
                description: 'Nova rodada criada com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível criar nova rodada. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao criar rodada',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const {
        isOpen: isAddSuppliersDialogOpen,
        onClose: onAddSuppliersDialogClose,
        onOpen: onAddSuppliersDialogOpen,
    } = useDisclosure()

    const [date, setDate] = useState<Date>()

    const [newSuppliersRowSelection, setNewSuppliersRowSelection] =
        useState<RowSelectionState>({})

    const { selectedRows, setSelectedRows } = useTable()

    const suppliers = useBaseStore(lastRoundSuppliersSelector)

    const onRemoveNewSupplier = (id: string) => {
        setNewSuppliers((prev) =>
            prev.filter((supplier) => supplier.SK_FORNECEDOR !== id)
        )
        setNewSuppliersRowSelection((prev) => ({ ...prev, [id]: false }))
    }

    const warningSuppliers = suppliers.filter(
        (supplier) =>
            Object.keys(selectedRows).includes(supplier.SK_FORNECEDOR) &&
            (supplier.FORNECEDOR_PUBLICADO === 0 ||
                supplier.FORNECEDOR_DECLINADO === 1)
    )

    const onSubmit = () => {
        const selectedSuppliers = Object.entries(selectedRows).filter(
            ([, value]) => Boolean(value)
        )

        if (selectedSuppliers.length === 0 && newSuppliers.length === 0) {
            return toast({
                title: 'Erro',
                description: 'Selecione pelo menos um fornecedor',
                variant: 'destructive',
            })
        }

        if (!date) {
            return toast({
                title: 'Erro',
                description: 'Selecione a data de expiração',
                variant: 'destructive',
            })
        }

        if (id)
            mutate({
                quotationId: Number(id),
                suppliers: [
                    ...selectedSuppliers.map(([key]) => ({
                        SK_FORNECEDOR: key,
                    })),
                    ...newSuppliers.map((supplier) => ({
                        SK_FORNECEDOR: supplier.SK_FORNECEDOR,
                    })),
                ],
                expirationDate: format(date, 'yyyy-MM-dd'),
            })
    }

    return (
        <>
            <Dialog open={isOpen} onOpenChange={onClose}>
                <DialogContent className="max-w-[1100px] max-h-[90%] flex flex-col">
                    <DialogHeader className="flex-row items-center justify-between">
                        <div>
                            <DialogTitle>Nova rodada</DialogTitle>
                            <DialogDescription>
                                Selecione os fornecedores que irão fazer parte
                                da nova rodada
                            </DialogDescription>
                        </div>
                        <div>
                            <button
                                className="flex items-center gap-2 px-2 py-1 text-sm border rounded-lg hover:bg-slate-100 border-slate-200 hover:text-slate-600"
                                onClick={onAddSuppliersDialogOpen}
                            >
                                <Store size={14} className="text-amber-700" />
                                Novos fornecedores
                            </button>
                        </div>
                    </DialogHeader>
                    <div className="flex-1 space-y-4 overflow-y-auto">
                        <div className="space-y-2">
                            <Label className="block">Data de expiração:</Label>
                            <DatePicker
                                date={date}
                                setDate={setDate}
                                disabled={(date) => isBefore(date, new Date())}
                            />
                        </div>
                        <RoundSuppliersSection
                            suppliers={suppliers}
                            selectedSuppliers={selectedRows}
                            onSelectSupplier={setSelectedRows}
                        />
                        {newSuppliers.length > 0 && (
                            <NewSuppliersSection
                                newSuppliers={newSuppliers}
                                onRemove={onRemoveNewSupplier}
                            />
                        )}
                    </div>
                    <DialogFooter>
                        {warningSuppliers.length > 0 && (
                            <p className="flex items-center gap-2 text-sm text-amber-600">
                                <AlertTriangle size={14} /> Há fornecedores que
                                declinaram ou que não finalizaram selecionados
                            </p>
                        )}
                        <Button variant="ghost" onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button
                            className="w-auto"
                            disabled={isPending}
                            onClick={onSubmit}
                        >
                            Criar nova rodada
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <SuppliersDialog
                isOpen={isAddSuppliersDialogOpen}
                onClose={onAddSuppliersDialogClose}
                rowSelection={{
                    onSelectSuppliers: (rowSelection, suppliers) => {
                        setNewSuppliersRowSelection(rowSelection)
                        setNewSuppliers(suppliers)
                    },
                    selectedRows: newSuppliersRowSelection,
                }}
            />
        </>
    )
}

export default NewRoundDialog
