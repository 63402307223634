import { X } from 'lucide-react'
import { ChangeEvent, useEffect } from 'react'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Form,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { TypographyP } from '@/components/ui/typography'
import { DatePicker } from '@/components/DatePicker'
import { isValid } from 'date-fns'
import { z } from 'zod'
import { useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { isAxiosError } from '@/api/business'
import { QuotationMapFlatState } from '@/store/quotationMapSlice/type'
import { addProductRow } from '@/api/business/cotacoes'
import { quotationMapKeys } from '@/queries/useQuotatioMapQuery'

const addLineSchema = z.object({
    LINHAS: z.array(
        z.object({
            PRECO: z.string(),
            QTD_DISPONIVEL: z.coerce
                .number()
                .refine((value) => Number(value) > 0, 'Valor inválido'),
            PREVISAO_DE_ENTREGA: z.coerce.date().or(z.string()).nullable(),
            OBSERVACAO: z.string().nullable(),
        })
    ),
})

type AddLineSchema = z.infer<typeof addLineSchema>

type AddProductGroupDialogProps = {
    product: Pick<
        QuotationMapFlatState,
        | 'SK_RESPOSTA_FORNECEDOR'
        | 'DS_PRODUTO'
        | 'DD_RECUSADO'
        | 'PRECO'
        | 'QTD_SOLICITADA'
        | 'PREVISAO_DE_ENTREGA'
        | 'OBSERVACAO'
        | 'SK_RESPOSTA_SUPERIOR'
        | 'GRUPO'
    >
    isOpen: boolean
    onOpenChange: (isOpen: boolean) => void
}

const AddProductGroupDialog = ({
    isOpen,
    product: {
        DS_PRODUTO,
        GRUPO,
        OBSERVACAO,
        PRECO,
        PREVISAO_DE_ENTREGA,
        QTD_SOLICITADA,
        SK_RESPOSTA_FORNECEDOR,
    },
    onOpenChange,
}: AddProductGroupDialogProps) => {
    const isGrouped = GRUPO && GRUPO.length > 0

    const queryClient = useQueryClient()

    const onSubmit = (data: AddLineSchema) => {
        mutate({
            SK_RESPOSTA_FORNECEDOR,
            PRODUTOS: data.LINHAS.map((line) => ({
                ...line,
                PREVISAO_DE_ENTREGA:
                    line.PREVISAO_DE_ENTREGA?.toString() || null,
            })),
        })
    }

    const initial = {
        PRECO: PRECO || '0',
        QTD_DISPONIVEL: QTD_SOLICITADA / 2,
        PREVISAO_DE_ENTREGA,
        OBSERVACAO,
    }

    const form = useForm<AddLineSchema>({
        resolver: zodResolver(addLineSchema),
        defaultValues: {
            LINHAS: isGrouped
                ? GRUPO.map((group) => ({
                      PRECO: group.PRECO || '0',
                      QTD_DISPONIVEL: Number(group.QTD_DISPONIVEL) || 0,
                      PREVISAO_DE_ENTREGA: group.PREVISAO_DE_ENTREGA || '',
                      OBSERVACAO: group.OBSERVACAO || '',
                  }))
                : [initial, initial],
        },
    })

    const { fields, append, remove } = useFieldArray({
        name: 'LINHAS',
        control: form.control,
    })

    const selectedQuantity = form
        .watch('LINHAS')
        .reduce((total, curr) => (total += Number(curr.QTD_DISPONIVEL) || 0), 0)

    const remaining = Math.max(0, QTD_SOLICITADA - selectedQuantity)

    useEffect(() => {
        form.reset({
            LINHAS: isGrouped
                ? GRUPO.map((group) => ({
                      PRECO: group.PRECO || '0',
                      QTD_DISPONIVEL: Number(group.QTD_DISPONIVEL) || 0,
                      PREVISAO_DE_ENTREGA: group.PREVISAO_DE_ENTREGA || '',
                      OBSERVACAO: group.OBSERVACAO || '',
                  }))
                : [initial, initial],
        })
    }, [])

    const { mutate, isPending } = useMutation({
        mutationFn: addProductRow,
        onSuccess: () => {
            onOpenChange(false)
            queryClient.invalidateQueries({ queryKey: quotationMapKeys.all })
            toast.success('Linhas adicionadas com sucesso')
        },
        onError: (err) => {
            let message = 'Erro ao informar novos valores'

            if (isAxiosError(err)) {
                message = err.response?.data?.message || message
            }

            toast.error(message)
        },
    })

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent className="max-w-[1200px]">
                <DialogHeader>
                    <DialogTitle>Duplicar linha</DialogTitle>
                    <DialogDescription>
                        Duplicar linha para informar prazos e quantidades
                        diferentes de entrega
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-4">
                    <div>
                        <TypographyP>{DS_PRODUTO}</TypographyP>
                        <TypographyP>
                            Quantidade solicitada: {QTD_SOLICITADA}
                        </TypographyP>
                    </div>
                    <Form {...form}>
                        <form
                            className="space-y-4"
                            onSubmit={form.handleSubmit(onSubmit)}
                        >
                            {fields.map((field, index) => {
                                return (
                                    <section
                                        key={field.id}
                                        className="flex items-center gap-4"
                                    >
                                        <FormField
                                            control={form.control}
                                            name={`LINHAS.${index}.PRECO`}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel>
                                                            Preço
                                                        </FormLabel>
                                                        <Input
                                                            type="number"
                                                            {...field}
                                                            value={
                                                                field.value ??
                                                                ''
                                                            }
                                                        />
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <FormField
                                            control={form.control}
                                            name={`LINHAS.${index}.QTD_DISPONIVEL`}
                                            render={({ field }) => {
                                                const handleQuantityChange = (
                                                    e: ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    let inputValue =
                                                        Number(
                                                            e.target.value
                                                        ) || 0

                                                    if (
                                                        inputValue > remaining
                                                    ) {
                                                        inputValue = remaining
                                                    } else if (inputValue < 0) {
                                                        inputValue = 0
                                                    }

                                                    field.onChange(
                                                        parseFloat(
                                                            inputValue.toFixed(
                                                                2
                                                            )
                                                        )
                                                    )
                                                }

                                                return (
                                                    <FormItem>
                                                        <FormLabel>
                                                            Qtd disponível
                                                        </FormLabel>
                                                        <Input
                                                            type="number"
                                                            {...field}
                                                            onChange={
                                                                handleQuantityChange
                                                            }
                                                            value={
                                                                field.value ??
                                                                ''
                                                            }
                                                        />
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <FormField
                                            control={form.control}
                                            name={`LINHAS.${index}.OBSERVACAO`}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel>
                                                            Observação
                                                        </FormLabel>
                                                        <Input
                                                            {...field}
                                                            value={
                                                                field.value ??
                                                                ''
                                                            }
                                                        />
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <FormField
                                            control={form.control}
                                            name={`LINHAS.${index}.PREVISAO_DE_ENTREGA`}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel className="block">
                                                            Previsão de entrega
                                                        </FormLabel>
                                                        <DatePicker
                                                            setDate={(date) =>
                                                                field.onChange(
                                                                    date
                                                                )
                                                            }
                                                            date={
                                                                field.value &&
                                                                isValid(
                                                                    new Date(
                                                                        field.value
                                                                    )
                                                                )
                                                                    ? new Date(
                                                                          field.value
                                                                      )
                                                                    : undefined
                                                            }
                                                        />
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        {index !== 0 && (
                                            <Button
                                                className="self-end"
                                                variant="ghost"
                                                size="icon"
                                                type="button"
                                                onClick={() => remove(index)}
                                            >
                                                <X
                                                    className="text-red-500"
                                                    size={14}
                                                />
                                            </Button>
                                        )}
                                    </section>
                                )
                            })}
                            <DialogFooter className="md:justify-between">
                                <Button
                                    type="button"
                                    disabled={remaining === 0}
                                    onClick={() =>
                                        append({
                                            PRECO: '0',
                                            QTD_DISPONIVEL: 0,
                                            OBSERVACAO: '',
                                            PREVISAO_DE_ENTREGA: '',
                                        })
                                    }
                                >
                                    Adicionar
                                </Button>
                                <div className="space-x-4">
                                    <Button
                                        variant="ghost"
                                        type="button"
                                        onClick={() => onOpenChange(false)}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button type="submit" disabled={isPending}>
                                        Confirmar
                                    </Button>
                                </div>
                            </DialogFooter>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default AddProductGroupDialog
