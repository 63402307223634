import {
    QuotationMapProduct,
    QuotationMapValues,
} from '@/api/business/cotacoes/type'
import {
    createColumnHelper,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { useMemo } from 'react'
import { useBaseStore } from '@/store'
import TableContainer from '@/components/Table/components/TableContainer'
import TableLayout from '@/components/Table/components/TableLayout'
import { TableContextProvider } from '@/components/Table/contexts/TableContext'
import { getLastRoundSelector } from '@/store/quotationDetailSlice'
import { Store } from '@/store/type'
import TableColumnHeader from '@/components/Table/components/TableColumnHeader'
import TableCell from '@/components/Table/components/TableCell'
import TableBody from '@/components/Table/components/TableBody'
import TableRow from '@/components/Table/components/TableRow'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { useParams } from 'react-router-dom'
import CellContent from './components/CellContent'
import HeaderContent from './components/HeaderContent'

type QuotationMapTable = QuotationMapValues & QuotationMapProduct

const columnHelper = createColumnHelper<QuotationMapTable>()

const stateSelector = (state: Store) => ({
    quotationMap: state.quotationMapSlice.state.quotationMap,
    onSelectFinalSuppliers:
        state.quotationMapSlice.actions.onSelectFinalSuppliers,
    onSelectAllFinalSupplier:
        state.quotationMapSlice.actions.onSelectAllFinalSupplier,
})

const QuotationEndTable = () => {
    const { id } = useParams()
    const { data: quotation } = useQuotationByIdQuery({ id: Number(id) })

    const { quotationMap, onSelectFinalSuppliers, onSelectAllFinalSupplier } =
        useBaseStore(stateSelector)

    const lastRound = useBaseStore(getLastRoundSelector)

    const suppliers = useMemo(
        () =>
            Object.values(quotationMap?.FORNECEDORES.entities || []).filter(
                (supplier) =>
                    lastRound &&
                    supplier.FORNECEDOR_COTACAO_RODADAS.includes(
                        lastRound.roundId
                    )
            ),
        [quotationMap.FORNECEDORES, lastRound]
    )

    const products = useMemo(
        () => Object.values(quotationMap?.PRODUTOS.entities || []),
        [quotationMap.PRODUTOS]
    )

    const values = useMemo(() => {
        return (
            quotationMap?.VALORES.filter(
                (value) => value.SK_COTACAO_X_RODADA === lastRound?.roundId
            ) || []
        )
    }, [quotationMap, lastRound])

    const columnsMemo = useMemo(
        () => [
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Cod Empresa',
                size: 80,
                enableHiding: false,
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original

                    return (
                        <span
                            style={{ color: COR_EMPRESA }}
                            className="truncate"
                        >
                            {getValue()}
                        </span>
                    )
                },
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'ABREVIATURA_EMPRESA',
                header: 'Empresa',
                size: 150,
                enableHiding: false,
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original

                    return (
                        <span
                            style={{ color: COR_EMPRESA }}
                            className="truncate"
                        >
                            {getValue()}
                        </span>
                    )
                },
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_ITEM_SOLICITACAO_DE_COMPRAS',
                header: 'Item',
                size: 80,
                enableHiding: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_SOLICITACAO_DE_COMPRAS',
                header: 'SC',
                size: 100,
                enableHiding: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 400,
                enableHiding: false,
                meta: {
                    enableColumnOrdering: false,
                    cell: {
                        className:
                            'whitespace-nowrap overflow-hidden text-ellipsis ',
                    },
                },
            }),
            columnHelper.accessor('DD_UM', {
                id: 'DD_UM',
                header: 'UM',
                size: 80,
                enableHiding: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('QTD_SOLICITADA', {
                id: 'QTD_SOLICITADA',
                header: 'Qtde solicitada',
                size: 100,
                enableHiding: false,
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor(
                ({ QTD_DISPONIVEL, GRUPO }) =>
                    GRUPO && GRUPO.length > 0
                        ? GRUPO.reduce(
                              (acc, curr) =>
                                  (acc += Number(curr.QTD_DISPONIVEL) || 0),
                              0
                          )
                        : QTD_DISPONIVEL,
                {
                    id: 'QTD_DISPONIVEL',
                    header: 'Qtde disponível',
                    size: 100,
                    enableHiding: false,
                    meta: {
                        enableColumnOrdering: false,
                    },
                }
            ),
            columnHelper.group({
                id: 'FORNECEDORES',
                header: 'Fornecedores',
                enableHiding: false,
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    header: {
                        className: 'p-4 text-sm font-bold',
                    },
                },
                columns: [
                    ...suppliers.map((supplier) => {
                        const disabled =
                            quotation?.DD_COTACAO_FINALIZADA ||
                            supplier.FORNECEDOR_DECLINADO === 1 ||
                            quotation?.APROVACAO?.DD_STATUS === 'APROVADO' ||
                            quotation?.APROVACAO?.DD_STATUS ===
                                'EM_APROVACAO' ||
                            !!quotation?.DD_FINALIZADA

                        return columnHelper.display({
                            id: supplier.SK_FORNECEDOR,
                            meta: {
                                cell: {
                                    className: 'p-0',
                                },
                                enableMenu: false,
                                enableColumnOrdering: false,
                            },
                            enableHiding: false,
                            size: 800,
                            header: () => {
                                return (
                                    <HeaderContent
                                        disabled={disabled}
                                        onSelectAllFinalSupplier={
                                            onSelectAllFinalSupplier
                                        }
                                        values={values}
                                        supplier={supplier}
                                    />
                                )
                            },
                            cell: ({ row }) => {
                                const { SK_COTACAO_X_PRODUTO } = row.original

                                const value = values?.find(
                                    (value) =>
                                        supplier.FORNECEDOR_RODADAS.includes(
                                            value.SK_FORNECEDOR_X_RODADA
                                        ) &&
                                        value.SK_COTACAO_X_PRODUTO ===
                                            SK_COTACAO_X_PRODUTO
                                )

                                if (!value) return null

                                return (
                                    <CellContent
                                        isGrouped={false}
                                        quotationProductId={
                                            SK_COTACAO_X_PRODUTO
                                        }
                                        disabled={disabled}
                                        value={value}
                                        onCheckedChange={(checked) =>
                                            onSelectFinalSuppliers(
                                                SK_COTACAO_X_PRODUTO,
                                                {
                                                    [value!
                                                        .SK_FORNECEDOR_X_RODADA]:
                                                        !!checked,
                                                }
                                            )
                                        }
                                    />
                                )
                            },
                        })
                    }),
                ],
            }),
        ],
        [values, quotation]
    )

    const dataMemo = useMemo(() => {
        return products.map((product) => {
            return {
                ...product,
                ...values.find(
                    (value) =>
                        value.SK_COTACAO_X_PRODUTO ===
                        product.SK_COTACAO_X_PRODUTO
                )!,
            }
        })
    }, [quotationMap])

    const table = useReactTable({
        data: dataMemo,
        columns: columnsMemo,
        filterFromLeafRows: true,
        meta: {
            layout: 'stretch',
        },
        columnResizeMode: 'onChange',
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
    })

    const rows = table.getRowModel().rows
    // const mergedHeaders = getMergeHeaderGroups(table.getHeaderGroups())

    if (!quotationMap) return 'Carregando...'

    return (
        <div className="w-full h-full overflow-auto">
            {/* table */}
            <TableContainer>
                <TableContextProvider table={table}>
                    <TableLayout>
                        {/* thead */}
                        <div className="bg-background">
                            {table.getHeaderGroups().map((headerGroup) => (
                                // tr
                                <div key={headerGroup.id} className="flex">
                                    {headerGroup.headers.map((header) => (
                                        // th
                                        <TableColumnHeader
                                            key={header.id}
                                            header={header}
                                            // table={table}
                                        />
                                    ))}
                                </div>
                            ))}
                        </div>
                        {/* tbody */}
                        <TableBody>
                            {rows.map((row) => {
                                return (
                                    // tr
                                    <TableRow key={row.id}>
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                // td
                                                <TableCell
                                                    className="flex-start"
                                                    key={cell.id}
                                                    // table={table}
                                                    cell={cell}
                                                />
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </TableLayout>
                </TableContextProvider>
            </TableContainer>
        </div>
    )
}

export default QuotationEndTable
