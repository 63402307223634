import { LucideIcon, ShoppingBag, Table2, WalletCards } from 'lucide-react'
import { CoinsIcon } from 'lucide-react'

export const SIDENAV_ITEMS: SidenavItems[] = [
    {
        label: 'Cotações',
        Icon: CoinsIcon,
        pathname: '/cotacoes',
        href: '/cotacoes',
    },
    {
        label: 'Pedidos de compras',
        Icon: ShoppingBag,
        pathname: '/pedidos-de-compras',
        href: '/pedidos-de-compras',
    },
    {
        label: 'Solicitações de compras',
        Icon: WalletCards,
        pathname: '/solicitacoes-de-compras',
        href: '/solicitacoes-de-compras',
    },
    {
        label: 'Dados mestre',
        Icon: Table2,
        pathname: '/dados-mestre',
        href: '/dados-mestre',
        subItems: [
            {
                label: 'Fornecedores',
                pathname: '/fornecedores',
                href: '/dados-mestre/fornecedores',
            },
            {
                label: 'Produtos',
                pathname: '/produtos',
                href: '/dados-mestre/produtos',
            },
            {
                label: 'Empresas',
                pathname: '/empresas',
                href: '/dados-mestre/empresas',
            },
            {
                label: 'Condições de pagamento',
                pathname: '/condicoes-de-pagamento',
                href: '/dados-mestre/condicoes-de-pagamento',
            },
        ],
    },
]

export type SidenavItems = {
    label: string
    pathname?: string
    href?: string
    Icon?: LucideIcon
    subItems?: SidenavItems[]
}
