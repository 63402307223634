import { cn } from '@/lib/utils'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { PlusCircle } from 'lucide-react'
import { useParams } from 'react-router-dom'
import { MenubarItem } from '@/components/ui/menubar'

type NewRoundItemProps = {
    onClick: () => void
}

const NewRoundItem = ({ onClick }: NewRoundItemProps) => {
    const { id } = useParams()

    const {
        data: quotation,
        isPending: isQuotationPending,
        isError: isQuotationError,
    } = useQuotationByIdQuery({
        id: Number(id),
    })

    const disabled =
        isQuotationPending ||
        isQuotationError ||
        quotation?.DD_CANCELADA ||
        !quotation.DD_PUBLICADO ||
        (!!quotation.APROVACAO && quotation.APROVACAO?.DD_STATUS !== 'RECUSADO')

    return (
        <MenubarItem className="gap-2" disabled={disabled} onClick={onClick}>
            <PlusCircle
                size={14}
                className={cn(
                    disabled ? 'text-muted-foreground' : 'text-sky-500'
                )}
            />
            Nova rodada
        </MenubarItem>
    )
}

export default NewRoundItem
