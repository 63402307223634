import SupplierSection from './components/SupplierSection'
import BuyerSection from './components/BuyerSection'
import AttachmentsSection from './components/AttachmentsSection'

const SummarySection = () => {
    return (
        <div className="w-[320px] flex p-4 flex-col overflow-auto bg-neutral-50 dark:bg-accent">
            <div className="space-y-4">
                <BuyerSection />
                <AttachmentsSection />
                <SupplierSection />
            </div>
        </div>
    )
}

export default SummarySection
