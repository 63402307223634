import Container from '@/components/Container'
import { Button } from '@/components/ui/button'
import { TypographyLarge, TypographyMuted } from '@/components/ui/typography'
import { Link } from 'react-router-dom'

const Error = ({
    code,
    message = 'Oopss... Houve um erro inesperado ao tentar carregar o recurso',
}: {
    code: number
    message?: string
}) => {
    return (
        <Container className="mt-[8rem] gap-10 items-center">
            <div className="flex flex-col items-center justify-center">
                <TypographyLarge className="text-[8rem] font-bold text-primary">
                    {code}
                </TypographyLarge>
                <TypographyMuted className="text-center">
                    {message}
                </TypographyMuted>
            </div>
            <Button size="lg" asChild>
                <Link to="/" replace>
                    Voltar para página inicial
                </Link>
            </Button>
        </Container>
    )
}

export default Error
