import { cn } from '@/lib/utils'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'

import { SendHorizontal } from 'lucide-react'
import { useParams } from 'react-router-dom'
import { MenubarItem } from '@/components/ui/menubar'

type PublishItemProps = {
    onClick: () => void
}

const PublishItem = ({ onClick }: PublishItemProps) => {
    const { id } = useParams()

    const {
        data: quotation,
        isPending: isQuotationPending,
        isError: isQuotationError,
    } = useQuotationByIdQuery({
        id: Number(id),
    })

    const disabled =
        isQuotationPending ||
        quotation?.DD_PUBLICADO ||
        quotation?.DD_CANCELADA ||
        isQuotationError

    return (
        <MenubarItem className="gap-2" disabled={disabled} onClick={onClick}>
            <SendHorizontal
                size={14}
                className={cn(
                    disabled ? 'text-muted-foreground' : 'text-sky-500'
                )}
            />
            Publicar
        </MenubarItem>
    )
}

export default PublishItem
