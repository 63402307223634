import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Login from '@/pages/Login'
import NotFound from '@/pages/ErrorPages/NotFound'
import PublicLayout from '@/pages/Layouts/PublicLayout'
import ResetPassword from '@/pages/ResetPassword'
import DadosMestreAvaliacoes from '@/pages/DadosMestre/DadosMestreAvaliacoes'
import DadosMestreFornecedores from '@/pages/DadosMestre/DadosMestreFornecedores'
import DadosMestreParametrosAvaliacao from '@/pages/DadosMestre/DadosMestreParametrosAvaliacao'
import DadosMestreProdutos from '@/pages/DadosMestre/DadosMestreProdutos'
import DadosMestreCompanies from '@/pages/DadosMestre/DadosMestreCompanies'
import QuotationList from '@/pages/QuotationList'
import QuotationDetail from '@/pages/QuotationDetail'
import CotacaoCreatePurchaseRequest from './pages/CotacaoCreatePurchaseRequest'
import CotacaoCreatePurchaseRequestGenerate from './pages/CotacaoCreatePurchaseRequestGenerate'
import QuotationCreatePurchaseRequestLayout from './pages/Layouts/QuotationCreatePurchaseRequestLayout'
import DadosMestreCondicoesDePagamento from './pages/DadosMestre/DadosMestreCondicoesDePagamento'
import PurchaseOrder from './pages/PurchaseOrder'
import RequireAuth from '@/middlewares/RequireAuth'
import MainLayout from './pages/Layouts/MainLayout'
import SupplierMap from './pages/SupplierMap'
import QuotationEnd from './pages/QuotationEnd'
import PurchaseRequest from './pages/PurchaseRequest'
import QuotationDetailLayout from './pages/Layouts/QuotationDetailLayout'
import QuotationApproval from './pages/QuotationApproval'

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PublicLayout />}>
                    <Route path="*" element={<NotFound />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/recuperar-senha"
                        element={<ResetPassword />}
                    />
                </Route>
                <Route element={<MainLayout />}>
                    <Route
                        path="/"
                        element={<QuotationCreatePurchaseRequestLayout />}
                    >
                        <Route
                            index
                            element={
                                <RequireAuth isAllowed>
                                    <CotacaoCreatePurchaseRequest />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="gerar"
                            element={
                                <RequireAuth isAllowed>
                                    <CotacaoCreatePurchaseRequestGenerate />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="pedidos-de-compras"
                            element={
                                <RequireAuth isAllowed>
                                    <PurchaseOrder />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="solicitacoes-de-compras"
                            element={
                                <RequireAuth isAllowed>
                                    <PurchaseRequest />
                                </RequireAuth>
                            }
                        />
                    </Route>
                    <Route path="/cotacoes">
                        <Route
                            element={
                                <RequireAuth isAllowed>
                                    <QuotationList />
                                </RequireAuth>
                            }
                            index
                        />
                        <Route path="criar">
                            <Route
                                path="solicitacao-de-compras"
                                element={
                                    <QuotationCreatePurchaseRequestLayout />
                                }
                            >
                                <Route
                                    index
                                    element={
                                        <RequireAuth isAllowed>
                                            <CotacaoCreatePurchaseRequest />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="gerar"
                                    element={
                                        <RequireAuth isAllowed>
                                            <CotacaoCreatePurchaseRequestGenerate />
                                        </RequireAuth>
                                    }
                                />
                            </Route>
                        </Route>
                        <Route
                            path=":id"
                            element={
                                <RequireAuth isAllowed>
                                    <QuotationDetailLayout />
                                </RequireAuth>
                            }
                        >
                            <Route
                                index
                                element={
                                    <RequireAuth isAllowed>
                                        <QuotationDetail />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="aprovacao"
                                element={
                                    <RequireAuth isAllowed>
                                        <QuotationApproval />
                                    </RequireAuth>
                                }
                            />
                            <Route path="mapa">
                                <Route index element={<Navigate to={'..'} />} />
                                <Route path="fornecedor">
                                    <Route
                                        index
                                        element={<Navigate to={'..'} />}
                                    />
                                    <Route
                                        path=":supplierId"
                                        element={
                                            <RequireAuth isAllowed>
                                                <SupplierMap />
                                            </RequireAuth>
                                        }
                                    />
                                </Route>
                                <Route
                                    path="finalizar-cotacao"
                                    element={
                                        <RequireAuth isAllowed>
                                            <QuotationEnd />
                                        </RequireAuth>
                                    }
                                />
                            </Route>
                        </Route>
                    </Route>
                    <Route path="dados-mestre">
                        <Route
                            index
                            element={
                                <RequireAuth isAllowed>
                                    <Navigate to="fornecedores" />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="avaliacoes"
                            element={
                                <RequireAuth isAllowed={false}>
                                    <DadosMestreAvaliacoes />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="parametros-avaliacao"
                            element={
                                <RequireAuth isAllowed>
                                    <DadosMestreParametrosAvaliacao />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="fornecedores"
                            element={
                                <RequireAuth isAllowed>
                                    <DadosMestreFornecedores />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="produtos"
                            element={
                                <RequireAuth isAllowed>
                                    <DadosMestreProdutos />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="empresas"
                            element={
                                <RequireAuth isAllowed>
                                    <DadosMestreCompanies />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="condicoes-de-pagamento"
                            element={
                                <RequireAuth isAllowed>
                                    <DadosMestreCondicoesDePagamento />
                                </RequireAuth>
                            }
                        />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Router
