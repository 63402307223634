import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { usePurchaseRequestListQuery } from '@/queries/usePurchaseRequestQuery'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import DadosMestreHeader from '../DadosMestre/components/DadosMestreHeader'
import DadosMestreHeaderActions from '../DadosMestre/components/DadosMestreHeaderActions'
import { useTable } from '@/hooks/useTable'
import { Filter, Operators } from '@/types/Filters'
import { isAxiosError } from '@/api/business'
import { currencyFormat } from '@/utils/stringFormatter'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { cn } from '@/lib/utils'
import { TypographyP } from '@/components/ui/typography'
import {
    INTERVAL_CLASSNAME,
    PURCHASE_REQUEST_CLASSNAME,
    PURCHASE_REQUEST_STATUS,
    PurchaseRequestFlat,
} from '@/api/business/solicitacaoDeCompra/type'
import { format, isValid } from 'date-fns'
import { getDateFromId, getIdFromDate } from '@/utils/date'
import { Intervals, Priority } from '@/types/PurchaseOrder'
import { AlertTriangle } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

export type PurchaseRequestTable = TableData<PurchaseRequestFlat>

const columnHelper = createColumnHelper<PurchaseRequestFlat>()

const PurchaseRequest = () => {
    const {
        table,
        selectedRows,
        pageIndex,
        pageSize,
        columnFilters,
        onPaginationChange,
        onColumnFiltersChange,
        onClickMultiDelete,
        setTable,
    } = useTable<PurchaseRequestTable>()

    const navigate = useNavigate()

    const [fieldFilters, setFieldFilters] = useState<
        Filter<PurchaseRequestFlat>[]
    >([])

    const { data, isFetching, isPending, isError, error, refetch } =
        usePurchaseRequestListQuery({
            currentPage: pageIndex,
            perPage: pageSize,
            filters: fieldFilters,
            enabled: true,
        })

    const memoData = useMemo(() => (data ? data.DADOS : []), [data])
    const memoColumns = useMemo(
        () => [
            columnHelper.accessor(
                ({ STATUS_SC_COTACAO }) =>
                    PURCHASE_REQUEST_STATUS[STATUS_SC_COTACAO],
                {
                    id: 'STATUS_SC_COTACAO',
                    header: 'Status SC',
                    cell: ({ row }) => {
                        const { STATUS_SC_COTACAO } = row.original

                        return STATUS_SC_COTACAO ? (
                            <span
                                className={cn(
                                    'px-2 truncate text-accent-foreground bg-accent',
                                    PURCHASE_REQUEST_CLASSNAME[
                                        STATUS_SC_COTACAO
                                    ]
                                )}
                            >
                                {PURCHASE_REQUEST_STATUS[STATUS_SC_COTACAO]}
                            </span>
                        ) : null
                    },
                    meta: {
                        enableColumnOrdering: false,
                    },
                    size: 150,
                }
            ),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_SOLICITACAO_DE_COMPRAS',
                header: 'Número da SC',
                size: 100,
            }),
            columnHelper.accessor('NUMERO_DA_COTACAO', {
                id: 'NUMERO_DA_COTACAO',
                header: 'Número da cotação',
                cell: ({ getValue, row }) => {
                    const { SK_COTACAO } = row.original
                    return (
                        <TypographyP
                            className="text-xs text-blue-500 truncate cursor-pointer hover:underline"
                            onClick={() => navigate(`/cotacoes/${SK_COTACAO}`)}
                        >
                            {getValue()}
                        </TypographyP>
                    )
                },
                size: 100,
            }),
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Código empresa',
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original

                    return (
                        <p
                            className="overflow-hidden whitespace-nowrap text-ellipsis"
                            style={{
                                color: COR_EMPRESA || undefined,
                            }}
                        >
                            {getValue()}
                        </p>
                    )
                },
                size: 100,
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'ABREVIATURA_EMPRESA',
                header: 'Empresa',
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original

                    return (
                        <TypographyP
                            className="text-xs truncate"
                            style={{
                                color: COR_EMPRESA || undefined,
                            }}
                        >
                            {getValue()}
                        </TypographyP>
                    )
                },
                size: 130,
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_ITEM_SOLICITACAO_DE_COMPRAS',
                header: 'Item SC',
                size: 100,
            }),
            columnHelper.accessor('NK_PRODUTO', {
                id: 'NK_PRODUTO',
                header: 'Código produto',
                size: 120,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 400,
            }),
            columnHelper.accessor('DD_UM', {
                id: 'DD_UM',
                header: 'UM',
                size: 100,
            }),
            columnHelper.accessor('DD_NCM', {
                id: 'DD_NCM',
                header: 'NCM',
                size: 100,
            }),
            columnHelper.accessor('DS_GRUPO_DE_PRODUTOS', {
                id: 'DS_GRUPO_DE_PRODUTOS',
                header: 'Grupo de produto',
                size: 200,
            }),
            columnHelper.accessor('DD_URGENCIA', {
                id: 'DD_URGENCIA',
                header: 'Prioridade',
                cell: ({ getValue }) => {
                    const priority = getValue() as Priority
                    return priority === 'Urgente' ? (
                        <span className="flex items-center gap-1.5">
                            <AlertTriangle
                                size={12}
                                className="text-orange-500"
                            />
                            {priority}
                        </span>
                    ) : (
                        priority
                    )
                },
                size: 110,
            }),
            columnHelper.accessor(
                ({ DD_RATEIO }) => (DD_RATEIO === 1 ? 'Sim' : 'Não'),
                {
                    id: 'DD_RATEIO',
                    header: 'Rateio',
                    size: 110,
                }
            ),
            columnHelper.accessor('DD_STATUS', {
                id: 'DD_STATUS',
                header: 'Status',
                size: 200,
            }),
            columnHelper.accessor('DS_NOME_COMPRADOR', {
                id: 'DS_NOME_COMPRADOR',
                header: 'Comprador',
                size: 200,
            }),
            columnHelper.accessor('NK_CENTRO_DE_CUSTO', {
                id: 'NK_CENTRO_DE_CUSTO',
                header: 'Código centro de custo',
                size: 100,
            }),
            columnHelper.accessor('CENTRO_DE_CUSTO', {
                id: 'CENTRO_DE_CUSTO',
                header: 'Centro de custo',
                size: 200,
            }),
            columnHelper.accessor('NK_CONTA_ORCAMENTARIA', {
                id: 'NK_CONTA_ORCAMENTARIA',
                header: 'Código conta orçamentária',
                size: 100,
            }),
            columnHelper.accessor('CONTA_ORCAMENTARIA', {
                id: 'CONTA_ORCAMENTARIA',
                header: 'Conta orçamentária',
                size: 300,
            }),
            columnHelper.accessor('QTD_SOLICITADA', {
                id: 'QTD_SOLICITADA',
                header: 'Qtde solicitada',
                size: 100,
            }),
            columnHelper.accessor('QTD_PEDIDA', {
                id: 'QTD_PEDIDA',
                header: 'Qtde pedida',
                size: 100,
            }),
            columnHelper.accessor(
                ({ ULTIMO_PRECO }) =>
                    ULTIMO_PRECO
                        ? currencyFormat(Number(ULTIMO_PRECO) || 0)
                        : '-',
                {
                    id: 'ULTIMO_PRECO',
                    header: 'Último preço',
                    size: 100,
                }
            ),
            columnHelper.accessor(
                ({ DD_BLOQUEADO }) => (DD_BLOQUEADO ? 'Sim' : 'Não'),
                {
                    id: 'DD_BLOQUEADO',
                    header: 'Bloqueado',
                    cell: ({ getValue }) => {
                        return (
                            <span
                                className={cn(
                                    'px-2',
                                    getValue() === 'Sim'
                                        ? 'bg-red-50 text-red-500'
                                        : 'bg-emerald-50 text-emerald-500'
                                )}
                            >
                                {getValue()}
                            </span>
                        )
                    },
                    meta: {
                        cell: {
                            className: 'justify-center',
                        },
                    },
                    size: 120,
                }
            ),
            columnHelper.accessor('DD_SOLICITANTE', {
                id: 'DD_SOLICITANTE',
                header: 'Solicitante',
                size: 130,
            }),
            columnHelper.accessor('DD_OBSERVACAO', {
                id: 'DD_OBSERVACAO',
                header: 'Observação',
                size: 300,
            }),
            columnHelper.accessor('DD_APROVADOR', {
                id: 'DD_APROVADOR',
                header: 'Aprovador',
                size: 300,
            }),
            columnHelper.accessor(
                ({ DT_PRAZO }) =>
                    DT_PRAZO
                        ? format(getDateFromId(Number(DT_PRAZO)), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_PRAZO',
                    header: 'Prazo',
                    size: 100,
                }
            ),
            columnHelper.accessor('INTERVALO_PRAZO', {
                id: 'INTERVALO_PRAZO',
                header: 'Intervalo',
                cell: ({ getValue }) => {
                    const interval = getValue() as Intervals
                    return (
                        <span
                            className={cn(
                                'px-2 py-0.5 whitespace-nowrap overflow-hidden text-ellipsis',
                                INTERVAL_CLASSNAME[interval]
                            )}
                        >
                            {interval}
                        </span>
                    )
                },
                size: 200,
                meta: {
                    cell: {
                        className: 'justify-center',
                    },
                },
            }),
            columnHelper.accessor('ATRASO', {
                id: 'ATRASO',
                header: 'Atraso',
                size: 100,
            }),
            columnHelper.accessor(
                ({ DT_APROVACAO }) =>
                    DT_APROVACAO
                        ? format(getDateFromId(DT_APROVACAO), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_APROVACAO',
                    header: 'Data aprovação',
                    size: 100,
                }
            ),
            columnHelper.accessor(
                ({ DT_EMISSAO }) =>
                    DT_EMISSAO
                        ? format(getDateFromId(DT_EMISSAO), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_EMISSAO',
                    header: 'Data emissão',
                    size: 100,
                }
            ),
            columnHelper.accessor(
                ({ DT_NECESSIDADE }) =>
                    DT_NECESSIDADE
                        ? format(getDateFromId(DT_NECESSIDADE), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_NECESSIDADE',
                    header: 'Data necessidade',
                    size: 100,
                }
            ),
        ],
        []
    )

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<PurchaseRequestTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_SOLICITACAO_DE_COMPRAS.toString()}
                isLoading={isPending}
                isFetching={isFetching}
                isError={isError}
                errorMessage={
                    isError && isAxiosError(error)
                        ? error.response?.data.message
                        : 'Erro ao carregar pedidos de compras'
                }
                getTableInstance={(table) => setTable(table)}
                tableState={{
                    columnFilters,
                    pagination: {
                        pageSize,
                        pageIndex,
                    },
                }}
                pagination={{
                    pageSize,
                    totalPages: data?.TOTAL_DE_PAGINAS || 0,
                    totalItems: data?.TOTAL || 0,
                }}
                onPaginationChange={onPaginationChange}
                onColumnFiltersChange={(filters) => {
                    if (filters.length > 0) {
                        const filter: Filter<PurchaseRequestFlat>[] = [
                            {
                                AND: filters.map((filter) => {
                                    if (filter.id.includes('DT_')) {
                                        const dateString = (
                                            filter.value as string
                                        )
                                            .split('/')
                                            .map(Number)

                                        const date = new Date(
                                            dateString[2],
                                            dateString[1] - 1,
                                            dateString[0]
                                        )

                                        const formattedDate = isValid(date)
                                            ? getIdFromDate(date).join('')
                                            : (filter.value as string)

                                        return {
                                            field: filter.id as keyof PurchaseRequestFlat,
                                            value: formattedDate,
                                            operator: Operators.equals,
                                        }
                                    }

                                    switch (filter.id) {
                                        case 'DD_RATEIO':
                                            return {
                                                field: filter.id as keyof PurchaseRequestFlat,
                                                value:
                                                    (
                                                        filter.value as string
                                                    ).toLowerCase() === 'sim'
                                                        ? 1
                                                        : 0,
                                                operator: Operators.like,
                                            }
                                        default:
                                            return {
                                                field: filter.id as keyof PurchaseRequestFlat,
                                                value: filter.value as string,
                                                operator: Operators.like,
                                            }
                                    }
                                }),
                            },
                        ]

                        setFieldFilters(filter)
                    } else {
                        setFieldFilters([])
                    }
                    onColumnFiltersChange(filters)
                }}
                tableHeader={
                    <DadosMestreHeader
                        title="Solicitação de compras"
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions
                        onRefech={refetch}
                        tableId="DADOS_MESTRE_SOLICITACAO_DE_COMPRAS"
                    />
                }
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
        </div>
    )
}

export default PurchaseRequest
