import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import AddSuppliersButton from './components/AddSuppliersButton'
import NotifySuppliersButton from './components/NotifySuppliersButton'
import { TypographyLarge } from '@/components/ui/typography'
import { ChevronDown } from 'lucide-react'
import NotifySuppliersDialog from './components/NotifySuppliersDialog'
import useDisclosure from '@/hooks/useDisclosure'
import SuppliersToQuotationDialog from '@/components/SuppliersToQuotationDialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useToast } from '@/components/ui/use-toast'
import {
    quotationKeys,
    useQuotationProductsQuery,
} from '@/queries/useQuotationQuery'
import { useParams } from 'react-router-dom'
import { RowSelectionState } from '@tanstack/react-table'
import { addSupplierInQuotation } from '@/api/business/cotacoes'
import { isAxiosError } from '@/api/business'

const SuppliersActions = () => {
    const { id } = useParams()

    const {
        isOpen: isNotificationDialogOpen,
        onOpen: onNotificationDialogOpen,
        onClose: onNotificationDialogClose,
    } = useDisclosure()

    const {
        isOpen: isSuppliersDialogOpen,
        onOpen: onSuppliersDialogOpen,
        onClose: onSuppliersDialogClose,
    } = useDisclosure()

    const { data: products } = useQuotationProductsQuery({
        quotationId: Number(id),
    })

    const queryClient = useQueryClient()
    const { toast } = useToast()
    const [selectedSuppliers, onSelectSuppliers] = useState<RowSelectionState>(
        {}
    )

    const { mutate: mutateAddSupplier, isPending: isAddSupplierPending } =
        useMutation({
            mutationFn: addSupplierInQuotation,
            onSuccess: () => {
                if (id) {
                    queryClient.invalidateQueries({
                        queryKey: quotationKeys.detail(Number(id)),
                    })
                    queryClient.invalidateQueries({
                        queryKey: quotationKeys.detailSuppliers(Number(id)),
                    })
                }
                toast({
                    title: 'Fornecedores adicionados',
                    description: `${
                        Object.keys(selectedSuppliers).length
                    } fornecedores adicionados com sucesso.`,
                })
                onSelectSuppliers({})
                onSuppliersDialogClose()
            },
            onError: (err) => {
                let errorMessage =
                    'Não foi possível adicionar fornecedor. Tente novamente mais tarde.'

                if (isAxiosError(err)) {
                    errorMessage = err.response?.data.message || errorMessage
                }

                toast({
                    title: 'Erro ao adicionar fornecedor',
                    description: errorMessage,
                    variant: 'destructive',
                })
            },
        })

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger className="flex items-center gap-2 rounded-md hover:bg-accent">
                    <TypographyLarge>Fornecedores</TypographyLarge>
                    <ChevronDown size={18} />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    <AddSuppliersButton onOpen={onSuppliersDialogOpen} />
                    <NotifySuppliersButton onOpen={onNotificationDialogOpen} />
                </DropdownMenuContent>
            </DropdownMenu>
            <NotifySuppliersDialog
                isOpen={isNotificationDialogOpen}
                onClose={onNotificationDialogClose}
            />
            <SuppliersToQuotationDialog
                isOpen={isSuppliersDialogOpen}
                onClose={onSuppliersDialogClose}
                rowSelection={{
                    onSelectSuppliers,
                    selectedRows: selectedSuppliers,
                }}
                dialogState={isAddSupplierPending ? 'loading' : undefined}
                items={
                    products
                        ? products.map((product) => ({
                              NK_PRODUTO: product.NK_PRODUTO!,
                              SK_EMPRESA: product.SK_EMPRESA,
                          }))
                        : []
                }
                onCloseWithAction={() => {
                    mutateAddSupplier({
                        quotationId: Number(id),
                        suppliersIds: Object.keys(selectedSuppliers).map(
                            (id) => ({
                                SK_FORNECEDOR: id,
                            })
                        ),
                    })
                }}
            />
        </>
    )
}

export default SuppliersActions
