import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { TypographyLarge } from '@/components/ui/typography'
import { ChevronDown, FilePlus2 } from 'lucide-react'
import AddAttachmentDialog from '../../../AddAttachmentDialog'
import useDisclosure from '@/hooks/useDisclosure'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { useParams } from 'react-router-dom'

const AttachmentsActions = () => {
    const { id } = useParams()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { data } = useQuotationByIdQuery({ id: Number(id) })

    const disabled = data?.DD_CANCELADA || !!data?.APROVACAO

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger className="flex items-center gap-2 rounded-md hover:bg-accent">
                    <TypographyLarge>Arquivos</TypographyLarge>
                    <ChevronDown size={18} />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    <DropdownMenuItem
                        className="gap-2"
                        onClick={onOpen}
                        disabled={disabled}
                    >
                        <FilePlus2 size={14} />
                        Adicionar anexo
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
            <AddAttachmentDialog isOpen={isOpen} onClose={onClose} />
        </>
    )
}

export default AttachmentsActions
