import PageLoader from '@/pages/PageLoader'
import QuotationDetailSkeleton from '@/pages/Layouts/QuotationDetailLayout/components/QuotationDetailSkeleton'
import { useQuotationMapQuery } from '@/queries/useQuotatioMapQuery'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { Outlet, useParams } from 'react-router-dom'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { useEffect } from 'react'

const stateSelector = (state: Store) => ({
    onSetData: state.quotationMapSlice.actions.onSetData,
    onSetRounds: state.quotationDetailSlice.actions.onSetRounds,
})

const QuotationDetailLayout = () => {
    const { id } = useParams()
    const { data, isPending, error } = useQuotationByIdQuery({ id: Number(id) })
    const {
        data: quotationMap,
        isPending: isMapPending,
        error: mapError,
    } = useQuotationMapQuery({
        id: Number(id),
    })

    const { onSetData, onSetRounds } = useBaseStore(stateSelector)

    useEffect(() => {
        if (data && quotationMap) {
            onSetData(quotationMap, data)

            onSetRounds(
                data.RODADAS.map((round) => ({
                    roundId: round.SK_COTACAO_X_RODADA,
                    round: round.NUMERO_RODADA,
                    expirationDate: round.DT_EXPIRACAO,
                    finished: round.DD_FINALIZADA === 1 ? true : false,
                    createdAt: round.CREATED_AT,
                    updatedAt: round.UPDATED_AT,
                }))
            )
        }
    }, [data, quotationMap])

    return (
        <PageLoader
            isLoading={isPending || isMapPending}
            fallback={<QuotationDetailSkeleton />}
            error={error ?? mapError}
            defaultMessage="Houve um erro ao buscar cotação. Tente novamente mais tarde."
        >
            <Outlet />
        </PageLoader>
    )
}

export default QuotationDetailLayout
