import instance from '@/api/business'
import {
    FetchPurchaseRequestItemsProps,
    FetchPurchaseRequestItemsResponse,
    FetchPurchaseRequestProps,
    FetchPurchaseRequestResponse,
    FetchPurchaseRequestToSelectProps,
    FetchQuotationPurchaseRequestProps,
    FetchQuotationPurchaseRequestResponse,
    PurchaseRequestFilters,
    PurchaseRequestFlat,
} from './type'

const BASE_ROUTE = '/sc'

export const fetchPurchaseRequest = async ({
    currentPage,
    perPage,
    filters,
}: FetchPurchaseRequestProps) => {
    const response = await instance.post<FetchPurchaseRequestResponse>(
        `${BASE_ROUTE}/paginacao`,
        {
            POR_PAGINA: perPage,
            PAGINA_ATUAL: currentPage,
            FILTROS: filters || [],
        }
    )

    return response.data
}

export const fetchQuotationPurchaseRequest = async ({
    currentPage,
    perPage,
    filters,
}: FetchQuotationPurchaseRequestProps) => {
    const response = await instance.post<FetchQuotationPurchaseRequestResponse>(
        `${BASE_ROUTE}/cotacao`,
        {
            POR_PAGINA: perPage,
            PAGINA_ATUAL: currentPage,
            FILTROS: filters || [],
        }
    )

    return response.data
}

export const fetchPurchaseRequestItems = async ({
    companyId,
    purchaseRequestNumber,
}: FetchPurchaseRequestItemsProps) => {
    const response = await instance.post<FetchPurchaseRequestItemsResponse>(
        `${BASE_ROUTE}/itens`,
        {
            SK_EMPRESA: companyId,
            NK_SOLICITACAO_DE_COMPRAS: purchaseRequestNumber,
        }
    )

    return response.data
}

export const fetchPurchaseRequestFilters = async () => {
    const response = await instance.post<PurchaseRequestFilters>(
        `${BASE_ROUTE}/filtros`
    )

    return response.data
}

export const fetchPurchaseRequestToSelect = async ({
    filters,
}: FetchPurchaseRequestToSelectProps) => {
    const response = await instance.post<PurchaseRequestFlat[]>(
        `${BASE_ROUTE}/cotacao/filtros`,
        {
            FILTROS: filters,
        }
    )

    return response.data
}
