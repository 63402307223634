import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { Button } from '@/components/ui/button'
import { TypographyP } from '@/components/ui/typography'
import { isAxiosError } from '@/api/business'
import { QuotationMapFlatState } from '@/store/quotationMapSlice/type'
import { removeProductGroup } from '@/api/business/cotacoes'
import { quotationMapKeys } from '@/queries/useQuotatioMapQuery'

type RemoveProductGroupDialogProps = {
    product: Pick<
        QuotationMapFlatState,
        | 'SK_RESPOSTA_FORNECEDOR'
        | 'DS_PRODUTO'
        | 'DD_RECUSADO'
        | 'PRECO'
        | 'QTD_SOLICITADA'
        | 'PREVISAO_DE_ENTREGA'
        | 'OBSERVACAO'
        | 'SK_RESPOSTA_SUPERIOR'
        | 'GRUPO'
    >
    isOpen: boolean
    onOpenChange: (isOpen: boolean) => void
}

const RemoveProductGroupDialog = ({
    isOpen,
    product: { DS_PRODUTO, SK_RESPOSTA_FORNECEDOR },
    onOpenChange,
}: RemoveProductGroupDialogProps) => {
    const queryClient = useQueryClient()

    const onSubmit = () => {
        mutate({
            SK_RESPOSTA_FORNECEDOR,
        })
    }

    const { mutate, isPending } = useMutation({
        mutationFn: removeProductGroup,
        onSuccess: () => {
            onOpenChange(false)
            queryClient.invalidateQueries({ queryKey: quotationMapKeys.all })
            toast.success('Agrupamento removido com sucesso')
        },
        onError: (err) => {
            let message = 'Erro ao remover agrupamento'

            if (isAxiosError(err)) {
                message = err.response?.data?.message || message
            }

            toast.error(message)
        },
    })

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent className="max-w-[680px]">
                <DialogHeader>
                    <DialogTitle>Remover agrupamento</DialogTitle>
                </DialogHeader>
                <div>
                    <TypographyP>
                        Deseja remover o agrupamento do produto {DS_PRODUTO}?
                    </TypographyP>
                </div>
                <DialogFooter>
                    <Button onClick={() => onOpenChange(false)} variant="ghost">
                        Cancelar
                    </Button>
                    <Button disabled={isPending} onClick={onSubmit}>
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default RemoveProductGroupDialog
