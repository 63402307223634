'use client'

import { Check, ChevronsUpDown } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '@/components/ui/command'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { TypographyP } from '../ui/typography'

interface MultiSelectProps {
    label?: string
    emptyLabel?: string
    placeholder?: string
    options: {
        value: string
        label: JSX.Element | string
    }[]
    selectedValues: string[]
    disabled?: boolean
    onChange: (value: string) => void
    customFilter?: (value: string, search: string) => number
    onSelectAll?: (values: string[]) => void
}

export function MultiSelect({
    label,
    emptyLabel,
    placeholder,
    options,
    selectedValues,
    disabled,
    customFilter,
    onChange,
    onSelectAll,
}: MultiSelectProps) {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    className="justify-between w-full text-foreground hover:text-muted-foreground"
                    disabled={disabled}
                >
                    <TypographyP className="truncate">
                        {selectedValues.length > 0
                            ? `${selectedValues.length} itens selecionados`
                            : label || 'Selecionar'}
                    </TypographyP>
                    <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
                </Button>
            </PopoverTrigger>
            <PopoverContent
                className="w-[400px] p-0 overflow-hidden"
                align="start"
            >
                <Command filter={customFilter}>
                    <CommandInput
                        placeholder={placeholder || 'Buscar item...'}
                    />
                    <CommandList>
                        <CommandEmpty>
                            {emptyLabel || 'Nenhum item selecionado'}
                        </CommandEmpty>
                        <CommandGroup className="max-h-[200px] overflow-y-auto">
                            <>
                                {onSelectAll && (
                                    <CommandItem
                                        onSelect={() =>
                                            onSelectAll(
                                                options.map(
                                                    (option) => option.value
                                                )
                                            )
                                        }
                                    >
                                        <Check
                                            className={cn(
                                                'mr-2 h-4 w-4',
                                                selectedValues.length ===
                                                    options.length
                                                    ? 'opacity-100'
                                                    : 'opacity-0'
                                            )}
                                        />
                                        <span className="truncate">
                                            Selecionar todos
                                        </span>
                                    </CommandItem>
                                )}
                                {options.map((option) => (
                                    <CommandItem
                                        key={option.value}
                                        value={option.value}
                                        onSelect={(currentValue) => {
                                            onChange(currentValue)
                                        }}
                                    >
                                        <Check
                                            className={cn(
                                                'mr-2 h-4 w-4',
                                                selectedValues
                                                    .map((value) =>
                                                        value.toLowerCase()
                                                    )
                                                    .includes(
                                                        option.value.toLowerCase()
                                                    )
                                                    ? 'opacity-100'
                                                    : 'opacity-0'
                                            )}
                                        />
                                        {typeof option.label === 'string' ? (
                                            <span className="flex-1 truncate">
                                                {option.label}
                                            </span>
                                        ) : (
                                            option.label
                                        )}
                                    </CommandItem>
                                ))}
                            </>
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
