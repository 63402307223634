import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useForm } from 'react-hook-form'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import PurchaseRequestItems from './components/PurchaseRequestItems'
import { zodResolver } from '@hookform/resolvers/zod'
import { createPurchaseRequestQuotationSchema } from '@/schemas/quotationSchema'
import { z } from 'zod'
import SuppliersSection from './components/SuppliersSection'
import useDisclosure from '@/hooks/useDisclosure'
import { ChangeEvent, useEffect } from 'react'
import File from '@/components/File'
import { createQuotation } from '@/api/business/cotacoes'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { format, isValid } from 'date-fns'
import { useToast } from '@/components/ui/use-toast'
import { MAX_FILE_SIZE } from '@/config/file'
import { useBaseStore } from '@/store'
import {
    getSelectedItemsSelector,
    getSuppliersSelectedSelector,
} from '@/store/quotationPurchaseRequestSlice'
import { Separator } from '@/components/ui/separator'
import PaymentConditionsDialog from '../../components/PaymentConditionsDialog'
import { Loader, Save } from 'lucide-react'
import { isAxiosError } from '@/api/business'
import { Store } from '@/store/type'
import PaymentConditionItem from '../../components/PaymentConditionsDialog/components/PaymentConditionItem'
import ExpirationDateFormField from './components/ExpirationDateFormField'
import SuppliersToQuotationDialog from '@/components/SuppliersToQuotationDialog'
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { TypographyH2 } from '@/components/ui/typography'
import { quotationKeys } from '@/queries/useQuotationQuery'

export type CreateQuotationSchema = z.infer<
    typeof createPurchaseRequestQuotationSchema
>

const actionsSelector = (state: Store) => ({
    onChangeFormState:
        state.quotationPurchaseRequestSlice.actions.onChangeFormState,
    onSelectSuppliers:
        state.quotationPurchaseRequestSlice.actions.onSelectSuppliers,
    onRemoveAllSuppliers:
        state.quotationPurchaseRequestSlice.actions.onRemoveAllSuppliers,
    onRemoveSupplier:
        state.quotationPurchaseRequestSlice.actions.onRemoveSupplier,
    onAddAttachments:
        state.quotationPurchaseRequestSlice.actions.onAddAttachments,
    onRemoveAttachment:
        state.quotationPurchaseRequestSlice.actions.onRemoveAttachment,
    onChangePaymentCondition:
        state.quotationPurchaseRequestSlice.actions.onChangePaymentCondition,
    setPaymentConditionsSelected:
        state.quotationPurchaseRequestSlice.actions
            .setPaymentConditionsSelected,
    onChangePreference:
        state.quotationPurchaseRequestSlice.actions.onChangePreference,
})

const stateSelector = (state: Store) => ({
    attachments: state.quotationPurchaseRequestSlice.state.attachments,
    formState: state.quotationPurchaseRequestSlice.state.formState,
    suppliersRowSelection:
        state.quotationPurchaseRequestSlice.state.suppliersRowSelection,
    paymentConditionsSelected:
        state.quotationPurchaseRequestSlice.state.paymentConditionsSelected,
})

const CotacaoCreatePurchaseRequestGenerate = () => {
    const queryClient = useQueryClient()

    const {
        onChangeFormState,
        onSelectSuppliers,
        onRemoveAllSuppliers,
        onRemoveSupplier,
        onAddAttachments,
        onRemoveAttachment,
        onChangePaymentCondition,
        setPaymentConditionsSelected,
        onChangePreference,
    } = useBaseStore(actionsSelector)

    const {
        attachments,
        formState,
        suppliersRowSelection,
        paymentConditionsSelected,
    } = useBaseStore(stateSelector)

    const selectedItems = useBaseStore(getSelectedItemsSelector)

    const selectedSuppliers = useBaseStore(getSuppliersSelectedSelector)

    const navigate = useNavigate()
    const { toast } = useToast()

    const { mutate: mutateCreate, isPending: isCreateLoading } = useMutation({
        mutationFn: createQuotation,
        onSuccess: ({ SK_COTACAO }) => {
            queryClient.invalidateQueries({ queryKey: quotationKeys.all })
            navigate(`/cotacoes/${SK_COTACAO}`)
            toast({
                title: 'Cotação gerada com sucesso',
            })
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível gerar a cotação. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao gerar cotação',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const {
        isOpen: isSuppliersDialogOpen,
        onClose: onSuppliersDialogClose,
        onOpen: onSuppliersDialogOpen,
    } = useDisclosure()

    const {
        isOpen: isPaymentConditionsDialogOpen,
        onClose: onPaymentConditionsDialogClose,
        onOpen: onPaymentConditionsDialogOpen,
    } = useDisclosure()

    const form = useForm<CreateQuotationSchema>({
        resolver: zodResolver(createPurchaseRequestQuotationSchema),
        defaultValues: {
            ...formState,
            purchaseRequests: selectedItems,
        },
    })

    const onSubmit = (data: CreateQuotationSchema) => {
        mutateCreate({
            headers: {
                DD_SOLICITACAO_DE_COMPRAS: 1,
                NM_COTACAO: data.quotation,
                DS_COTACAO: data.description,
                DT_EXPIRACAO:
                    data.expirationDate &&
                    isValid(new Date(data.expirationDate))
                        ? format(new Date(data.expirationDate), 'yyyy-MM-dd')
                        : format(new Date(), 'yyyy-MM-dd'),
                DD_PUBLICADO: 0,
            },
            attachments: data.attachments || [],
            purchaseRequests: data.purchaseRequests.map((item) => ({
                SK_SOLICITACAO_DE_COMPRAS: item.SK_SOLICITACAO_DE_COMPRAS,
            })),
            suppliers: data.suppliers,
            paymentConditions: data.paymentConditions,
        })
    }

    const onFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files

        if (fileList) {
            const files = Object.values(fileList)

            if (files.some((file) => file.size > MAX_FILE_SIZE)) {
                return toast({
                    title: 'Arquivo muito grande',
                    description: 'O arquivo não pode ter mais que 50MB',
                    variant: 'destructive',
                })
            }

            if (files.length > 0) {
                onAddAttachments(
                    Object.values(files).map((file) => ({
                        file,
                        id: crypto.randomUUID(),
                    }))
                )
            }
        }
    }

    useEffect(() => {
        form.reset({
            ...formState,
            suppliers: selectedSuppliers,
            purchaseRequests: selectedItems,
            attachments: attachments.map((file) => file.file),
            paymentConditions: paymentConditionsSelected,
        })
    }, [
        formState,
        selectedSuppliers,
        selectedItems,
        attachments,
        paymentConditionsSelected,
    ])

    if (selectedItems.length === 0) {
        return <Navigate to=".." state={{ redirected: true }} />
    }

    return (
        <>
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="flex flex-col h-full overflow-hidden"
                >
                    <div className="flex items-center justify-between px-4 py-2 border-b">
                        <Breadcrumb>
                            <BreadcrumbList>
                                <BreadcrumbItem>
                                    <BreadcrumbLink asChild>
                                        <Link
                                            className="hover:underline"
                                            to="/cotacoes"
                                        >
                                            Cotações
                                        </Link>
                                    </BreadcrumbLink>
                                </BreadcrumbItem>
                                <BreadcrumbSeparator />
                                <BreadcrumbItem>
                                    <BreadcrumbLink asChild>
                                        <Link
                                            className="hover:underline"
                                            to=".."
                                        >
                                            Solicitações de compra
                                        </Link>
                                    </BreadcrumbLink>
                                </BreadcrumbItem>
                                <BreadcrumbSeparator />
                                <BreadcrumbItem>
                                    <BreadcrumbPage>
                                        Gerar cotação
                                    </BreadcrumbPage>
                                </BreadcrumbItem>
                            </BreadcrumbList>
                        </Breadcrumb>
                        <button
                            className="flex items-center gap-2 px-4 py-2 text-sm border rounded-lg hover:bg-slate-100 border-slate-200 hover:text-slate-600"
                            disabled={isCreateLoading}
                        >
                            {isCreateLoading ? (
                                <Loader size={14} className="animate-spin" />
                            ) : (
                                <Save size={14} className="text-sky-500" />
                            )}
                            Gravar cotação
                        </button>
                    </div>
                    <div className="flex-col flex-1 hidden overflow-y-auto lg:flex">
                        <div className="flex flex-col flex-1 p-4 space-y-6">
                            <div className="flex divide-x-[1px] items-top">
                                <div className="flex-1 pr-10 space-y-4">
                                    <FormField
                                        name="quotation"
                                        control={form.control}
                                        render={({ field }) => {
                                            return (
                                                <FormItem className="flex-1">
                                                    <FormLabel>
                                                        Nome da cotação
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            onBlur={(e) =>
                                                                onChangeFormState(
                                                                    field.name,
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )
                                        }}
                                    />
                                    <FormField
                                        name="description"
                                        control={form.control}
                                        render={({ field }) => {
                                            return (
                                                <FormItem className="flex-1">
                                                    <FormLabel>
                                                        Descrição
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            onBlur={(e) =>
                                                                onChangeFormState(
                                                                    field.name,
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )
                                        }}
                                    />
                                    <ExpirationDateFormField form={form} />
                                </div>
                                <div className="flex-1 px-10">
                                    <FormField
                                        name="paymentConditions"
                                        control={form.control}
                                        render={({ field }) => {
                                            return (
                                                <>
                                                    <FormItem className="flex-1">
                                                        <FormLabel>
                                                            Condições de
                                                            pagamento
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                className="cursor-pointer"
                                                                value={`${field.value.length} selecionadas`}
                                                                readOnly
                                                                onClick={
                                                                    onPaymentConditionsDialogOpen
                                                                }
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                    <div>
                                                        <ul className="max-h-[180px] overflow-auto">
                                                            {field.value.map(
                                                                (condition) => (
                                                                    <PaymentConditionItem
                                                                        key={
                                                                            condition.SK_CONDICAO_DE_PAGAMENTO
                                                                        }
                                                                        checked={
                                                                            true
                                                                        }
                                                                        paymentCondition={
                                                                            condition
                                                                        }
                                                                        onChangePreference={
                                                                            onChangePreference
                                                                        }
                                                                        onSelect={() => {}}
                                                                        onRemove={() =>
                                                                            onChangePaymentCondition(
                                                                                condition
                                                                            )
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </>
                                            )
                                        }}
                                    />
                                </div>
                                <div className="flex-1 pl-10">
                                    <FormField
                                        name="attachments"
                                        render={() => {
                                            return (
                                                <FormItem className="flex-1">
                                                    <FormLabel>
                                                        Anexos
                                                    </FormLabel>
                                                    <FormControl>
                                                        <>
                                                            <label
                                                                htmlFor="file"
                                                                className="flex items-center justify-center p-2 mb-4 text-sm border rounded-md cursor-pointer text-neutral-500 hover:bg-neutral-50"
                                                            >
                                                                Escolher
                                                                arquivos
                                                            </label>
                                                            <Input
                                                                id="file"
                                                                type="file"
                                                                className="invisible hidden"
                                                                maxLength={
                                                                    MAX_FILE_SIZE
                                                                }
                                                                onChange={
                                                                    onFileInputChange
                                                                }
                                                            />
                                                            <div className="space-y-2">
                                                                {attachments.map(
                                                                    (
                                                                        attachment
                                                                    ) => (
                                                                        <File
                                                                            file={
                                                                                attachment.file
                                                                            }
                                                                            type="file"
                                                                            key={
                                                                                attachment.id
                                                                            }
                                                                            id={
                                                                                attachment.id
                                                                            }
                                                                            onRemove={() =>
                                                                                onRemoveAttachment(
                                                                                    attachment.id
                                                                                )
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            </div>
                                                        </>
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col max-h-[500px] overflow-hidden">
                                <div className="mb-4">
                                    <TypographyH2 className="border-none">
                                        Itens SC
                                    </TypographyH2>
                                    <Separator />
                                </div>
                                <div className="flex flex-col flex-1 overflow-hidden">
                                    <PurchaseRequestItems />
                                </div>
                            </div>
                            <SuppliersSection
                                onClearAll={() => {
                                    onRemoveAllSuppliers()
                                }}
                                onRemoveSupplier={(id) => {
                                    onRemoveSupplier(id.toString())
                                }}
                                onSuppliersDialogOpen={onSuppliersDialogOpen}
                                suppliers={selectedSuppliers}
                            />
                            <div className="p-6" />
                        </div>
                    </div>
                </form>
            </Form>

            <SuppliersToQuotationDialog
                isOpen={isSuppliersDialogOpen}
                onClose={onSuppliersDialogClose}
                rowSelection={{
                    selectedRows: suppliersRowSelection,
                    onSelectSuppliers: (rowSelection, suppliers) => {
                        onSelectSuppliers(rowSelection, suppliers)
                    },
                }}
                items={selectedItems}
            />
            <PaymentConditionsDialog
                isOpen={isPaymentConditionsDialogOpen}
                onClose={onPaymentConditionsDialogClose}
                initialSelected={paymentConditionsSelected}
                onConfirm={(paymentConditions) => {
                    setPaymentConditionsSelected(paymentConditions)
                    onPaymentConditionsDialogClose()
                }}
            />
        </>
    )
}

export default CotacaoCreatePurchaseRequestGenerate
