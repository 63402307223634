import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { Download, Trash } from 'lucide-react'

interface FileDropdownMenuProps {
    onRemove?: () => void
    onDownload?: () => void
}

const FileMenuDropdown = ({ onDownload, onRemove }: FileDropdownMenuProps) => {
    return onDownload || onRemove ? (
        <DropdownMenu>
            <DropdownMenuTrigger>
                <DotsHorizontalIcon />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
                {onDownload && (
                    <DropdownMenuItem
                        className="items-center gap-2"
                        disabled={!onDownload}
                        onClick={onDownload}
                    >
                        <Download size={14} className="text-sky-500" />
                        Download
                    </DropdownMenuItem>
                )}
                {onRemove && (
                    <DropdownMenuItem
                        className="items-center gap-2"
                        disabled={!onRemove}
                        onClick={onRemove}
                    >
                        <Trash size={14} className="text-red-500" />
                        Remover
                    </DropdownMenuItem>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    ) : null
}

export default FileMenuDropdown
