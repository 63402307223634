import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { useParams } from 'react-router-dom'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { History, Paperclip } from 'lucide-react'
import { useApprovalAttachmentsQuery } from '@/queries/useAttachmentsQuery'
import ApprovalAttachmentsTable from '../ApprovalAttachmentsTable'
import ApprovalHistory from '../ApprovalHistory'

const Content = () => {
    const { id } = useParams()

    const { data: quotation } = useQuotationByIdQuery({ id: Number(id) })
    const { data: attachments = [] } = useApprovalAttachmentsQuery({
        quotationId: Number(id),
    })

    const status = quotation?.APROVACAO?.DD_STATUS

    const showToolbar = !!status

    if (showToolbar) {
        return (
            <Tabs
                className="flex flex-col flex-1 w-full overflow-hidden"
                defaultValue="details"
            >
                {showToolbar ? (
                    <div className="flex items-center gap-4 border-b bg-accent border-neutral-300">
                        <TabsList className="self-end ml-auto rounded-md">
                            <TabsTrigger value="details">Detalhes</TabsTrigger>
                            <TabsTrigger value="attachments" className="gap-2">
                                <span className="px-1 rounded-md bg-neutral-300">
                                    {attachments.length}
                                </span>
                                <Paperclip size={14} />
                            </TabsTrigger>
                        </TabsList>
                    </div>
                ) : null}
                <TabsContent value="details" className="h-full overflow-auto">
                    <ApprovalHistory />
                </TabsContent>
                <TabsContent value="attachments" className="overflow-auto">
                    {attachments.length === 0 ? (
                        <div className="flex items-center justify-center h-[130px]">
                            <TypographyMuted>
                                Não há arquivos enviados
                            </TypographyMuted>
                        </div>
                    ) : (
                        <div className="flex flex-col h-full overflow-hidden">
                            <ApprovalAttachmentsTable />
                        </div>
                    )}
                </TabsContent>
            </Tabs>
        )
    }

    return (
        <div className="flex items-center justify-center p-4">
            <TypographyMuted>Mapa não está em aprovação</TypographyMuted>
        </div>
    )
}

const ApprovalDialog = () => {
    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button
                    className="flex items-center gap-1.5"
                    variant="ghost"
                    size="sm"
                >
                    <History size={14} />
                    <TypographyP>Histórico</TypographyP>
                </Button>
            </DialogTrigger>
            <DialogContent className="flex flex-col max-w-[90%] items-start h-[80%]">
                <DialogHeader>
                    <DialogTitle>Histórico</DialogTitle>
                    <DialogDescription>
                        Status de aprovação dos fornecedores selecionados
                    </DialogDescription>
                </DialogHeader>
                <Content />
            </DialogContent>
        </Dialog>
    )
}

export default ApprovalDialog
