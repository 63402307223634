import { useBaseStore } from '@/store'
import { quotationMapStateSelector } from '@/store/quotationMapSlice'
import { useCallback, useMemo, useState } from 'react'
import {
    ColumnOrderState,
    createColumnHelper,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    Header,
    useReactTable,
} from '@tanstack/react-table'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import TableContainer from '@/components/Table/components/TableContainer'
import { TableContextProvider } from '@/components/Table/contexts/TableContext'
import TableLayout from '@/components/Table/components/TableLayout'
import TableColumnHeader from '@/components/Table/components/TableColumnHeader'
import TableRow from '@/components/Table/components/TableRow'
import TableCell from '@/components/Table/components/TableCell'
import TableFooter from '@/components/Table/components/TableFooter'
import {
    QuotationMapProduct,
    QuotationMapValues,
} from '@/api/business/cotacoes/type'
import HeaderContent from './components/HeaderContent'
import CellContent from './components/CellContent'
import { QuotationMapSupplierValuesState } from '@/store/quotationMapSlice/type'
import { ApprovalComponent } from '@/pages/QuotationDetail/components/QuotationMapSection/components/QuotationMapTable'
import TableBody from '@/components/Table/components/TableBody'
import { cn } from '@/lib/utils'

type QuotationMapTableType = QuotationMapValues & QuotationMapProduct

const columnHelper = createColumnHelper<QuotationMapTableType>()

const HEADER_CLASSNAME = 'text-xs flex items-center h-auto font-bold'

const COMMON_COLUMN_META = {
    enableColumnOrdering: false,
    enableMenu: false,
    header: {
        className: HEADER_CLASSNAME,
    },
    cell: {
        className: 'p-0 items-start',
    },
}

const TOTALS_HEADER_CLASSNAME = 'bg-accent text-accent-foreground'
const SAVINGS_HEADER_CLASSNAME = 'text-primary-foreground'
const TOTALS_CELL_CLASSNAME =
    'group-hover:bg-neutral-200 bg-accent font-semibold text-accent-foreground'
const SAVINGS_CELL_CLASSNAME =
    'group-hover:bg-primary-200 bg-primary-50 text-primary-foreground'

const ApprovalTable = () => {
    const { quotationMap } = useBaseStore(quotationMapStateSelector)

    const [columnOrderState, setColumnOrderState] = useState<ColumnOrderState>(
        []
    )

    const suppliers = useMemo(
        () => Object.values(quotationMap?.FORNECEDORES.entities || []),
        [quotationMap.FORNECEDORES]
    )

    const products = useMemo(
        () => Object.values(quotationMap?.PRODUTOS.entities || []),
        [quotationMap.PRODUTOS]
    )

    const values = quotationMap?.VALORES || []

    const getValue = useCallback(
        (
            supplier: QuotationMapSupplierValuesState,
            quotationRoundId: number,
            quotationProductId: number
        ) => {
            const foundProduct = quotationMap?.VALORES.find(
                (value) =>
                    supplier.FORNECEDOR_RODADAS.includes(
                        value.SK_FORNECEDOR_X_RODADA
                    ) &&
                    quotationRoundId === value.SK_COTACAO_X_RODADA &&
                    value.SK_COTACAO_X_PRODUTO === quotationProductId
            )

            return foundProduct
        },
        [quotationMap]
    )

    const columnsMemo = useMemo(
        () => [
            columnHelper.accessor('SK_EMPRESA', {
                header: 'Cod Empresa',
                size: 80,
                enableHiding: false,
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original

                    return (
                        <span
                            style={{ color: COR_EMPRESA }}
                            className="truncate"
                        >
                            {getValue()}
                        </span>
                    )
                },
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                header: 'Empresa',
                size: 150,
                enableHiding: false,
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original

                    return (
                        <span
                            style={{ color: COR_EMPRESA }}
                            className="truncate"
                        >
                            {getValue()}
                        </span>
                    )
                },
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                header: 'Item',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 80,
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                header: 'SC',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 100,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                header: 'Produto',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 400,
            }),
            columnHelper.accessor('DD_UM', {
                header: 'UM',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 80,
            }),
            columnHelper.accessor(
                ({ ULTIMO_PRECO }) =>
                    ULTIMO_PRECO ? currencyFormat(Number(ULTIMO_PRECO)) : '-',
                {
                    header: 'Último preço',
                    meta: {
                        enableColumnOrdering: false,
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    enableHiding: false,
                    size: 120,
                }
            ),
            columnHelper.accessor('QTD_SOLICITADA', {
                header: 'Qtde. solicitada',
                enableHiding: false,
                size: 130,
                meta: {
                    enableColumnOrdering: false,
                },
                footer: () => 'Total',
            }),
            columnHelper.group({
                header: 'Fornecedores',
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    header: {
                        className: 'text-xs p-4 font-bold',
                    },
                },
                columns: [
                    ...suppliers.map((supplier) => {
                        const columnGroup = columnHelper.group({
                            id: supplier.SK_FORNECEDOR,
                            header: () => {
                                return <HeaderContent supplier={supplier} />
                            },
                            meta: {
                                enableColumnOrdering: false,
                                enableMenu: false,
                                header: {
                                    className: 'text-sm p-4 font-bold',
                                },
                            },
                            columns: [],
                        })
                        const supplierFirstRound = Math.min(
                            ...supplier.FORNECEDOR_COTACAO_RODADAS
                        )
                        const supplierLastRound = Math.max(
                            ...supplier.FORNECEDOR_COTACAO_RODADAS
                        )

                        columnGroup.columns?.push(
                            columnHelper.display({
                                id: `${supplier.SK_FORNECEDOR}-status`,
                                header: 'Aprovação',
                                cell: ({ row }) => {
                                    const { SK_COTACAO_X_PRODUTO } =
                                        row.original

                                    const value = getValue(
                                        supplier,
                                        supplierLastRound,
                                        SK_COTACAO_X_PRODUTO
                                    )

                                    if (!value) return null

                                    const status = value.APROVACAO?.DD_STATUS

                                    return (
                                        <div className="p-2">
                                            {status
                                                ? ApprovalComponent[status]
                                                : '-'}
                                        </div>
                                    )
                                },
                                meta: {
                                    ...COMMON_COLUMN_META,
                                    cell: {
                                        className: cn(
                                            COMMON_COLUMN_META.cell.className,
                                            'items-center'
                                        ),
                                    },
                                },
                            })
                        )
                        columnGroup.columns?.push(
                            columnHelper.display({
                                id: `${supplier.SK_FORNECEDOR}-qtde-disponivel`,
                                header: 'Qtd disponível',
                                cell: ({ row }) => {
                                    const { SK_COTACAO_X_PRODUTO } =
                                        row.original

                                    const value = getValue(
                                        supplier,
                                        supplierLastRound,
                                        SK_COTACAO_X_PRODUTO
                                    )

                                    if (!value) return null

                                    return (
                                        <CellContent
                                            shouldGroup
                                            data={value}
                                            dataKey="QTD_DISPONIVEL"
                                        />
                                    )
                                },
                                meta: COMMON_COLUMN_META,
                            })
                        )

                        columnGroup.columns?.push(
                            columnHelper.display({
                                id: `${supplier.SK_FORNECEDOR}-net-inicial`,
                                header: 'Valor net inicial',
                                cell: ({ row }) => {
                                    const { SK_COTACAO_X_PRODUTO } =
                                        row.original

                                    const value = getValue(
                                        supplier,
                                        supplierFirstRound,
                                        SK_COTACAO_X_PRODUTO
                                    )

                                    if (!value) return null

                                    return (
                                        <CellContent
                                            data={value}
                                            dataKey="PRECO"
                                            formatter={currencyFormat}
                                        />
                                    )
                                },
                                meta: COMMON_COLUMN_META,
                                footer: () => {
                                    const sum = values
                                        .filter((value) =>
                                            supplier.FORNECEDOR_RODADAS.some(
                                                (id) =>
                                                    id ===
                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                    supplierFirstRound ===
                                                        value.SK_COTACAO_X_RODADA
                                            )
                                        )
                                        .reduce((acc, curr) => {
                                            const value =
                                                Number(curr.PRECO) || 0
                                            return acc + value
                                        }, 0)
                                    return currencyFormat(sum)
                                },
                            })
                        )
                        columnGroup.columns?.push(
                            columnHelper.display({
                                id: `${supplier.SK_FORNECEDOR}-net-final`,
                                header: 'Valor net final',
                                cell: ({ row }) => {
                                    const { SK_COTACAO_X_PRODUTO } =
                                        row.original

                                    const value = getValue(
                                        supplier,
                                        supplierLastRound,
                                        SK_COTACAO_X_PRODUTO
                                    )

                                    if (!value) return null

                                    return (
                                        <CellContent
                                            data={value}
                                            dataKey="PRECO"
                                            formatter={currencyFormat}
                                        />
                                    )
                                },
                                meta: COMMON_COLUMN_META,
                                footer: () => {
                                    const sum = values
                                        .filter((value) =>
                                            supplier.FORNECEDOR_RODADAS.some(
                                                (id) =>
                                                    id ===
                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                    supplierLastRound ===
                                                        value.SK_COTACAO_X_RODADA
                                            )
                                        )
                                        .reduce((acc, curr) => {
                                            const value =
                                                Number(curr.PRECO) || 0
                                            return acc + value
                                        }, 0)
                                    return currencyFormat(sum)
                                },
                            })
                        )

                        columnGroup.columns?.push(
                            columnHelper.display({
                                id: `${supplier.SK_FORNECEDOR}-taxes`,
                                header: 'Valor com imposto',
                                meta: COMMON_COLUMN_META,
                                cell: ({ row }) => {
                                    const { SK_COTACAO_X_PRODUTO } =
                                        row.original

                                    const value = values?.find(
                                        (value) =>
                                            supplier.FORNECEDOR_RODADAS.includes(
                                                value.SK_FORNECEDOR_X_RODADA
                                            ) &&
                                            supplierLastRound ===
                                                value.SK_COTACAO_X_RODADA &&
                                            value.SK_COTACAO_X_PRODUTO ===
                                                SK_COTACAO_X_PRODUTO
                                    )

                                    if (!value) return null

                                    return (
                                        <CellContent
                                            dataKey="PRECO_IMPOSTO"
                                            data={value}
                                            formatter={currencyFormat}
                                        />
                                    )
                                },
                                footer: () => {
                                    const sum = values
                                        .filter((value) =>
                                            supplier.FORNECEDOR_RODADAS.some(
                                                (id) =>
                                                    id ===
                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                    supplierLastRound ===
                                                        value.SK_COTACAO_X_RODADA
                                            )
                                        )
                                        .reduce((acc, curr) => {
                                            const value =
                                                Number(curr.PRECO_IMPOSTO) || 0
                                            return (acc += value)
                                        }, 0)
                                    return currencyFormat(sum)
                                },
                            })
                        )

                        columnGroup.columns?.push(
                            columnHelper.display({
                                id: `${supplier.SK_FORNECEDOR}-total-inicial`,
                                header: 'Valor total inicial',
                                cell: ({ row }) => {
                                    const { SK_COTACAO_X_PRODUTO } =
                                        row.original

                                    const value = getValue(
                                        supplier,
                                        supplierFirstRound,
                                        SK_COTACAO_X_PRODUTO
                                    )

                                    if (!value) return null

                                    return (
                                        <CellContent
                                            dataKey="PRECO_TOTAL"
                                            className={TOTALS_CELL_CLASSNAME}
                                            data={value}
                                            formatter={currencyFormat}
                                        />
                                    )
                                },
                                footer: () => {
                                    const sum = values
                                        .filter((value) =>
                                            supplier.FORNECEDOR_RODADAS.some(
                                                (id) =>
                                                    id ===
                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                    supplierFirstRound ===
                                                        value.SK_COTACAO_X_RODADA
                                            )
                                        )
                                        .reduce((acc, curr) => {
                                            const value =
                                                Number(curr.PRECO_TOTAL) || 0
                                            return acc + value
                                        }, 0)
                                    return currencyFormat(sum)
                                },
                                meta: {
                                    ...COMMON_COLUMN_META,
                                    header: {
                                        className: cn(
                                            HEADER_CLASSNAME,
                                            TOTALS_HEADER_CLASSNAME
                                        ),
                                    },
                                },
                            })
                        )
                        columnGroup.columns?.push(
                            columnHelper.display({
                                id: `${supplier.SK_FORNECEDOR}-total-final`,
                                header: 'Valor total final',
                                cell: ({ row }) => {
                                    const { SK_COTACAO_X_PRODUTO } =
                                        row.original

                                    const value = getValue(
                                        supplier,
                                        supplierLastRound,
                                        SK_COTACAO_X_PRODUTO
                                    )

                                    if (!value) return null

                                    return (
                                        <CellContent
                                            dataKey="PRECO_TOTAL"
                                            className={TOTALS_CELL_CLASSNAME}
                                            data={value}
                                            formatter={currencyFormat}
                                        />
                                    )
                                },
                                footer: () => {
                                    const sum = values
                                        .filter((value) =>
                                            supplier.FORNECEDOR_RODADAS.some(
                                                (id) =>
                                                    id ===
                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                    supplierLastRound ===
                                                        value.SK_COTACAO_X_RODADA
                                            )
                                        )
                                        .reduce((acc, curr) => {
                                            const value =
                                                Number(curr.PRECO_TOTAL) || 0
                                            return acc + value
                                        }, 0)
                                    return currencyFormat(sum)
                                },
                                meta: {
                                    ...COMMON_COLUMN_META,
                                    header: {
                                        className: cn(
                                            HEADER_CLASSNAME,
                                            TOTALS_HEADER_CLASSNAME
                                        ),
                                    },
                                },
                            })
                        )

                        columnGroup.columns?.push(
                            columnHelper.display({
                                id: `${supplier.SK_FORNECEDOR}-saving`,
                                header: 'Saving baseline',
                                cell: ({ row }) => {
                                    const { SK_COTACAO_X_PRODUTO } =
                                        row.original

                                    const value = getValue(
                                        supplier,
                                        supplierLastRound,
                                        SK_COTACAO_X_PRODUTO
                                    )

                                    if (!value) return null

                                    return (
                                        <CellContent
                                            dataKey="SAVING_BASELINE"
                                            className={SAVINGS_CELL_CLASSNAME}
                                            data={value}
                                            formatter={(v) =>
                                                `${currencyFormat(
                                                    v
                                                )} (${percentageFormat(
                                                    Number(
                                                        value.SAVING_BASELINE_PERCENTUAL
                                                    ) * 100
                                                )})`
                                            }
                                        />
                                    )
                                },
                                footer: () => {
                                    const sum = values
                                        .filter((value) =>
                                            supplier.FORNECEDOR_RODADAS.some(
                                                (id) =>
                                                    id ===
                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                    supplierFirstRound ===
                                                        value.SK_COTACAO_X_RODADA
                                            )
                                        )
                                        .reduce((acc, curr) => {
                                            const value =
                                                Number(curr.SAVING_BASELINE) ||
                                                0
                                            return acc + value
                                        }, 0)
                                    return currencyFormat(sum)
                                },
                                meta: {
                                    ...COMMON_COLUMN_META,
                                    header: {
                                        className: cn(
                                            HEADER_CLASSNAME,
                                            SAVINGS_HEADER_CLASSNAME
                                        ),
                                    },
                                },
                            })
                        )
                        columnGroup.columns?.push(
                            columnHelper.display({
                                id: `${supplier.SK_FORNECEDOR}-saving-negociacao`,
                                header: 'Saving negociação',
                                cell: ({ row }) => {
                                    const { SK_COTACAO_X_PRODUTO } =
                                        row.original

                                    const value = getValue(
                                        supplier,
                                        supplierLastRound,
                                        SK_COTACAO_X_PRODUTO
                                    )

                                    if (!value) return null

                                    return (
                                        <CellContent
                                            dataKey="SAVING_NEGOCIACAO"
                                            className={SAVINGS_CELL_CLASSNAME}
                                            data={value}
                                            formatter={(v) =>
                                                `${currencyFormat(
                                                    v
                                                )} (${percentageFormat(
                                                    Number(
                                                        value.SAVING_NEGOCIACAO_PERCENTUAL
                                                    ) * 100
                                                )})`
                                            }
                                        />
                                    )
                                },

                                footer: () => {
                                    const sum = values
                                        .filter((value) =>
                                            supplier.FORNECEDOR_RODADAS.some(
                                                (id) =>
                                                    id ===
                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                    supplierLastRound ===
                                                        value.SK_COTACAO_X_RODADA
                                            )
                                        )
                                        .reduce((acc, curr) => {
                                            const value =
                                                Number(
                                                    curr.SAVING_NEGOCIACAO
                                                ) || 0
                                            return acc + value
                                        }, 0)
                                    return currencyFormat(sum)
                                },
                                meta: {
                                    ...COMMON_COLUMN_META,
                                    header: {
                                        className: cn(
                                            HEADER_CLASSNAME,
                                            SAVINGS_HEADER_CLASSNAME
                                        ),
                                    },
                                },
                            })
                        )
                        columnGroup.columns?.push(
                            columnHelper.display({
                                id: `${supplier.SK_FORNECEDOR}-saving-financeiro`,
                                header: 'Saving financeiro',
                                cell: ({ row }) => {
                                    const { SK_COTACAO_X_PRODUTO } =
                                        row.original

                                    const value = getValue(
                                        supplier,
                                        supplierLastRound,
                                        SK_COTACAO_X_PRODUTO
                                    )

                                    if (!value) return null

                                    return (
                                        <CellContent
                                            dataKey="SAVING_FINANCEIRO"
                                            className={SAVINGS_CELL_CLASSNAME}
                                            data={value}
                                            formatter={(v) =>
                                                `${currencyFormat(
                                                    v
                                                )} (${percentageFormat(
                                                    value.SAVING_FINANCEIRO_PERCENTUAL ||
                                                        0
                                                )})`
                                            }
                                        />
                                    )
                                },
                                footer: () => {
                                    const sum = values
                                        .filter((value) =>
                                            supplier.FORNECEDOR_RODADAS.some(
                                                (id) =>
                                                    id ===
                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                    supplierLastRound ===
                                                        value.SK_COTACAO_X_RODADA
                                            )
                                        )
                                        .reduce((acc, curr) => {
                                            const value =
                                                Number(
                                                    curr.SAVING_FINANCEIRO
                                                ) || 0
                                            return acc + value
                                        }, 0)
                                    return currencyFormat(sum)
                                },
                                meta: {
                                    ...COMMON_COLUMN_META,
                                    header: {
                                        className: cn(
                                            HEADER_CLASSNAME,
                                            SAVINGS_HEADER_CLASSNAME
                                        ),
                                    },
                                },
                            })
                        )

                        return columnGroup
                    }),
                ],
            }),
        ],
        [quotationMap]
    )

    const dataMemo = useMemo(() => {
        return products.map((product) => {
            return {
                ...product,
                ...values.find(
                    (value) =>
                        value.SK_COTACAO_X_PRODUTO ===
                        product.SK_COTACAO_X_PRODUTO
                )!,
            }
        })
    }, [products, quotationMap, values])

    const table = useReactTable({
        data: dataMemo,
        columns: columnsMemo,
        filterFromLeafRows: true,
        columnResizeMode: 'onChange',
        state: {
            columnOrder: columnOrderState,
        },
        meta: {
            layout: 'stretch',
        },
        onColumnOrderChange: setColumnOrderState,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
    })

    const rows = table.getRowModel().rows

    return (
        <TableContainer>
            <TableContextProvider table={table}>
                <TableLayout>
                    {/* thead */}
                    <div className="bg-background">
                        {table.getHeaderGroups().map((headerGroup) => (
                            // tr
                            <div key={headerGroup.id} className="flex">
                                {headerGroup.headers.map((header) => (
                                    // th
                                    <TableColumnHeader
                                        key={header.id}
                                        header={header}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                    {/* tbody */}
                    <TableBody>
                        {rows.map((row) => {
                            return (
                                // tr
                                <TableRow
                                    className="flex border-b"
                                    key={row?.id}
                                >
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            // td
                                            <TableCell
                                                key={cell.id}
                                                cell={cell}
                                            />
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                    <div>
                        {table.getFooterGroups().map((footerGroup) => (
                            <div key={footerGroup.id} className="flex">
                                {footerGroup.headers.map(
                                    (header) =>
                                        header.depth === 3 && (
                                            <TableFooter
                                                key={header.id}
                                                header={
                                                    header as Header<
                                                        unknown,
                                                        any
                                                    >
                                                }
                                            />
                                        )
                                )}
                            </div>
                        ))}
                    </div>
                </TableLayout>
            </TableContextProvider>
        </TableContainer>
    )
}

export default ApprovalTable
