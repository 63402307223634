import { Filter } from '@/types/Filters'
import { PaginationAPIProps, PaginationAPIResponse } from '@/types/Pagination'

export type Intervals =
    | 'ATRASO DE 1 À 5 DIAS'
    | 'ATRASO DE 11 À 15 DIAS'
    | 'ATRASO DE 16 À 25 DIAS'
    | 'ATRASO DE 6 À 10 DIAS'
    | 'ATRASO MAIOR QUE 25 DIAS'
    | 'DENTRO DO PRAZO'

export type Priority =
    | 'Normal'
    | 'Urgente'
    | 'Complemento'
    | 'Regularização'
    | 'Delegadas'
    | 'Intercompany'
    | 'Recompra'

export type PurchaseRequestQuotationStatus = 'EM_COTACAO' | 'ERRO_PEDIDO'

export type PurchaseRequestQuotationStatusMap = Record<
    PurchaseRequestQuotationStatus,
    string
>

export type PurchaseRequestQuotationIntervalMap = Record<Intervals, string>

export const PURCHASE_REQUEST_STATUS: PurchaseRequestQuotationStatusMap = {
    EM_COTACAO: 'Em cotação',
    ERRO_PEDIDO: 'Erro no pedido',
}

export const PURCHASE_REQUEST_CLASSNAME: PurchaseRequestQuotationStatusMap = {
    EM_COTACAO: 'bg-amber-50 text-amber-600',
    ERRO_PEDIDO: 'bg-red-50 text-red-500',
}

export const INTERVAL_CLASSNAME: PurchaseRequestQuotationIntervalMap = {
    'DENTRO DO PRAZO': 'text-emerald-600',
    'ATRASO DE 1 À 5 DIAS': 'text-yellow-500',
    'ATRASO DE 6 À 10 DIAS': 'text-amber-500',
    'ATRASO DE 11 À 15 DIAS': 'text-orange-400',
    'ATRASO DE 16 À 25 DIAS': 'text-orange-400',
    'ATRASO MAIOR QUE 25 DIAS': 'text-red-600',
}

export interface PurchaseRequestAPIResponse {
    NK_SOLICITACAO_DE_COMPRAS: string
    SK_EMPRESA: string
    ABREVIATURA_EMPRESA: string
    COR_EMPRESA: string
    SK_COMPRADOR: string
    NK_COMPRADOR: string
    NK_CODIGO_USUARIO: string
    DS_NOME_COMPRADOR: string
    DD_STATUS: string
    DD_SOLICITANTE: string
    DT_EMISSAO: number
    DT_APROVACAO: number
    HR_APROVACAO: string
}

export interface PurchaseRequestItem {
    SK_SOLICITACAO_DE_COMPRAS: string
    NK_ITEM_SOLICITACAO_DE_COMPRAS: string
    SK_PRODUTO: string
    NK_PRODUTO: string
    DS_PRODUTO: string
    DD_UM: string
    DD_NCM: string
    ULTIMO_PRECO: string | null
    NK_GRUPO_DE_PRODUTOS: string
    DS_GRUPO_DE_PRODUTOS: string
    DD_BLOQUEADO: boolean
    DD_SOLICITANTE: string
    DD_APROVADOR: string | null
    DD_URGENCIA: Priority
    DD_RATEIO: 0 | 1 | null
    SK_CENTRO_DE_CUSTO: string
    NK_CENTRO_DE_CUSTO: string
    CENTRO_DE_CUSTO: string
    SK_CONTA_ORCAMENTARIA: string
    NK_CONTA_ORCAMENTARIA: string
    CONTA_ORCAMENTARIA: string
    DD_OBSERVACAO: string
    QTD_PEDIDA: string
    QTD_SOLICITADA: string
    DT_NECESSIDADE: number
    INTERVALO_PRAZO: Intervals
    DT_PRAZO: string
    ATRASO: number
}

export interface PurchaseRequestFilters {
    INTERVALO_PRAZO: Intervals[]
    URGENCIA: Priority[]
}

export type PurchaseRequestFlat = PurchaseRequestAPIResponse &
    PurchaseRequestItem & {
        NUMERO_DA_COTACAO: string | null
        SK_COTACAO: number
        GERA_COTACAO: boolean
        DD_PEDIDO_NAO_GERADO: boolean
        STATUS_SC_COTACAO: PurchaseRequestQuotationStatus
    }

export interface FetchPurchaseRequestProps
    extends PaginationAPIProps<PurchaseRequestFlat> {}

export interface FetchQuotationPurchaseRequestProps
    extends PaginationAPIProps<PurchaseRequestFlat> {}

export interface FetchQuotationPurchaseRequestResponse
    extends PaginationAPIResponse<PurchaseRequestFlat> {}

export interface FetchPurchaseRequestItemsProps {
    companyId: string
    purchaseRequestNumber: string
}

export interface FetchPurchaseRequestResponse
    extends PaginationAPIResponse<PurchaseRequestFlat> {}

export interface FetchPurchaseRequestItemsResponse
    extends PurchaseRequestAPIResponse {
    ITENS: PurchaseRequestItem[]
}

export type FetchPurchaseRequestToSelectProps = {
    filters: Filter<PurchaseRequestFlat>[]
}
