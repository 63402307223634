import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useQuotationPurchaseOrderListQuery } from '@/queries/usePurchaseOrderQuery'
import {
    PurchaseOrder as IPurchaseOrder,
    purchaseOrderClassNames,
} from '@/types/PurchaseOrder'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import DadosMestreHeader from '../DadosMestre/components/DadosMestreHeader'
import DadosMestreHeaderActions from '../DadosMestre/components/DadosMestreHeaderActions'
import { useTable } from '@/hooks/useTable'
import { Filter, Operators } from '@/types/Filters'
import { isAxiosError } from '@/api/business'
import {
    currencyFormat,
    formatCpfOrCnpj,
    percentageFormat,
} from '@/utils/stringFormatter'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { cn } from '@/lib/utils'
import { TypographyP } from '@/components/ui/typography'

export type PurchaseOrderTable = TableData<IPurchaseOrder>

const columnHelper = createColumnHelper<IPurchaseOrder>()

const PurchaseOrder = () => {
    const {
        table,
        selectedRows,
        pageIndex,
        pageSize,
        columnFilters,
        onPaginationChange,
        onColumnFiltersChange,
        onClickMultiDelete,
        setTable,
    } = useTable<PurchaseOrderTable>()

    // const { onDownload } = usePurchaseOrder()
    const [fieldFilters, setFieldFilters] = useState<Filter<IPurchaseOrder>[]>(
        []
    )

    const { data, isFetching, isPending, isError, error, refetch } =
        useQuotationPurchaseOrderListQuery({
            currentPage: pageIndex,
            perPage: pageSize,
            filters: fieldFilters,
            enabled: true,
        })

    const memoData = useMemo(() => (data ? data.DADOS : []), [data])
    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('NK_PEDIDO_DE_COMPRAS', {
                id: 'NK_PEDIDO_DE_COMPRAS',
                header: 'Número pedido',
                size: 130,
            }),
            columnHelper.accessor('NK_ITEM_PEDIDO_DE_COMPRAS', {
                id: 'NK_ITEM_PEDIDO_DE_COMPRAS',
                header: 'Item',
                size: 80,
            }),
            columnHelper.accessor('TIPO_PEDIDO', {
                id: 'TIPO_PEDIDO',
                header: 'Tipo',
                size: 150,
            }),
            columnHelper.accessor('DD_STATUS', {
                id: 'DD_STATUS',
                header: 'Status',
                cell: ({ getValue }) => {
                    return (
                        <TypographyP
                            className={cn(
                                'text-xs truncate',
                                purchaseOrderClassNames[getValue()]
                            )}
                        >
                            {getValue()}
                        </TypographyP>
                    )
                },
                size: 150,
            }),
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Cod empresa',
                size: 100,
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'ABREVIATURA_EMPRESA',
                header: 'Empresa',
                size: 100,
            }),
            columnHelper.accessor('CNPJ', {
                id: 'CNPJ',
                header: 'CNPJ',
                size: 120,
                meta: {
                    cell: {
                        formatterFn: ({ value }) => {
                            return formatCpfOrCnpj(value)
                        },
                    },
                },
            }),
            columnHelper.accessor('NK_FORNECEDOR', {
                id: 'NK_FORNECEDOR',
                header: 'Cod fornecedor',
                size: 100,
            }),
            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                id: 'DD_RAZAO_SOCIAL',
                header: 'Razão social',
                size: 250,
            }),
            columnHelper.accessor('DD_NOME_FANTASIA', {
                id: 'DD_NOME_FANTASIA',
                header: 'Nome fantasia',
                size: 250,
            }),
            columnHelper.accessor('DD_UF', {
                id: 'DD_UF',
                header: 'UF fonecedor',
                size: 100,
            }),
            columnHelper.accessor('NK_CENTRO_DE_CUSTO', {
                id: 'NK_CENTRO_DE_CUSTO',
                header: 'Cod centro de custo',
                size: 100,
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_SOLICITACAO_DE_COMPRAS',
                header: 'Número SC',
                size: 100,
            }),
            columnHelper.accessor('NK_PRODUTO', {
                id: 'NK_PRODUTO',
                header: 'Cod produto',
                size: 100,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 250,
            }),
            columnHelper.accessor('DD_TIPO', {
                id: 'DD_TIPO',
                header: 'Tipo produto',
                size: 100,
            }),
            columnHelper.accessor('DD_UM', {
                id: 'DD_UM',
                header: 'UM',
                size: 100,
            }),
            columnHelper.accessor('DD_NCM', {
                id: 'DD_NCM',
                header: 'NCM',
                size: 100,
            }),
            columnHelper.accessor('DS_GRUPO_DE_PRODUTOS', {
                id: 'DS_GRUPO_DE_PRODUTOS',
                header: 'Grupo de produtos',
                size: 200,
            }),
            columnHelper.accessor('NK_CONTA_ORCAMENTARIA', {
                id: 'NK_CONTA_ORCAMENTARIA',
                header: 'Cod conta orçamentária',
                size: 100,
            }),
            columnHelper.accessor('CONTA_ORCAMENTARIA', {
                id: 'CONTA_ORCAMENTARIA',
                header: 'Conta orçamentária',
                size: 100,
            }),
            columnHelper.accessor('NK_COMPRADOR', {
                id: 'NK_COMPRADOR',
                header: 'Cod comprador',
                size: 100,
            }),
            columnHelper.accessor('DD_EMAIL', {
                id: 'DD_EMAIL',
                header: 'Email comprador',
                size: 200,
            }),
            columnHelper.accessor('DS_NOME_COMPRADOR', {
                id: 'DS_NOME_COMPRADOR',
                header: 'Nome comprador',
                size: 200,
            }),
            columnHelper.accessor('DS_COMPRADOR', {
                id: 'DS_COMPRADOR',
                header: 'Comprador (PROTHEUS)',
                size: 200,
            }),
            columnHelper.accessor('DS_APROVADOR', {
                id: 'DS_APROVADOR',
                header: 'Aprovador',
                size: 250,
            }),
            columnHelper.accessor('QTD_PEDIDA', {
                id: 'QTD_PEDIDA',
                header: 'Qtd pedida',
                size: 100,
            }),
            columnHelper.accessor('QTD_ENTREGUE', {
                id: 'QTD_ENTREGUE',
                header: 'Qtd entregue',
                size: 100,
            }),
            columnHelper.accessor('VL_UNITARIO', {
                id: 'VL_UNITARIO',
                header: 'Vl. unitário',
                cell: ({ getValue }) => {
                    return currencyFormat(Number(getValue()) || 0, 4)
                },
                size: 150,
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
            }),
            columnHelper.accessor('VL_TOTAL', {
                id: 'VL_TOTAL',
                header: 'Vl. total',
                cell: ({ getValue }) => {
                    return currencyFormat(Number(getValue()) || 0, 4)
                },
                size: 150,
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
            }),
            columnHelper.accessor('TIPO_SAVING', {
                id: 'TIPO_SAVING',
                header: 'Tipo saving',
                size: 100,
            }),
            columnHelper.accessor('VL_SAVING', {
                id: 'VL_SAVING',
                header: 'Vl. saving',
                cell: ({ getValue }) => {
                    return currencyFormat(Number(getValue()) || 0, 4)
                },
                size: 150,
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
            }),
            columnHelper.accessor('PERCENTUAL_SAVING', {
                id: 'PERCENTUAL_SAVING',
                header: 'Percentual saving',
                cell: ({ getValue }) => {
                    return percentageFormat(Number(getValue()) || 0, 4)
                },
                size: 150,
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
            }),
            columnHelper.accessor('DT_PREVISAO_ENTREGA', {
                id: 'DT_PREVISAO_ENTREGA',
                header: 'Previsão de entrega',
                size: 100,
            }),
            columnHelper.accessor('DT_EMISSAO', {
                id: 'DT_EMISSAO',
                header: 'Emissão',
                size: 100,
            }),
            columnHelper.accessor('DT_ESTIMATIVA_PAGAMENTO', {
                id: 'DT_ESTIMATIVA_PAGAMENTO',
                header: 'Estimativa pagamento',
                size: 100,
            }),
            columnHelper.accessor('DD_URGENCIA', {
                id: 'DD_URGENCIA',
                header: 'Prioridade',
                size: 100,
            }),
            columnHelper.accessor('PRAZO', {
                id: 'PRAZO',
                header: 'Prazo',
                size: 200,
            }),
            columnHelper.accessor('PRAZO_INTERVALO', {
                id: 'PRAZO_INTERVALO',
                header: 'Intervalo',
                size: 200,
            }),
            columnHelper.accessor('DS_OBSERVACAO', {
                id: 'DS_OBSERVACAO',
                header: 'Observação',
                size: 300,
            }),
        ],
        []
    )

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<PurchaseOrderTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_PEDIDO_DE_COMPRAS.toString()}
                isLoading={isPending}
                isFetching={isFetching}
                isError={isError}
                errorMessage={
                    isError && isAxiosError(error)
                        ? error.response?.data.message
                        : 'Erro ao carregar pedidos de compras'
                }
                getTableInstance={(table) => setTable(table)}
                tableState={{
                    columnFilters,
                    pagination: {
                        pageSize,
                        pageIndex,
                    },
                }}
                pagination={{
                    pageSize,
                    totalPages: data?.TOTAL_DE_PAGINAS || 0,
                    totalItems: data?.TOTAL || 0,
                }}
                onPaginationChange={onPaginationChange}
                onColumnFiltersChange={(columnFilter) => {
                    if (columnFilter.length > 0) {
                        const filters: Filter<IPurchaseOrder>[] = [
                            {
                                AND: columnFilter.map((filter) => {
                                    return {
                                        field: filter.id as keyof IPurchaseOrder,
                                        operator: Operators.like,
                                        value: filter.value as string,
                                    }
                                }),
                            },
                        ]

                        setFieldFilters(filters)
                    } else {
                        setFieldFilters([])
                    }
                    onColumnFiltersChange(columnFilter)
                }}
                tableHeader={
                    <DadosMestreHeader
                        title="Pedidos de compras"
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions
                        onRefech={refetch}
                        tableId="DADOS_MESTRE_PEDIDOS_DE_COMPRAS"
                    />
                }
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
        </div>
    )
}

export default PurchaseOrder
