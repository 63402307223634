import { isAxiosError } from '@/api/business'
import { updateQuotation } from '@/api/business/cotacoes'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import {
    quotationKeys,
    useQuotationByIdQuery,
} from '@/queries/useQuotationQuery'
import { editQuotationSchema } from '@/schemas/quotationSchema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { z } from 'zod'

interface EditDetailsDialogProps {
    isOpen: boolean
    onClose: () => void
}

const EditDetailsDialog = ({ isOpen, onClose }: EditDetailsDialogProps) => {
    const { id } = useParams()
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const { data: quotation } = useQuotationByIdQuery({
        id: Number(id),
    })

    const { mutate, isPending } = useMutation({
        mutationFn: updateQuotation,
        onSuccess: () => {
            if (id)
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(Number(id)),
                })

            toast({
                title: 'Cotação editada com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível editar a cotação. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao editar cotação',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const form = useForm<z.infer<typeof editQuotationSchema>>({
        resolver: zodResolver(editQuotationSchema),
        defaultValues: quotation
            ? {
                  quotation: quotation.NM_COTACAO,
                  description: quotation.DS_COTACAO,
              }
            : {},
    })

    useEffect(() => {
        if (quotation) {
            form.reset({
                quotation: quotation.NM_COTACAO,
                description: quotation.DS_COTACAO || '',
            })
        }
    }, [quotation])

    const onSubmit = (data: z.infer<typeof editQuotationSchema>) => {
        mutate({
            SK_COTACAO: quotation?.SK_COTACAO,
            NM_COTACAO: data.quotation,
            DS_COTACAO: data.description,
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Editar cabeçalho</DialogTitle>
                    <DialogDescription>
                        Editar informações sobre a cotação
                    </DialogDescription>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-2"
                    >
                        <FormField
                            control={form.control}
                            name="quotation"
                            render={({ field }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>Cotação</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )
                            }}
                        />
                        <FormField
                            control={form.control}
                            name="description"
                            render={({ field }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>Descrição</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )
                            }}
                        />
                        <DialogFooter>
                            <Button
                                variant="ghost"
                                onClick={onClose}
                                type="button"
                            >
                                Cancelar
                            </Button>
                            <Button disabled={isPending}>Confirmar</Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default EditDetailsDialog
