import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { currencyFormat, formatCpfOrCnpj } from '@/utils/stringFormatter'
import HeaderActions from '../HeaderActions'
import {
    QuotationMapSupplierValuesState,
    QuotationMapValuesState,
} from '@/store/quotationMapSlice/type'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { useMemo } from 'react'

type HeaderContentProps = {
    supplier: QuotationMapSupplierValuesState
    disabled: boolean
    values: QuotationMapValuesState[]
    onSelectAllFinalSupplier: (supplierId: string) => void
}

const stateSelector = (state: Store) => ({
    selectedWinners: state.quotationMapSlice.state.selectedWinners,
})

const HeaderContent = ({
    disabled,
    supplier,
    values,
    onSelectAllFinalSupplier,
}: HeaderContentProps) => {
    const { selectedWinners } = useBaseStore(stateSelector)

    const valuesBySuppliers = useMemo(
        () =>
            values
                .filter(
                    (value) => value.SK_FORNECEDOR === supplier.SK_FORNECEDOR
                )
                .reduce((acc, curr) => {
                    const prevValue = acc[supplier.SK_FORNECEDOR] || {
                        totalTax: 0,
                        total: 0,
                    }

                    let currValue = { total: 0, totalTax: 0 }

                    if (selectedWinners[curr.SK_COTACAO_X_PRODUTO]) {
                        if (
                            selectedWinners[curr.SK_COTACAO_X_PRODUTO][
                                curr.SK_FORNECEDOR_X_RODADA
                            ]
                        )
                            if (curr.GRUPO.length > 0) {
                                currValue = curr.GRUPO.reduce(
                                    (acc, curr) => {
                                        return {
                                            totalTax: (acc.totalTax +=
                                                curr.PRECO_TOTAL_IMPOSTO),
                                            total: (acc.total +=
                                                curr.PRECO_TOTAL || 0),
                                        }
                                    },
                                    { total: 0, totalTax: 0 }
                                )
                            } else {
                                currValue = {
                                    totalTax: curr.PRECO_TOTAL_IMPOSTO,
                                    total: curr.PRECO_TOTAL,
                                }
                            }
                    }

                    return {
                        ...acc,
                        [supplier.SK_FORNECEDOR]: {
                            totalTax: prevValue.totalTax + currValue.totalTax,
                            total: prevValue.total + currValue.total,
                        },
                    }
                }, {} as Record<string, { total: number; totalTax: number }>),
        [selectedWinners]
    )

    return (
        <div className="flex items-center justify-between flex-1 p-2 overflow-hidden">
            <div className="flex-1 space-y-2 overflow-hidden">
                <div className="">
                    <TypographyP className="overflow-hidden font-semibold whitespace-nowrap text-ellipsis">
                        {supplier.DD_NOME_FANTASIA}
                    </TypographyP>
                    <TypographyMuted className="truncate">
                        {supplier.DD_RAZAO_SOCIAL} -{' '}
                        {supplier.DD_CNPJ && formatCpfOrCnpj(supplier.DD_CNPJ)}
                    </TypographyMuted>
                </div>
                <div className="flex items-center gap-2">
                    {supplier.FORNECEDOR_DECLINADO === 1 && (
                        <span className="p-1 font-normal rounded-md bg-destructive text-destructive-foreground">
                            Declinado
                        </span>
                    )}
                    <span className="p-1 font-normal rounded-md bg-muted text-muted-foreground">
                        Total c/ imposto:{' '}
                        {currencyFormat(
                            valuesBySuppliers[supplier.SK_FORNECEDOR].totalTax
                        )}
                    </span>
                    <span className="p-1 font-normal rounded-md bg-muted text-muted-foreground">
                        Total:{' '}
                        {currencyFormat(
                            valuesBySuppliers[supplier.SK_FORNECEDOR].total
                        )}
                    </span>
                </div>
            </div>
            <HeaderActions
                disabled={disabled}
                onSelectAll={() =>
                    onSelectAllFinalSupplier(supplier.SK_FORNECEDOR)
                }
            />
        </div>
    )
}

export default HeaderContent
