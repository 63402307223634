import { cn } from '@/lib/utils'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { CheckCircle } from 'lucide-react'
import { useParams } from 'react-router-dom'
import { MenubarItem } from '@/components/ui/menubar'

type FinishQuotationProps = {
    onClick: () => void
}

const FinishQuotation = ({ onClick }: FinishQuotationProps) => {
    const { id } = useParams()

    const {
        data: quotation,
        isPending: isQuotationPending,
        isError: isQuotationError,
    } = useQuotationByIdQuery({
        id: Number(id),
    })

    const disabled =
        isQuotationPending ||
        isQuotationError ||
        quotation?.DD_CANCELADA ||
        !quotation.DD_PUBLICADO

    return (
        <MenubarItem className="gap-2" disabled={disabled} onClick={onClick}>
            <CheckCircle
                size={14}
                className={cn(
                    disabled ? 'text-muted-foreground' : 'text-green-500'
                )}
            />
            Finalizar cotação
        </MenubarItem>
    )
}

export default FinishQuotation
