import { isAxiosError } from '@/api/business'
import { removeFile } from '@/api/business/arquivos'
import { Button } from '@/components/ui/button'
import File from '@/components/File'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { useToast } from '@/components/ui/use-toast'
import { quotationKeys } from '@/queries/useQuotationQuery'
import { Attachment } from '@/types/Attachments'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { RowSelectionState } from '@tanstack/react-table'
import { useParams } from 'react-router-dom'

interface RemoveAttachmentDialogProps {
    attachments: Attachment[]
    isOpen: boolean
    onSelectRows: (rowSelection: RowSelectionState) => void
    onClose: () => void
}

const RemoveAttachmentDialog = ({
    attachments,
    isOpen,
    onSelectRows,
    onClose,
}: RemoveAttachmentDialogProps) => {
    const { id } = useParams()
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: removeFile,
        onSuccess: () => {
            toast({
                title: 'Anexos removidos com sucesso',
            })
            if (id) {
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(Number(id)),
                })
            }

            onSelectRows({})
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível remover anexos. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao remover anexos',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-[680px]">
                <DialogHeader>
                    <DialogTitle>Remover anexos</DialogTitle>
                    <DialogDescription>
                        Realmente deseja remover os anexos da cotação?
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-2 divide-y max-h-[400px] overflow-auto">
                    {attachments.map((attachment) => (
                        <File
                            key={attachment.SK_COTACAO_X_ARQUIVOS}
                            file={attachment}
                            type="attachment"
                        />
                    ))}
                </div>
                <DialogFooter>
                    <Button
                        className="w-[80px]"
                        variant="destructive"
                        disabled={attachments.length === 0 || isPending}
                        onClick={() =>
                            mutate({
                                quotationId: Number(id),
                                files: attachments.map((attachment) => ({
                                    SK_ARQUIVO:
                                        attachment.SK_COTACAO_X_ARQUIVOS,
                                })),
                            })
                        }
                    >
                        Remover
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default RemoveAttachmentDialog
