import { DropdownMenuItem } from '@/components/ui/dropdown-menu'
import { cn } from '@/lib/utils'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { Store } from 'lucide-react'
import { useParams } from 'react-router-dom'

type AddSuppliersButtonProps = {
    onOpen: () => void
}

const AddSuppliersButton = ({ onOpen }: AddSuppliersButtonProps) => {
    const { id } = useParams()
    const { data, isPending, isError } = useQuotationByIdQuery({
        id: Number(id),
    })

    const disabled =
        isPending || isError || data.DD_CANCELADA || !!data.APROVACAO

    return (
        <DropdownMenuItem
            disabled={disabled}
            onClick={onOpen}
            className="gap-2"
        >
            <Store
                size={14}
                className={cn(disabled ? 'text-neutral-500' : 'text-amber-700')}
            />
            Adicionar fornecedores
        </DropdownMenuItem>
    )
}

export default AddSuppliersButton
