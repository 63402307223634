import { isAxiosError } from '@/api/business'
import { removeProductFromQuotation } from '@/api/business/cotacoes'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { TypographyP } from '@/components/ui/typography'
import { useToast } from '@/components/ui/use-toast'
import { quotationKeys } from '@/queries/useQuotationQuery'
import { QuotationProduct } from '@/types/Products'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { RowSelectionState } from '@tanstack/react-table'
import { useParams } from 'react-router-dom'

interface RemoveProductDialogProps {
    products: QuotationProduct[]
    isOpen: boolean
    isPurchaseRequest: boolean
    onSelectRows: (rowSelection: RowSelectionState) => void
    onClose: () => void
}

const RemoveProductDialog = ({
    products,
    isOpen,
    isPurchaseRequest,
    onSelectRows,
    onClose,
}: RemoveProductDialogProps) => {
    const { id } = useParams()
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: removeProductFromQuotation,
        onSuccess: () => {
            toast({
                title: 'Produtos removidos com sucesso',
            })
            const numberId = Number(id)

            if (numberId) {
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detailProducts(numberId),
                })
                queryClient.invalidateQueries({
                    queryKey: quotationKeys.detail(numberId),
                })
            }
            onSelectRows({})
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível remover os produtos. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao remover produtos',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-[680px]">
                <DialogHeader>
                    <DialogTitle>Remover produtos</DialogTitle>
                    <DialogDescription>
                        Realmente deseja remover as SCs da cotação?
                    </DialogDescription>
                </DialogHeader>
                <div className="space-y-2 divide-y max-h-[400px] overflow-auto">
                    {products.map((product) => (
                        <div
                            key={
                                product.SK_SOLICITACAO_DE_COMPRAS ||
                                product.SK_PRODUTO
                            }
                            className="pt-2"
                        >
                            <TypographyP>
                                ({product.NK_SOLICITACAO_DE_COMPRAS} -{' '}
                                {product.NK_ITEM_SOLICITACAO_DE_COMPRAS}){' '}
                                {product.DS_PRODUTO}
                            </TypographyP>
                        </div>
                    ))}
                </div>
                <DialogFooter>
                    <Button
                        className="w-[80px]"
                        variant="destructive"
                        disabled={products.length === 0 || isPending}
                        onClick={() => {
                            if (isPurchaseRequest) {
                                mutate({
                                    quotationId: Number(id),
                                    usePurchaseRequest: isPurchaseRequest,
                                    products: products.map((product) => ({
                                        SK_SOLICITACAO_DE_COMPRAS:
                                            product.SK_SOLICITACAO_DE_COMPRAS,
                                    })),
                                })
                            } else {
                                mutate({
                                    quotationId: Number(id),
                                    usePurchaseRequest: isPurchaseRequest,
                                    products: products.map((product) => ({
                                        SK_PRODUTO: product.SK_PRODUTO,
                                    })),
                                })
                            }
                        }}
                    >
                        Remover
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default RemoveProductDialog
