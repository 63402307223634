import instance from '@/api/business'
import {
    AprovacaoHistorico,
    AddItemsToQuotationMapApprovalProps,
    ApproveQuotationMapProps,
    GetApprovalHistoryProps,
} from '@/api/business/cotacaoAprovacao/type'

const BASE_ROUTE = '/cotacoes/aprovacao'

export const getApprovalHistory = async ({
    SK_COTACAO,
}: GetApprovalHistoryProps) => {
    const response = await instance.post<AprovacaoHistorico[]>(
        `${BASE_ROUTE}/historico`,
        {
            SK_COTACAO,
        }
    )

    return response.data
}

export const approveQuotationMap = async ({
    DD_APROVADO,
    SK_COTACAO,
    DS_OBSERVACAO,
}: ApproveQuotationMapProps) => {
    const response = await instance.put(`${BASE_ROUTE}/aprovar`, {
        SK_COTACAO,
        DD_APROVADO,
        DS_OBSERVACAO,
    })

    return response.data
}

export const addItemsToQuotationMapApproval = async ({
    ITENS,
    SK_COTACAO,
    DS_APROVACAO,
}: AddItemsToQuotationMapApprovalProps) => {
    const response = await instance.put(
        `${BASE_ROUTE}/adicionar-itens-aprovacao`,
        {
            SK_COTACAO,
            ITENS,
            DS_APROVACAO,
        }
    )

    return response.data
}
